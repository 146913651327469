import React, { FC, useEffect } from 'react';
import { StaticsComponentPropsType, StaticsType } from 'types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const Statistics: FC<StaticsComponentPropsType> = ({
  textColor,
  stats,
}) => {
  if (!stats) {
    return null;
  }
  const extractNumbers = (text: string) => {
    const value = text.split(/([0-9]+)/);
    return (
      <div className="data-value">
        <span className="dollor-symbol">{value[0]}</span>{' '}
        <div className="value">{value[1]}</div>
      </div>
    );
  };
  useEffect(() => {
    const element: any = document.querySelectorAll('.value');
    const dollor: any = document.querySelectorAll('.dollor-symbol');
    const AnimationPosition =
      window.innerWidth < 1400 ? 'top center+=230' : 'top=-10';
    gsap.from(element, {
      textContent: 1,
      color: textColor,
      duration: 3,
      ease: 'power1.in',
      snap: { textContent: 1 },
      scrollTrigger: {
        trigger: element,
        start: `${AnimationPosition}`,
        toggleActions: 'restart none restart none',
      },
    });
    gsap.from(dollor, {
      color: textColor,
      duration: 3,
      ease: 'power1.in',
      scrollTrigger: {
        trigger: element,
        start: `${AnimationPosition}`,
        toggleActions: 'restart none restart none',
      },
    });
  }, []);
  return (
    <section className="statistics">
      <div className="statistics-data">
        {stats.map((stat: StaticsType) => {
          const { id, title, value } = stat;
          return (
            <div className="statistics-data-inner" key={id}>
              {/^\d/.test(value) ? (
                <div className="value">{value}</div>
              ) : (
                extractNumbers(value)
              )}
              <div className="title">{title}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
