/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface ImageSlideProps {
  children: any;
  className?: string;
  direction?: string;
}

export const ImageSlide: FC<ImageSlideProps> = ({
  children,
  className,
  direction = 'left',
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const element: any = ref.current;
    const AnimationPosition =
      window.innerWidth < 990 ? 'top center' : 'top center-=150';
    const animationConfig = {
      x: 0,
      y: 0,
      rotateX: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: ref.current,
        toggleActions: 'play none none reverse',
        start: AnimationPosition,
      },
    };
    const animationfrom = {
      x: 0,
      y: 0,
      opacity: 0,
    };
    if (direction === 'right') {
      animationfrom.x = 100;
    } else {
      animationfrom.x = -100;
    }
    gsap.fromTo(element, animationfrom, animationConfig);
  }, []);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
