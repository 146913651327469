/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideUpTitleProps {
  children: any;
  className?: string;
  durationTime?: number;
  delaySec?: number;
}

export const SlideUpTitle: FC<SlideUpTitleProps> = ({
  children,
  className,
  durationTime,
  delaySec = 0,
}) => {
  const showAnimations =
    process.env.GATSBY_SHOW_ANIMATIONS === undefined ||
    (process.env.GATSBY_SHOW_ANIMATIONS &&
      process.env.GATSBY_SHOW_ANIMATIONS === 'true');
  const ref = useRef(null);

  useEffect(() => {
    const TitleAnimationPosition =
      window.innerWidth < 576
        ? 'top-=0 bottom-=10'
        : window.innerWidth < 990
        ? 'top-=10 bottom-=350'
        : 'top-=80 bottom-=170';
    const delayTime = window.innerWidth < 769 ? 0 : delaySec;
    const element: any = ref.current;
    if (showAnimations) {
      gsap.fromTo(
        element,
        { autoAlpha: 0, y: 100 },
        {
          duration: durationTime ?? 0.3,
          autoAlpha: 1,
          y: 0,
          delay: delayTime,
          scrollTrigger: {
            trigger: element,
            start: TitleAnimationPosition,
            toggleActions: 'play none none reverse',
          },
        }
      );
    }
  }, [showAnimations]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
