import { useStaticQuery, graphql } from 'gatsby';

const BrandQuery = () => {
  return useStaticQuery(graphql`
    query BrandQuery {
      Brands: strapiGlobal {
        brandsWorked {
          title
          showBrand
          showTitle
          brandImage {
            title
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      strapiContactUsPage {
        locationsSection {
          contactFormModalTitle
        }
      }
    }
  `);
};

export { BrandQuery };
