/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const LocationCard: FC = ({ location }: any) => {
  const { title, image, slug } = location;
  const locationimage: any = image
    ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
    : null;
  return (
    <div className="location-service-item">
      <div className="location-service-item-title">
        <Link to={`/location/${slug}`}> {title} </Link>
      </div>
      {locationimage && (
        <div className="location-service-item-image">
          <div className="location-service-item-image-wrapper">
            <Link to={`/location/${slug}`}>
              <GatsbyImage
                image={locationimage}
                alt={image?.alternativeText ?? (title || '')}
              />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export { LocationCard };
