/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-console */
import React, { FC } from 'react';
import ReactModal from 'react-modal';
import LeftArrowIcon from 'assets/svg/arrow-prev.svg';
import RightArrowIcon from 'assets/svg/arrow-next.svg';

interface VideoModalProp {
  isModalOpen: any;
  setisModalOpen: any;
  gallery: [any];
  ModalVideoIndex: any;
  setModalVideoIndex: any;
}

const VideoModal: FC<VideoModalProp> = ({
  isModalOpen,
  setisModalOpen,
  gallery,
  ModalVideoIndex,
  setModalVideoIndex,
}) => {
  const handleModalClose = () => {
    setisModalOpen(false);
  };
  const handlePrevRequest = () => {
    setModalVideoIndex((ModalVideoIndex - 1 + gallery.length) % gallery.length);
  };
  const handleNextRequest = () => {
    setModalVideoIndex((ModalVideoIndex + 1) % gallery.length);
  };
  const handleclickRequest = () => {
    // setModalVideoIndex(index);
    setModalVideoIndex((ModalVideoIndex + 1) % gallery.length);
  };
  return (
    <div>
      <ReactModal
        closeTimeoutMS={1000}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName="ReactModalPortal videoplayer-modal"
      >
        <button
          type="button"
          onClick={() => handleModalClose()}
          className="modal-close"
        >
          <span>&#x2715;</span>
        </button>
        ({' '}
        {gallery.length > 1 && (
          <div>
            <button
              className="modal-btn prev"
              type="button"
              onClick={handlePrevRequest}
            >
              <LeftArrowIcon />
            </button>{' '}
          </div>
        )}
        )
        <div className="modal-img-container">
          {ModalVideoIndex !== null && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video src={gallery[ModalVideoIndex]} controls autoPlay />
          )}
        </div>
        {gallery.length > 1 && (
          <div>
            <button
              type="button"
              className="modal-btn next"
              onClick={handleNextRequest}
            >
              <RightArrowIcon />
            </button>
          </div>
        )}
        {gallery.length > 1 && (
          <div className="modal-step">
            {gallery &&
              gallery.map((index: number) => {
                return (
                  <button
                    type="button"
                    className="modal-dot"
                    onClick={handleclickRequest}
                    key={index}
                  >
                    {' '}
                  </button>
                );
              })}
          </div>
        )}
      </ReactModal>
    </div>
  );
};
export { VideoModal };
