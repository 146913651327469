import { combineReducers } from 'redux';
import { NavReducer } from 'Redux/reducers/navReducer';
import {
  HomePageQuizReducer,
  NutritionPageQuizReducer,
  QuizModalReducer,
  CareerPageQuizReducer,
} from 'Redux/reducers/QuizReducer';
import { CartReducer } from 'Redux/reducers/cartReducer';
import { InventoryReducer } from 'Redux/reducers/inventoryReducer';

export default combineReducers({
  NavReducer,
  QuizModalReducer,
  HomePageQuizReducer,
  NutritionPageQuizReducer,
  CareerPageQuizReducer,
  CartReducer,
  InventoryReducer,
});
