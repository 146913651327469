import React, { useState, useEffect } from 'react';
import { EnquireFormModal, InfoPopup } from 'components';
import FitnessIcon from 'assets/svg/weight.svg';
import NutritionIcon from 'assets/svg/apple.svg';
import MindsetIcon from 'assets/svg/stopwatch.svg';
import StarIcon from 'assets/svg/stargreen.svg';
import CheckIcon from 'assets/svg/TickWithBlueBgIcon.svg';
import EnquiryIcon from 'assets/svg/EnquiryIcon.svg';
import Humm from 'assets/svg/humm.svg';
import InCorrectIcon from 'assets/svg/incorrect-mark.svg';

const WeightLossPlanSection = ({
  // weightLossPackageDescription,
  weightLossPackage,
  weightLossPackageService,
  // products,
  // functionUrl,
  humPayment,
  setActiveTitle,
}: any) => {
  const [InfoModal, setInfoModal] = useState(false);
  const [wlKey, setwlKeys] = useState() as any;
  const handleClick = (desc: any) => {
    setInfoModal(true);
    setwlKeys(desc);
  };

  const closeModal = () => {
    setInfoModal(false);
  };

  const [WeightlossFormModal, setWeightlossFormModal] = useState(false);
  const [formTitle, setFormTitle] = useState('');

  const formHandler = (form: string) => {
    setWeightlossFormModal(true);
    setFormTitle(form);
  };

  useEffect(() => {
    sessionStorage.removeItem('digitalCartId');
  }, []);
  return (
    <>
      <div className="weightloss-plan-desktop">
        <div className="weightloss-plan-package">
          <div className="weightloss-plan-product">
            <div className="weightloss-plan-product-item" />
            {weightLossPackage.edges.map((item: any) => {
              return (
                <div
                  className={`weightloss-plan-product-item ${
                    item.node.popular ? 'active' : ''
                  }`}
                  key={item.node.title}
                  onMouseOver={() => setActiveTitle(item.node.title)}
                  onFocus={() => setActiveTitle(item.node.title)}
                >
                  <div className="weightloss-plan-product-item-content">
                    <div className="weightloss-plan-product-item-heading">
                      {item.node.popular && (
                        <>
                          <div className="weightloss-plan-product-item-heading-tagline">
                            {' '}
                            {item.node.tagline}
                          </div>
                          <StarIcon />
                        </>
                      )}
                      <h1> {item.node.title}</h1>
                      {humPayment && (
                        <div className="humm">
                          <div className="humm-price">
                            {item.node.weeklyPrice} <span> / wk</span>
                          </div>
                          <div className="humm-price-detail">
                            {item.node.priceInstallmentText}
                          </div>
                          <Humm />
                          <div className="humm-price-total">
                            {item.node.hummTotalPrice}
                          </div>
                        </div>
                      )}
                      {!humPayment && (
                        <div className="weightloss-plan-product-item-heading-price">
                          {' '}
                          {item.node.price}
                        </div>
                      )}
                    </div>

                    <div className="weightloss-plan-product-item-wrapper">
                      {/* {products.edges.map((product: any) => {
                        return (
                          <>
                            {product.node.name === item.node.title && (
                              <AddToCartButton
                                productId={product.node.bigcommerce_id}
                                qty={1}
                                variantId={product.node.variants[0].id}
                                url={functionUrl}
                                isProductOutOfStock={false}
                                butttonLabel={
                                  weightLossPackageDescription.purchaseButtonText
                                }
                                digital
                              />
                            )}
                          </>
                        );
                      })} */}
                      <button
                        className="weightloss-plan-product-item-enquiry-wrapper"
                        type="button"
                        onClick={() => {
                          formHandler(item.node.title);
                        }}
                      >
                        <EnquiryIcon className="enquiry-icon" />
                        <p className="enquiry-title">Enquiry</p>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            <EnquireFormModal
              isEnquireModalOpen={WeightlossFormModal}
              setisEnquireModalOpen={setWeightlossFormModal}
              contactFormModalTitle={formTitle}
              optionalMessage
              dealTitle="weight-loss"
            />
          </div>

          <table className="weightloss-plan-package-content">
            {weightLossPackageService.edges.map((wlservice: any) => {
              let Icon;
              switch (wlservice.node.icon) {
                case 'Fitness':
                  Icon = <FitnessIcon />;
                  break;
                case 'Nutrition':
                  Icon = <NutritionIcon />;
                  break;
                case 'Bonus':
                  Icon = <MindsetIcon />;
                  break;
                default:
                  Icon = <FitnessIcon />;
                  break;
              }
              return (
                <tbody>
                  <div className="weightloss-plan-package-item">
                    <div className="weightloss-plan-package-item-svg">
                      {Icon}
                    </div>
                    <div className="weightloss-plan-package-item-title">
                      {wlservice.node.title}
                    </div>
                  </div>
                  {wlservice.node.weight_loss_keys.map(
                    (wlkeys: any, index: number) => {
                      return (
                        <tr>
                          <td
                            className={`keypoint-title key-title-${index + 1}`}
                          >
                            {' '}
                          </td>
                          <td
                            className={`keypoint-description key-description-${
                              index + 1
                            }`}
                          >
                            {wlkeys.infoPopup.length > 0 ? (
                              <button
                                type="button"
                                className="info-icon-btn"
                                onClick={() => {
                                  handleClick(wlkeys);
                                }}
                              >
                                <h5 className="popup-title">
                                  {wlkeys.description}
                                </h5>
                              </button>
                            ) : (
                              <h5>{wlkeys.description}</h5>
                            )}
                          </td>
                          {wlkeys.weightLossKeyPoint.map((item: any) => {
                            return weightLossPackage.edges.map(
                              (product: any) => {
                                return (
                                  product.node.title ===
                                    item.weightloss_package.title && (
                                    <td>
                                      {(item.availableText && (
                                        <h5>{item.availableText}</h5>
                                      )) ||
                                        (item.included === true ? (
                                          <CheckIcon />
                                        ) : (
                                          <InCorrectIcon />
                                        ))}
                                    </td>
                                  )
                                );
                              }
                            );
                          })}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
      <InfoPopup
        InfoModal={InfoModal}
        closeModal={closeModal}
        portalClassName="home-page-quiz-modal"
        weightLossPackageService={weightLossPackageService}
        wlKey={wlKey}
      />
    </>
  );
};

export { WeightLossPlanSection };
