export const TOOGLE_NAV = 'TOOGLE_NAV';
export const HOME_PAGE_QUIZ_MODAL_OPEN = 'HOME_PAGE_QUIZ_MODAL_OPEN';
export const HOME_PAGE_QUIZ_MODAL_CLOSE = 'HOME_PAGE_QUIZ_MODAL_CLOSE';
export const NUTRITION_PAGE_QUIZ_MODAL_OPEN = 'NUTRITION_PAGE_QUIZ_MODAL_OPEN';
export const NUTRITION_PAGE_QUIZ_MODAL_CLOSE =
  'NUTRITION_PAGE_QUIZ_MODAL_CLOSE';
export const SHOW_HOME_PAGE_QUIZ_NEXT_QUESTION =
  'SHOW_HOME_PAGE_QUIZ_NEXT_QUESTION';
export const SHOW_HOME_PAGE_QUIZ_PREV_QUESTION =
  'SHOW_HOME_PAGE_QUIZ_PREV_QUESTION';
export const SHOW_NUTRITION_PAGE_QUIZ_NEXT_QUESTION =
  'SHOW_NUTRITION_PAGE_QUIZ_NEXT_QUESTION';
export const SHOW_NUTRITION_PAGE_QUIZ_PREV_QUESTION =
  'SHOW_NUTRITION_PAGE_QUIZ_PREV_QUESTION';
export const SHOW_CAREER_PAGE_QUIZ_NEXT_QUESTION =
  'SHOW_CAREER_PAGE_QUIZ_NEXT_QUESTION';
export const SHOW_CAREER_PAGE_QUIZ_PREV_QUESTION =
  'SHOW_CAREER_PAGE_QUIZ_PREV_QUESTION';
export const SUBMIT_HOME_PAGE_QUIZ_AND_PREVIEW_ANSWERS =
  'SUBMIT_HOME_PAGE_QUIZ_AND_PREVIEW_ANSWERS';
export const SUBMIT_CAREER_PAGE_QUIZ = 'SUBMIT_CAREER_PAGE_QUIZ';
export const SUBMIT_NUTRITION_PAGE_QUIZ_AND_PREVIEW_ANSWERS =
  'SUBMIT_NUTRITION_PAGE_QUIZ_AND_PREVIEW_ANSWERS';
export const UPDATE_HOME_PAGE_QUIZ_PREVIEW_STATUS =
  'UPDATE_HOME_PAGE_QUIZ_PREVIEW_STATUS';
export const UPDATE_NUTRITION_PAGE_QUIZ_PREVIEW_STATUS =
  'UPDATE_NUTRITION_PAGE_QUIZ_PREVIEW_STATUS';
export const UPDATE_HOME_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS =
  'UPDATE_HOME_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS';
export const UPDATE_NUTRITION_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS =
  'UPDATE_NUTRITION_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS';
export const UPDATE_CAREER_PAGE_QUIZ_FORM = 'UPDATE_CAREER_PAGE_QUIZ_FORM';
export const RESET_HOME_PAGE_QUIZ = 'RESET_HOME_PAGE_QUIZ';
export const RESET_NUTRITION_PAGE_QUIZ = 'RESET_NUTRITION_PAGE_QUIZ';
export const RESET_CAREER_PAGE_QUIZ = 'RESET_CAREER_PAGE_QUIZ';

// Cart Action Type

export const SHOW_CART = 'SHOW_CART';
export const HIDE_CART = 'HIDE_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS =
  'UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS';
export const UPDATE_CART_LOADER_STATUS = 'UPDATE_CART_LOADER_STATUS';
export const UPDATE_ADD_TO_CART_ERROR = 'UPDATE_ADD_TO_CART_ERROR';
export const UPDATE_CART_ERROR = 'UPDATE_CART_ERROR';
export const CLEAR_CART_ERROR = 'CLEAR_CART_ERROR';
export const STORE_INVENTORY_DATA = 'STORE_INVENTORY_DATA';
export const UPDATE_CART_DIGITAL = 'UPDATE_CART_DIGITAL';
