/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideUpPageContentProps {
  children: any;
  className?: string;
}

export const SlideUpPageContent: FC<SlideUpPageContentProps> = ({
  children,
  className,
}) => {
  const showAnimations =
    process.env.GATSBY_SHOW_ANIMATIONS === undefined ||
    (process.env.GATSBY_SHOW_ANIMATIONS &&
      process.env.GATSBY_SHOW_ANIMATIONS === 'true');
  const ref = useRef(null);

  useEffect(() => {
    const ParagraphAnimationPosition =
      window.innerWidth < 576 ? 'top center+=220' : 'top center+=130';
    const element: any = ref.current;
    if (showAnimations && element != null) {
      gsap.fromTo(
        ref.current,
        { y: 100, opacity: 0 },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: element,
            start: ParagraphAnimationPosition,
            toggleActions: 'play none none reverse',
          },
        }
      );
    }
  }, [ref.current, showAnimations]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
