/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { toggleNav } from 'Redux/actions';
import { MenuQuery } from 'queries/main-nav';
import { HeaderNavigation } from 'components';
import { comparePath } from 'utils';
import { useLocation } from '@reach/router';

interface navLink {
  title: string;
  slug: string;
}

const Menu: FC = () => {
  const data = MenuQuery();
  const navLinks = data?.strapiGlobal?.mainNav?.navLink;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <>
      {navLinks && (
        <div className="menu-bar">
          <nav className="nav-container">
            <ul className="nav-list">
              {navLinks.map((item: navLink) => (
                <li
                  className={classNames('nav-item', {
                    active: comparePath(pathname, item.slug),
                  })}
                  key={item.title}
                >
                  <Link
                    to={item.slug}
                    onClick={() => {
                      setTimeout(() => {
                        dispatch(toggleNav());
                      }, 10);
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <HeaderNavigation pathname={pathname} />
          </nav>
        </div>
      )}
    </>
  );
};
export { Menu };
