/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react';
import { ContactMap } from 'components';
import { LocationComponentPropTypes } from 'types';
import ReactMarkdown from 'react-markdown';
import { Link, navigate } from 'gatsby';

const Locations: FC<LocationComponentPropTypes> = ({
  LocationFilter,
  locations,
  heading,
  contactUsUrl,
  selectedCity = 'Melbourne',
  content,
}) => {
  const [FilterValue, setFilterValue] = useState(selectedCity);
  const [FilterdLocation, setFilterdLocation] = useState(locations.edges);
  useEffect(() => {
    const filterdValue = locations.edges.filter((Suburb: any) => {
      return Suburb.node.location_state.name === FilterValue;
    });
    setFilterdLocation(filterdValue);
  }, [FilterValue]);
  const handelFilterClick = (filterTitle: string, slug: string) => {
    setFilterValue(filterTitle);
    navigate(`/${slug}`);
  };
  return (
    <div className="location-section">
      <div className="location white-gradient-background">
        <h3 className="location-heading">{heading}</h3>
        {content && (
          <ReactMarkdown className="location-description md-html">
            {content}
          </ReactMarkdown>
        )}
        <ul className="location-filters">
          {LocationFilter.edges.map(filter => {
            const { name: filterTitle, id, slug } = filter.node;
            return (
              <li
                className={`location-filter-item ${
                  FilterValue === filterTitle ? 'active' : ''
                }`}
                key={id}
              >
                <button
                  type="button"
                  onClick={() => handelFilterClick(filterTitle, slug)}
                >
                  {filterTitle}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="location-wrapper">
          <div className="location-lists">
            <ul>
              {FilterdLocation.map((location: any) => {
                const { id, title: locationTtile, slug } = location.node;
                return (
                  <li className="location-list-item" key={id}>
                    <Link to={`/${contactUsUrl}/${slug ?? locationTtile}`}>
                      {locationTtile}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="location-map">
            <ContactMap />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Locations };
