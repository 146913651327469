import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import {
  McqQuestion,
  LikertScaleQuestion,
  PostCodeQuestions,
  McqAndTextQuestion,
  QuestionnaireSummary,
  FormQuiz,
  ProgressIndicator,
} from 'components';

export const QuizModal: FC = ({
  isQuizModalOpen,
  portalClassName,
  handelQuizModal,
  formSuccessMessage,
  formSuccessTitle,
  QuizQuestions,
  currentQuestion,
  submitedQuestions,
  showPrevQuestion,
  showNextQuestion,
  previewAnswer,
  showQuizSummary,
  toogleQuizPreview,
  isQuizSummaryReviewed,
  updateQuizSummaryReviewedStatus,
  resetQuiz,
  dealTitle,
}: any) => {
  const dispatch = useDispatch();
  const handleModalClose = () => {
    handelQuizModal();
  };
  const SortedQuestions = QuizQuestions.edges.sort(
    (a: any, b: any) => a.node.questionOrderNumber - b.node.questionOrderNumber
  );
  const currentQuizItem = SortedQuestions[currentQuestion - 1];
  // state for form
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div>
      <ReactModal
        closeTimeoutMS={100}
        isOpen={isQuizModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName={`ReactModalPortal ${portalClassName}`}
      >
        <div className="quiz-question-item-wrapper">
          <div className="quiz-question-item-detail">
            <div className="quiz-question-item-wrapper-content">
              <button
                type="button"
                onClick={() => handleModalClose()}
                className="modal-close"
              >
                <span>&#x2715;</span>
              </button>
              {!showQuizSummary &&
                !isQuizSummaryReviewed &&
                currentQuestion - 1 > 0 &&
                !showSuccess && (
                  <button
                    type="button"
                    className="quiz-question-item-back"
                    onClick={() => dispatch(showPrevQuestion())}
                  >
                    Back
                  </button>
                )}
              {showQuizSummary && !showSuccess && (
                <button
                  type="button"
                  className="quiz-question-item-back"
                  onClick={() => dispatch(toogleQuizPreview())}
                >
                  Back
                </button>
              )}
              {isQuizSummaryReviewed && !showSuccess && (
                <button
                  type="button"
                  className="quiz-question-item-back"
                  onClick={() => dispatch(updateQuizSummaryReviewedStatus())}
                >
                  Back
                </button>
              )}
              {!showQuizSummary && !isQuizSummaryReviewed && (
                <div className="quiz-item-progress-indicator-wrapper">
                  {SortedQuestions.length && (
                    <ProgressIndicator
                      completedQuestions={submitedQuestions}
                      activeQuestion={currentQuestion}
                      SortedQuestions={SortedQuestions}
                    />
                  )}
                  <div className="quiz-item-progress-indicator-count">
                    <span>{currentQuestion}</span>/
                    <span>{SortedQuestions.length}</span>
                  </div>
                </div>
              )}
            </div>
            {!showQuizSummary &&
              !isQuizSummaryReviewed &&
              currentQuizItem.node.questionType ===
                'multipleChoiceQuestion' && (
                <McqQuestion
                  Question={currentQuizItem}
                  showNextQuestion={showNextQuestion}
                  submitedQuestions={submitedQuestions}
                  currentquestion={currentQuestion}
                  QuestionsListLength={SortedQuestions.length}
                  previewAnswer={previewAnswer}
                />
              )}
            {!showQuizSummary &&
              !isQuizSummaryReviewed &&
              currentQuizItem.node.questionType === 'postCheckQuestion' && (
                <PostCodeQuestions
                  Question={currentQuizItem}
                  showNextQuestion={showNextQuestion}
                  submitedQuestions={submitedQuestions}
                  currentquestion={currentQuestion}
                  QuestionsListLength={SortedQuestions.length}
                  previewAnswer={previewAnswer}
                />
              )}
            {!showQuizSummary &&
              !isQuizSummaryReviewed &&
              currentQuizItem.node.questionType === 'likertScaleQuestion' && (
                <LikertScaleQuestion
                  Question={currentQuizItem}
                  showNextQuestion={showNextQuestion}
                  submitedQuestions={submitedQuestions}
                  currentquestion={currentQuestion}
                  QuestionsListLength={SortedQuestions.length}
                  previewAnswer={previewAnswer}
                />
              )}
            {!showQuizSummary &&
              !isQuizSummaryReviewed &&
              currentQuizItem.node.questionType ===
                'textAndMultipleChoiceQuestion' && (
                <McqAndTextQuestion
                  Question={currentQuizItem}
                  showNextQuestion={showNextQuestion}
                  submitedQuestions={submitedQuestions}
                  currentquestion={currentQuestion}
                  QuestionsListLength={SortedQuestions.length}
                  previewAnswer={previewAnswer}
                />
              )}
            {showQuizSummary && (
              <QuestionnaireSummary
                updateQuizSummaryReviewedStatus={
                  updateQuizSummaryReviewedStatus
                }
                submitedQuestions={submitedQuestions}
                resetQuiz={resetQuiz}
              />
            )}
            {isQuizSummaryReviewed && (
              <FormQuiz
                submitedQuestions={submitedQuestions}
                resetQuiz={resetQuiz}
                handelQuizModal={handelQuizModal}
                formSuccessMessage={formSuccessMessage}
                formSuccessTitle={formSuccessTitle}
                showSuccess={showSuccess}
                setShowSuccess={setShowSuccess}
                dealTitle={dealTitle}
              />
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
