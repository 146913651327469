/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { PageHeading } from 'components';
import Quizstepone from 'assets/svg/quizstepone.svg';
import Quizsteptwo from 'assets/svg/quizsteptwo.svg';
import Quizstepthree from 'assets/svg/quizstepthree.svg';
import QuizProcess from 'assets/svg/quiz-progress.svg';
import { QuizWizardPropsType } from 'types';

const Quiz: FC<QuizWizardPropsType> = ({
  QuizWizardData,
  handelQuizModalOpen,
}) => {
  return (
    <section className="quiz-section">
      {QuizWizardData.title && (
        <PageHeading
          title={QuizWizardData.title}
          subtitle={QuizWizardData.tagline}
          description={QuizWizardData.description}
        />
      )}
      <div className="quiz_launch">
        <div className="quiz_launch_wrapper">
          <div className="quiz_launch_wrapper_item">
            <button
              className="quiz_start"
              onClick={() => handelQuizModalOpen()}
            >
              <span>{QuizWizardData.quizCTA.buttonText}</span>
            </button>
            <div className="quiz_launch_process">
              <div className="quiz_launch_content">
                <p>{QuizWizardData.quizCTA.description}</p>
                <QuizProcess />
              </div>
            </div>
          </div>
          <p className="quiz_launch_label">{QuizWizardData.quizCTA.title}</p>
        </div>
      </div>
      <div className="quiz">
        <div className="quiz_wrapper">
          <ul className="quiz_steps">
            {QuizWizardData.quizStep.map((step: any, i: number) => {
              const { title, icon } = step;
              const quizStepCount = `Step ${i + 1}`;
              return (
                <li key={title}>
                  <span className="quiz_steps_name">{quizStepCount}</span>
                  {icon === 'questionnaire' ? (
                    <div className="quiz_steps_icon">
                      <Quizstepone />
                    </div>
                  ) : icon === 'plan' ? (
                    <div className="quiz_steps_icon">
                      <Quizsteptwo />
                    </div>
                  ) : (
                    <div className="quiz_steps_icon">
                      <Quizstepthree />
                    </div>
                  )}
                  <div className="quiz_steps_info">{title}</div>
                </li>
              );
            })}
          </ul>
          <ul className="quiz_process">
            {QuizWizardData.quizAction.map((step: any) => {
              const { title, description } = step;
              return (
                <li key={title}>
                  <h3 className="quiz_process_name">{title}</h3>
                  <div className="quiz_process_info">{description}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export { Quiz };
