/* eslint-disable camelcase */
import React, { FC } from 'react';
import { locationsSectionComponentPropTypes } from 'types';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PageHeading } from 'components';

const LocationSection: FC<locationsSectionComponentPropTypes> = ({
  title,
  tagline,
  description,
  image,
}) => {
  const locationsImage: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div>
      <PageHeading title={title} />
      <section className="contact-us-section-container white-gradient-background">
        <div className="contact-us-section-location-content">
          <h3 className="contact-us-section-location-heading">{tagline}</h3>
          <div className="contact-us-section-location-description">
            <ReactMarkdown className="md-html">{description}</ReactMarkdown>
          </div>
        </div>
        <div className="contact-us-section-location-image">
          <GatsbyImage
            image={locationsImage}
            alt={image?.alternativeText ?? (title || '')}
          />
        </div>
      </section>
    </div>
  );
};

export { LocationSection };
