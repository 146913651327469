/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';

interface updateFormActionType {
  type: string;
  data: {
    name: string;
    value: string;
    hasError: boolean;
    error: string;
    isFormValid: boolean;
  };
}
// interface UpdateFormDispatch {
//   dispatch: (arg: updateFormActionType) => updateFormActionType;
// }

export const UPDATE_FORM = 'UPDATE_FORM';
export const RESET = 'RESET';

export const checkFormStatusOnInputChange = (
  formState: any,
  name: string,
  hasError: boolean
) => {
  let isFormValid = true;
  for (const key in formState) {
    const item = formState[key];
    // Check if the current field has error
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      // Check if any other field has error
      isFormValid = false;
      break;
    }
  }
  return isFormValid;
};

export const checkFormStatusOnSubmit = (
  formState: any,
  dispatch: React.Dispatch<updateFormActionType>
) => {
  let isFormValid = true;
  for (const name in formState) {
    const item = formState[name];
    const { value } = item;
    const { hasError, error } = validateInput(name, value);
    if (hasError && !item.optional) {
      isFormValid = false;
    }
    if (name) {
      dispatch({
        type: UPDATE_FORM,
        data: {
          name,
          value,
          hasError: item.optional ? false : hasError,
          error: item.optional ? '' : error,
          isFormValid,
        },
      });
    }
  }
  return isFormValid;
};
export const reset = (
  formState: any,
  dispatch: React.Dispatch<updateFormActionType>
) => {
  const isFormValid = false;
  for (const name in formState) {
    const value = '';
    const hasError = true;
    const error = '';

    if (name) {
      dispatch({
        type: UPDATE_FORM,
        data: {
          name,
          value,
          hasError,
          error,
          isFormValid,
        },
      });
    }
  }
};
/**
 * Triggered every time the value of the form changes
 */
export const onInputChange = (
  name: string,
  value: string,
  dispatch: React.Dispatch<updateFormActionType>,
  formState: any
) => {
  const { hasError } = validateInput(name, value);
  const isFormValid = checkFormStatusOnInputChange(formState, name, hasError);
  dispatch({
    type: UPDATE_FORM,
    data: {
      name,
      value,
      hasError: false,
      error: '',
      isFormValid,
    },
  });
};

export const validateInput = (name: string, value: string) => {
  let hasError = false;
  let error = '';
  switch (name) {
    case 'name':
      if (value.trim() === '') {
        hasError = true;
        error = 'Name cannot be empty';
      } else if (!/^[a-zA-Z ]+$/.test(value)) {
        hasError = true;
        error = 'Invalid Name. Avoid Special characters';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'email':
      if (value.trim() === '') {
        hasError = true;
        error = 'Email cannot be empty';
      } else if (
        !/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
          value
        )
      ) {
        hasError = true;
        error = 'Invalid Email';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'phoneNo':
      if (value.trim() === '') {
        hasError = true;
        error = 'Phone Number cannot be empty';
      } else if (!/^[0-9]{5,}$/.test(value)) {
        hasError = true;
        error = 'Please check the phone number';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'postCode':
      if (value.trim() === '') {
        hasError = true;
        error = 'Postcode cannot be empty';
      } else if (value.length < 4) {
        hasError = true;
        error = 'Postcode must be atleast 4 digits';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'subUrb':
      if (value.trim() === '') {
        hasError = true;
        error = 'Suburb cannot be empty';
      } else if (!/^[a-zA-Z ]+$/.test(value)) {
        hasError = true;
        error = 'Invalid Suburb. Avoid Special characters';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'callTime':
      if (value.trim() === '') {
        hasError = true;
        error = 'Best Time To Call cannot be empty';
      } else if (value.length < 2) {
        hasError = true;
        error = 'Enter a valid Time';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'message':
      if (value.trim() === '') {
        hasError = true;
        error = 'Message cannot be empty';
      } else if (value.length < 10) {
        hasError = true;
        error = 'Message must be atleast 10 letters long';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'streetAddress':
      if (value.trim() === '') {
        hasError = true;
        error = 'Address cannot be empty';
      } else if (value.length < 10) {
        hasError = true;
        error = 'Address must be atleast 10 letters long';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'heardAboutUs':
      if (value === '') {
        hasError = true;
        error = 'Please select one in the list';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'service':
      if (value === '') {
        hasError = true;
        error = 'Please select a service';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'managementType':
      if (value === '') {
        hasError = true;
        error = 'Please select one in the list';
      } else {
        hasError = false;
        error = '';
      }
      break;
    default:
      break;
  }
  return { hasError, error };
};
