import { useStaticQuery, graphql } from 'gatsby';

const SocialMediaQuery = () => {
  return useStaticQuery(graphql`
    query SocialMediaQuery {
      GlobalSocialMedia: strapiGlobal {
        socialMediaLinks {
          facebookLink
          instagramLink
        }
      }
      ContactUsPage: strapiContactUsPage {
        contactFormSection {
          email
        }
      }
    }
  `);
};
export { SocialMediaQuery };
