/* eslint-disable no-case-declarations */
import React, { FC, useState, useReducer, useEffect } from 'react';
import {
  UPDATE_FORM,
  onInputChange,
  checkFormStatusOnSubmit,
  reset,
} from 'utils';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import Loader from 'assets/svg/loader.svg';
import { useLocation } from '@reach/router';

type FormEvent = React.FormEvent<HTMLFormElement>;

interface initialStateKeys {
  [key: string]: any;
}
interface initialStateValueType extends initialStateKeys {
  value: string;
  hasError: boolean;
  error: string;
}

interface updateFormActionType {
  type: string;
  data: {
    name: string;
    value: string;
    hasError: boolean;
    error: string;
    isFormValid: boolean;
  };
}
interface initialStateForAllFieldType {
  email: initialStateValueType;
  isFormValid: boolean;
}

export const SubscribeForm: FC = () => {
  /**
   * Reducer which will perform form state update
   */
  const formsReducer = (
    state: initialStateForAllFieldType | any,
    action: updateFormActionType
  ) => {
    switch (action.type) {
      case UPDATE_FORM:
        const { name, value, hasError, error, isFormValid } = action.data;
        return {
          ...state,
          [name]: { ...state[name], value, hasError, error },
          isFormValid,
        };
      default:
        return state;
    }
  };

  const initialState: initialStateForAllFieldType = {
    email: { value: '', hasError: true, error: '' },
    isFormValid: false,
  };

  const [formState, dispatch] = useReducer(formsReducer, initialState);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [validation, changeValidation] = useState('');
  const [loading, isloading] = useState(false);
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const data: any = subscribeFormQuery();
  const { subscribeFormSuccessMessage, subscribeFormFailureMessage } =
    data.strapiGlobal.FormMessage;
  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault(); // prevents the form from submitting
    const isFormValid = checkFormStatusOnSubmit(formState, dispatch);
    const URL: string = data.site.siteMetadata.subscribeUrl;
    if (isFormValid) {
      isloading(true);
      axios
        .post(URL, {
          formState,
        })
        .then(response => {
          if (response) {
            setShowSuccess(true);
            isloading(false);
          }
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeValidation(subscribeFormFailureMessage);
          } else {
            changeValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
          }
          setShowError(true);
          isloading(false);
        });
    }

    // Hide the error message after 5 seconds
    setTimeout(() => {
      setShowError(false);
      setShowSuccess(false);
    }, 10000);
  };

  useEffect(() => {
    if (showSuccess === true) reset(formState, dispatch);
  }, [showSuccess]);

  useEffect(() => {
    reset(formState, dispatch);
  }, [pathname]);

  return (
    <div className="subscribe">
      {showError && <div className="submit-message">{validation}</div>}
      {showSuccess && (
        <div className="submit-message">{subscribeFormSuccessMessage}</div>
      )}
      <form className="subscribe-form" onSubmit={e => formSubmitHandler(e)}>
        <input
          type="text"
          placeholder="Enter your email"
          value={formState.email.value}
          onChange={e => {
            onInputChange('email', e.target.value, dispatch, formState);
          }}
        />
        <button type="submit">
          {' '}
          {!loading ? (
            'Stay In The Loop'
          ) : (
            <div className="form-action-loader">
              <Loader />
            </div>
          )}
        </button>
      </form>
      {formState.email.hasError && (
        <div className="submit-message">{formState.email.error}</div>
      )}
    </div>
  );
};

const subscribeFormQuery = () => {
  return useStaticQuery(graphql`
    query subscribeFormQuery {
      site {
        siteMetadata {
          subscribeUrl
        }
      }
      strapiGlobal {
        FormMessage {
          subscribeFormSuccessMessage
          subscribeFormFailureMessage
        }
      }
    }
  `);
};
