import moment from 'moment';

export const getFromAndToTime = (From: string, To: string) => {
  const from = moment(From);
  const to = moment(To);
  const fromday = moment(From).format('DD');
  const today = moment(To).format('DD');
  const fromPeriod = moment(From).format('a');
  const toPeriod = moment(To).format('a');
  const fromMonth = moment(From).format('MMM');
  const toMonth = moment(To).format('MMM');
  if (fromMonth !== toMonth || fromday !== today) {
    return `${from.format('ddd, DD MMM, hh:mm a')} - ${to.format(
      'ddd, DD MMM, hh:mm a'
    )}`;
  }
  if (fromPeriod !== toPeriod) {
    return `${from.format('ddd, DD MMM, hh:mm a')} - ${to.format('hh:mm a')}`;
  }

  return `${from.format('ddd, DD MMM, hh:mm')} - ${to.format('hh:mm a')}`;
};

export const getDate = (date: string) => {
  return moment(date).format('DD');
};

export const getMonth = (date: string) => {
  return moment(date).format('MMM');
};
export const getDateMonthYear = (date: string) => {
  return moment(date).format('DD-MM-YY');
};
export const comparePath = (urlA: string, urlB: string) => {
  if (urlA === urlB) {
    return true;
  }
  return urlB !== '/' && urlA.startsWith(urlB);
};

export const getFormatedDate = (date: string) => {
  return moment(date).format('MMM DD, YYYY');
};

export const getDateandTime = (date: string) => {
  moment.relativeTimeThreshold('d', 7);
  moment.relativeTimeThreshold('w', 4);
  return moment(date).fromNow();
};

export const truncate = (str: string, max: number) => {
  const suffix = '...';
  return str.length < max
    ? str
    : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(' ')
      )}${suffix}`;
};

export const getprice = (num: string) => {
  const decimal = String(num).split('.');
  const price = decimal && decimal.length > 2 ? decimal.length : 2;
  return Number(num).toFixed(price);
};

export const getDieticianCount = (services: any, title: string) => {
  const dietitianService = services.filter((service: any) => {
    return service.node.title === title;
  });
  return dietitianService[0].node.dietitians.length;
};
export const getProductCategories = (
  categories: any,
  productCategories: any
) => {
  const shopAllID = categories.find((allCategory: any) => {
    return allCategory.name === 'Shop All';
  }).bigcommerce_id;

  return productCategories
    .filter((productCategory: any) => productCategory !== shopAllID)
    .map((category: any) => {
      return categories.find(
        (allCategory: any) => allCategory.bigcommerce_id === category
      ).name;
    })
    .join(', ');
};
