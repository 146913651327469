import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

interface flipCardProps {
  card1: any;
  card2: any;
  card1Class?: string;
  card2Class?: string;
  index: number;
}

const FlipCard: FC<flipCardProps> = ({
  card1,
  card2,
  card1Class,
  card2Class,
  index,
}) => {
  const [flip, setflip] = useState(0);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
    trackVisibility: true,
    delay: 750,
  });
  const isTouchScreenDevice = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div
      className={`flip-card ${
        inView && isTouchScreenDevice() ? 'visible' : ''
      }`}
      ref={ref}
    >
      <div
        className={`flip-card__wrapper ${
          flip === index ? 'flip-card__content' : ''
        }`}
        onTouchStart={() => setflip(index)}
      >
        <div
          className={classNames('flip-card__card', {
            [`${card1Class}`]: !!card1Class,
          })}
        >
          {card1}
        </div>
        <div
          className={classNames('flip-card__card', 'back', {
            [`${card2Class}`]: !!card2Class,
          })}
        >
          {card2}
        </div>
      </div>
    </div>
  );
};

export { FlipCard };
