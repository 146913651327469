import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import CorrectIcon from 'assets/svg/correct-mark.svg';
import InCorrectIcon from 'assets/svg/incorrect-mark.svg';
import { SlideIn } from 'components/animations';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const WeightPackage = ({ weightLoss }: any) => {
  const [SortedWLValue, setSortedWLValue] = useState(weightLoss);
  const [windowWidth, setWindowWidth] = useState(0);
  const sortArray = () => {
    weightLoss.sort((a: any, b: any) => a.active - b.active);
  };
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    if (window.innerWidth < 992) {
      sortArray();
    }
    setSortedWLValue(weightLoss);
  }, [windowWidth]);
  return (
    <>
      <div className="weight-package-banner">
        <div className="weight-package-banner-circle-container">
          {SortedWLValue.map((item: any, index: number) => {
            const { id, title, tagline, description, active, logoImage } = item;
            const direction = (index + 1) % 2 === 0 ? 'right' : 'left';
            const logimg: any = logoImage
              ? getImage(logoImage?.localFile?.childImageSharp?.gatsbyImageData)
              : null;
            return (
              <SlideIn
                direction={direction}
                className={`weight-package-banner-circle ${
                  active === true ? 'active' : ''
                } `}
                key={id}
              >
                <h2 className="weight-package-banner-details-title title-mobile">
                  {title}
                </h2>
                <div className="weight-package-banner-circle-wrapper">
                  <div className="weight-package-banner-circle-list">
                    {active === true ? (
                      <CorrectIcon className="correct-icon" />
                    ) : (
                      <InCorrectIcon className="incorrect-icon" />
                    )}
                  </div>
                  <div className="weight-package-banner-details">
                    <div className="weight-package-banner-details-wrapper">
                      {logimg ? (
                        <div className="weight-package-banner-details-img">
                          <GatsbyImage
                            image={logimg}
                            alt={logimg?.alternativeText}
                          />
                        </div>
                      ) : (
                        <h2 className="weight-package-banner-details-title title-desktop">
                          {title}
                        </h2>
                      )}

                      <p className="weight-package-banner-details-desc">
                        {tagline}
                      </p>
                      <div className="weight-package-banner-details-list">
                        <ReactMarkdown>{description}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              </SlideIn>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { WeightPackage };
