/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-case-declarations */
import React, { FC, useReducer, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  UPDATE_FORM,
  onInputChange,
  checkFormStatusOnSubmit,
  reset,
} from 'utils';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import { useLocation } from '@reach/router';
import Loader from 'assets/svg/loader.svg';

type FormEvent = React.FormEvent<HTMLFormElement>;

interface FormQuizPropsType {
  submitedQuestions: any;
  resetQuiz: any;
  handelQuizModal: any;
  formSuccessMessage: string;
  formSuccessTitle: string;
  showSuccess: any;
  setShowSuccess: any;
  dealTitle: string;
}

interface initialStateKeys {
  [key: string]: any;
}
interface initialStateValueType extends initialStateKeys {
  value: string;
  hasError: boolean;
  error: string;
}

interface updateFormActionType {
  type: string;
  data: {
    name: string;
    value: string;
    hasError: boolean;
    error: string;
    isFormValid: boolean;
  };
}
interface initialStateForAllFieldType {
  name: initialStateValueType;
  email: initialStateValueType;
  phoneNo: initialStateValueType;
  postCode: initialStateValueType;
  messageOptional?: initialStateValueType;
  service?: initialStateValueType;
  dealTitle?: initialStateValueType;
  isFormValid: boolean;
}

const FormQuiz: FC<FormQuizPropsType> = ({
  submitedQuestions,
  resetQuiz,
  handelQuizModal,
  formSuccessMessage,
  formSuccessTitle,
  showSuccess,
  setShowSuccess,
  dealTitle,
}) => {
  const initialState: initialStateForAllFieldType = {
    name: { value: '', hasError: true, error: '' },
    email: { value: '', hasError: true, error: '' },
    messageOptional: { value: '', hasError: true, error: '' },
    phoneNo: { value: '', hasError: true, error: '' },
    postCode: { value: '', hasError: true, error: '' },
    service: { value: '', hasError: true, error: '' },
    dealTitle: { value: '', hasError: true, error: '' },
    isFormValid: false,
  };
  const data: any = quizFormQuery();
  const URL: string = data.site.siteMetadata.quizUrl;
  const formsReducer = (
    state: initialStateForAllFieldType | any,
    action: updateFormActionType
  ) => {
    switch (action.type) {
      case UPDATE_FORM:
        const { name, value, hasError, error, isFormValid } = action.data;
        return {
          ...state,
          [name]: { ...state[name], value, hasError, error },
          isFormValid,
        };
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(formsReducer, initialState);
  const [showError, setShowError] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [validation, changeValidation] = useState('');
  const { pathname } = useLocation();
  const set = useDispatch();
  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault();
    const isFormValid = checkFormStatusOnSubmit(formState, dispatch);
    if (isFormValid) {
      setFormSubmitted(true);
      const questionnaire = submitedQuestions.map((element: any) => {
        const obj = {
          [element.question]: Array.isArray(element.answer)
            ? element.optionSummary
            : element.answer,
          ...(typeof element.selectedLocation?.pincode !== 'undefined' && {
            Location: `${element.selectedLocation.subUrb} ${element.selectedLocation.state} ${element.selectedLocation.pincode}`,
          }),
        };
        return obj;
      });
      const heardAboutQuestion = submitedQuestions.find((question: any) =>
        question.optionSummary.startsWith('Heard about')
      );
      const updatedFormState = {
        ...formState,
        heardAboutUs: heardAboutQuestion
          ? { value: heardAboutQuestion.answer, hasError: false, error: '' }
          : {
              value: '',
              hasError: false,
              error: '',
            },
        dealTitle: { value: dealTitle, hasError: true, error: '' },
      };
      axios
        .post(URL, {
          formState: updatedFormState,
          questionnaire,
        })
        .then(() => {
          setShowSuccess(true);
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeValidation('Sorry something went wrong');
          } else {
            changeValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
            setShowError(true);
          }
        });
    }
  };

  const completeQuiz = (e: FormEvent) => {
    e.preventDefault();
    handelQuizModal();
    setTimeout(() => {
      set(resetQuiz());
    }, 500);
  };

  useEffect(() => {
    reset(formState, dispatch);
  }, [pathname]);

  return (
    <div className="quiz-form-section">
      <div className="quiz-form">
        <div className="quiz-form-wrapper">
          <h3>
            <span>Your tailored plan made just for you</span>
          </h3>
          <div className="quiz-form-container">
            {!showSuccess && !showError && (
              <h4 className="quiz-form-title">
                A click away from changing your life
              </h4>
            )}
            {showSuccess && (
              <>
                <h4 className="quiz-form-title">{formSuccessTitle}</h4>
                <p>{formSuccessMessage}</p>
                <form onSubmit={e => completeQuiz(e)}>
                  <button className="quiz-form-submit-btn" type="submit">
                    <span>Close</span>
                  </button>
                </form>
              </>
            )}
            {showError && (
              <>
                <h4 className="quiz-form-title">{validation}</h4>
                <p>Please try again</p>
              </>
            )}
            {!showSuccess && !showError && (
              <form onSubmit={e => formSubmitHandler(e)}>
                <div className="quiz-form-row-1">
                  <div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      disabled={isFormSubmitted}
                      className="quiz-form-field"
                      value={formState.name.value}
                      onChange={e => {
                        onInputChange(
                          'name',
                          e.target.value,
                          dispatch,
                          formState
                        );
                      }}
                    />
                    {formState.name.hasError && (
                      <div className="submit-message">
                        {formState.name.error}
                      </div>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Poscode"
                      className="quiz-form-field"
                      name="postCode"
                      disabled={isFormSubmitted}
                      onChange={e => {
                        onInputChange(
                          'postCode',
                          e.target.value,
                          dispatch,
                          formState
                        );
                      }}
                      value={formState.postCode.value}
                    />
                    {formState.postCode.hasError && (
                      <div className="submit-message">
                        {formState.postCode.error}
                      </div>
                    )}
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  className="quiz-form-field"
                  name="email"
                  disabled={isFormSubmitted}
                  onChange={e => {
                    onInputChange('email', e.target.value, dispatch, formState);
                  }}
                  value={formState.email.value}
                />
                {formState.email.hasError && (
                  <div className="submit-message">{formState.email.error}</div>
                )}
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="quiz-form-field"
                  name="phoneNo"
                  disabled={isFormSubmitted}
                  onChange={e => {
                    onInputChange(
                      'phoneNo',
                      e.target.value,
                      dispatch,
                      formState
                    );
                  }}
                  value={formState.phoneNo.value}
                />
                {formState.phoneNo.hasError && (
                  <div className="submit-message">
                    {formState.phoneNo.error}
                  </div>
                )}

                <select
                  className="form-field form_Select quiz-form-field"
                  onChange={e => {
                    onInputChange(
                      'service',
                      e.target.value,
                      dispatch,
                      formState
                    );
                  }}
                  value={formState.service.value}
                >
                  <option value="" disabled>
                    Please select the service.
                  </option>
                  <option value="Personal Trainers">Personal Training</option>
                  <option value="Dietitians">Dietetics</option>
                </select>
                {formState.service.hasError && (
                  <div className="submit-message">
                    {formState.service.error}
                  </div>
                )}

                <textarea
                  placeholder="Message (Optional)"
                  className="quiz-form-field"
                  name="messageOptional"
                  disabled={isFormSubmitted}
                  onChange={e => {
                    onInputChange(
                      'messageOptional',
                      e.target.value,
                      dispatch,
                      formState
                    );
                  }}
                  value={formState.messageOptional.value}
                  rows={6}
                  cols={20}
                />
                {formState.messageOptional.hasError && (
                  <div className="submit-message">
                    {formState.messageOptional.error}
                  </div>
                )}
                <p className="quiz-form-item quiz-form-content">
                  Send your curated planand we’ll get back to you with a
                  customised and tailored price that’s made just for you. Stay
                  tuned.
                </p>
                <button
                  type="submit"
                  className="quiz-form-submit-btn"
                  disabled={isFormSubmitted}
                >
                  {isFormSubmitted && (
                    <span className="form-action-loader">
                      <Loader />
                    </span>
                  )}
                  {!isFormSubmitted && <span>Send my Plan</span>}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const quizFormQuery = () => {
  return useStaticQuery(graphql`
    query quizFormQuery {
      site {
        siteMetadata {
          quizUrl
        }
      }
    }
  `);
};

export { FormQuiz };
