import {
  SHOW_CART,
  HIDE_CART,
  UPDATE_CART,
  REMOVE_CART,
  UPDATE_CART_LOADER_STATUS,
  UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS,
  UPDATE_ADD_TO_CART_ERROR,
  UPDATE_CART_ERROR,
  CLEAR_CART_ERROR,
  UPDATE_CART_DIGITAL,
} from 'Redux/actionTypes';

type InitialStateType = {
  isCartOpen: boolean;
  cartId: string;
  digitalCartID: string;
  cartDetails: Object;
  cartItems: Array<Object>;
  showLoaderForAddToCartButton: boolean;
  showLoaderForCart: boolean;
  errorMessageForAddToCartButton: string;
  errorMessageForCart: string;
  couponCode: string;
  appliedCoupons: Array<any>;
};

const InitialState: InitialStateType = {
  isCartOpen: false,
  cartId: '',
  digitalCartID: '',
  cartDetails: {},
  cartItems: [],
  showLoaderForAddToCartButton: false,
  showLoaderForCart: false,
  errorMessageForAddToCartButton: '',
  errorMessageForCart: '',
  couponCode: '',
  appliedCoupons: [],
};

const CartReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case SHOW_CART:
      return { ...state, isCartOpen: true };
    case HIDE_CART:
      return { ...state, isCartOpen: false };
    case UPDATE_CART:
      sessionStorage.setItem('cartId', action.payload.id);
      return {
        ...state,
        cartDetails: action.payload,
        cartItems: action.payload.line_items.physical_items,
        showLoaderForCart: false,
        showLoaderForAddToCartButton: false,
        cartId: action.payload.id,
        errorMessageForAddToCartButton: '',
        errorMessageForCart: '',
      };
    case UPDATE_CART_DIGITAL:
      sessionStorage.setItem('digitalCartId', action.payload.id);
      return {
        ...state,
        digitalCartID: action.payload.id,
      };
    case REMOVE_CART:
      sessionStorage.removeItem('cartId');
      sessionStorage.removeItem('digitalCartId');
      return {
        ...state,
        cartDetails: {},
        cartItems: [],
        showLoaderForCart: false,
        showLoaderForAddToCartButton: false,
        cartId: '',
        errorMessageForAddToCartButton: '',
        errorMessageForCart: '',
      };
    case UPDATE_CART_LOADER_STATUS:
      return {
        ...state,
        showLoaderForCart: true,
      };
    case UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS:
      return {
        ...state,
        showLoaderForAddToCartButton: true,
      };
    case UPDATE_CART_ERROR:
      return {
        ...state,
        showLoaderForCart: false,
        errorMessageForCart: action.payload,
      };
    case UPDATE_ADD_TO_CART_ERROR:
      return {
        ...state,
        showLoaderForAddToCartButton: false,
        errorMessageForAddToCartButton: action.payload,
      };
    case CLEAR_CART_ERROR:
      return {
        ...state,
        errorMessageForAddToCartButton: '',
      };
    default:
      return state;
  }
};
export { CartReducer };
