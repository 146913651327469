/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { FC, useState, useReducer, useEffect } from 'react';
import {
  UPDATE_FORM,
  onInputChange,
  checkFormStatusOnSubmit,
  reset,
} from 'utils';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import Loader from 'assets/svg/loader.svg';
import RightArrow from 'assets/svg/arrow-right-icon.svg';
import { useLocation } from '@reach/router';

type FormEvent = React.FormEvent<HTMLFormElement>;

interface initialStateKeys {
  [key: string]: any;
}
interface initialStateValueType extends initialStateKeys {
  value: string;
  hasError: boolean;
  error: string;
  optional?: boolean;
}

interface updateFormActionType {
  type: string;
  data: {
    name: string;
    value: string;
    hasError: boolean;
    error: string;
    isFormValid: boolean;
  };
}
interface initialStateForAllFieldType {
  name: initialStateValueType;
  email: initialStateValueType;
  postCode: initialStateValueType | any | undefined;
  streetAddress: initialStateValueType | any | undefined;
  phoneNo: initialStateValueType | any | undefined;
  heardAboutUs: initialStateValueType | any | undefined;
  isFormValid: boolean;
  dealTitle: initialStateValueType;
  condition: initialStateValueType | any | undefined;
  managementType: initialStateValueType | any | undefined;
}
interface EnquireContactFormPropType {
  optionalMessage: boolean;
  setFormSubmited?: any;
  title?: string;
  trainer?: string;
  dietitian?: string;
  dealTitle?: string;
  posCodeValue?: any;
  showNdisFields?: boolean;
}
const EnquireContactForm: FC<EnquireContactFormPropType> = ({
  optionalMessage,
  setFormSubmited,
  title,
  trainer,
  dietitian,
  dealTitle,
  posCodeValue,
  showNdisFields,
}) => {
  const initialState: initialStateForAllFieldType = {
    name: { value: '', hasError: true, error: '' },
    email: { value: '', hasError: true, error: '' },
    postCode: { value: '', hasError: true, error: '' },
    streetAddress: {
      value: '',
      hasError: true,
      error: '',
      optional: optionalMessage,
    },
    phoneNo: { value: '', hasError: true, error: '' },
    heardAboutUs: { value: '', hasError: true, error: '' },
    dealTitle: { value: '', hasError: true, error: '' },
    condition: {
      value: '',
      hasError: true,
      error: '',
      optional: true,
    },
    managementType: {
      value: '',
      hasError: true,
      error: '',
      optional: !showNdisFields,
    },
    isFormValid: false,
  };
  /**
   * Reducer which will perform form state update
   */
  const formsReducer = (
    state: initialStateForAllFieldType | any,
    action: updateFormActionType
  ) => {
    const trainerObj: any = trainer
      ? { trainer: { value: trainer, hasError: false, error: '' } }
      : {};
    const dietitianObj: any = dietitian
      ? { dietitian: { value: dietitian, hasError: false, error: '' } }
      : {};
    switch (action.type) {
      case UPDATE_FORM:
        const { name, value, hasError, error, isFormValid } = action.data;
        return {
          ...state,
          [name]: { ...state[name], value, hasError, error },
          ...trainerObj,
          ...dietitianObj,
          isFormValid,
        };
      default:
        return { ...state, ...trainerObj, ...dietitianObj };
    }
  };
  const data = EnquireContactFormQuery();
  const [formState, dispatch] = useReducer(formsReducer, initialState);
  const [showError, setShowError] = useState(false);
  const [validation, changeValidation] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, isloading] = useState(false);
  const { pathname } = useLocation();
  const { contactFormSuccessMessage, contactFormFailureMessage } =
    data.strapiGlobal.FormMessage;
  const hearaboutusForm = data.strapiGlobal.footer.contactForm;
  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault(); // prevents the form from submitting
    const isFormValid = checkFormStatusOnSubmit(formState, dispatch);
    const URL: string = data.site.siteMetadata.formUrl;
    if (isFormValid) {
      isloading(true);
      if (showNdisFields) {
        formState.message = {
          value: `Management type ${formState.managementType.value} ${
            formState?.condition?.value
              ? `- Condition ${formState.condition.value}`
              : ''
          }`,
        };
      }

      axios
        .post(URL, {
          formState: {
            ...formState,
            dealTitle: { value: dealTitle, hasError: true, error: '' },
          },
        })
        .then(response => {
          if (response) {
            setShowSuccess(true);
            isloading(false);
            if (setFormSubmited) setFormSubmited(true);
          }
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeValidation(contactFormFailureMessage);
          } else {
            changeValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
          }
          setShowError(true);
          isloading(false);
        });
    }

    // Hide the error message after 10 seconds
    setTimeout(() => {
      setShowError(false);
      setShowSuccess(false);
    }, 15000);
  };

  useEffect(() => {
    if (showSuccess === true) reset(formState, dispatch);
  }, [showSuccess]);

  useEffect(() => {
    reset(formState, dispatch);
  }, [pathname]);

  useEffect(() => {
    if (posCodeValue && posCodeValue !== '') {
      onInputChange('postCode', posCodeValue, dispatch, formState);
    }
  }, [posCodeValue]);

  return (
    <form onSubmit={e => formSubmitHandler(e)}>
      {showError && <div>{validation}</div>}
      {showSuccess && (
        <div className="submit-message">{contactFormSuccessMessage}</div>
      )}
      <div className="enquiry-form-section">
        <div className="enquiry-form-fields">
          <div className="enquiry-form-fields-input">
            <input
              type="text"
              id="get-in-touch-name"
              placeholder="Name"
              onChange={e => {
                onInputChange('name', e.target.value, dispatch, formState);
              }}
              name="name"
              value={formState.name.value}
              className="form-field"
            />

            {formState.name.hasError && (
              <div className="submit-message">{formState.name.error}</div>
            )}
          </div>
          <div className="enquiry-form-fields-input">
            <input
              type="tel"
              placeholder="Phone"
              onChange={e => {
                onInputChange('phoneNo', e.target.value, dispatch, formState);
              }}
              name="phoneNo"
              value={formState.phoneNo.value}
              className="form-field"
            />
            {formState.phoneNo.hasError && (
              <div className="submit-message">{formState.phoneNo.error}</div>
            )}
          </div>
        </div>
        <input
          type="text"
          placeholder="E-mail"
          onChange={e => {
            onInputChange('email', e.target.value, dispatch, formState);
          }}
          name="email"
          value={formState.email.value}
          className="form-field"
        />
        {formState.email.hasError && (
          <div className="submit-message">{formState.email.error}</div>
        )}
        <div className="enquiry-form-fields">
          <div className="enquiry-form-fields-input address-field">
            {!optionalMessage ? (
              <>
                <input
                  type="text"
                  placeholder="Street Address"
                  onChange={e => {
                    onInputChange(
                      'streetAddress',
                      e.target.value,
                      dispatch,
                      formState
                    );
                  }}
                  name="streetAddress"
                  value={formState.streetAddress.value}
                  className="form-field"
                />
                {formState.streetAddress.hasError && (
                  <div className="submit-message">
                    {formState.streetAddress.error}
                  </div>
                )}
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Street Address (optional)"
                  onChange={e => {
                    onInputChange(
                      'streetAddress',
                      e.target.value,
                      dispatch,
                      formState
                    );
                  }}
                  name="streetAddress"
                  value={formState.streetAddress.value}
                  className="form-field"
                />
                {formState.streetAddress.hasError && (
                  <div className="submit-message">
                    {formState.streetAddress.error}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="enquiry-form-fields-input">
            <input
              type="number"
              placeholder="Postcode"
              onChange={e => {
                onInputChange('postCode', e.target.value, dispatch, formState);
              }}
              name="postCode"
              value={formState.postCode.value}
              className="form-field"
            />
            {formState.postCode.hasError && (
              <div className="submit-message">{formState.postCode.error}</div>
            )}
          </div>
        </div>
        <select
          className="form-field form_Select"
          onChange={e => {
            onInputChange('heardAboutUs', e.target.value, dispatch, formState);
          }}
          value={formState.heardAboutUs.value}
        >
          <option value="" disabled>
            Please select how you heard about us?
          </option>
          {hearaboutusForm.heardAboutUS.map((item: any) => {
            return (
              <option value={item.title} key={item.id}>
                {item.title}
              </option>
            );
          })}
        </select>
        {formState.heardAboutUs.hasError && (
          <div className="submit-message">{formState.heardAboutUs.error}</div>
        )}
        {showNdisFields && (
          <>
            <select
              className="form-field form_Select"
              onChange={e => {
                onInputChange(
                  'managementType',
                  e.target.value,
                  dispatch,
                  formState
                );
              }}
              value={formState.managementType.value}
            >
              <option value="" disabled>
                Self-managed/Support Coordinator Managed
              </option>
              <option value="self-managed">Self-managed</option>
              <option value="support-coordinator-managed">
                Support Coordinator Manage
              </option>
            </select>
            {formState.managementType.hasError && (
              <div className="submit-message">
                {formState.managementType.error}
              </div>
            )}
            <div className="enquiry-form-fields-input">
              <input
                type="text"
                placeholder="Condition (Optional)"
                onChange={e => {
                  onInputChange(
                    'condition',
                    e.target.value,
                    dispatch,
                    formState
                  );
                }}
                name="condition"
                value={formState.condition.value}
                className="form-field"
              />

              {formState.condition.hasError && (
                <div className="submit-message">
                  {formState.condition.error}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {/* {!optionalMessage ? (
        <>
          <textarea
            name="message"
            placeholder="Message"
            rows={6}
            cols={20}
            onChange={e => {
              onInputChange('message', e.target.value, dispatch, formState);
            }}
            value={formState.message.value}
            className="form-field"
          />
          {formState.message.hasError && (
            <div className="submit-message">{formState.message.error}</div>
          )}
        </>
      ) : (
        <>
          <textarea
            name="message"
            placeholder="Message (optional)"
            rows={6}
            cols={20}
            onChange={e => {
              dispatch({
                type: UPDATE_FORM,
                data: {
                  name: 'message',
                  value: e.target.value,
                  hasError: false,
                  error: '',
                  isFormValid: true,
                },
              });
            }}
            value={formState.message.value}
            className="form-field"
          />
          {formState.message.hasError && (
            <div className="submit-message">{formState.message.error}</div>
          )}
        </>
      )} */}

      <button type="submit" className="form-action" disabled={loading}>
        {!loading ? (
          <span>
            <RightArrow />
          </span>
        ) : (
          <div className="form-action-loader">
            <span>
              <Loader />
            </span>
          </div>
        )}
      </button>
    </form>
  );
};
export { EnquireContactForm };

const EnquireContactFormQuery = () => {
  return useStaticQuery(graphql`
    query EnquireContactFormQuery {
      site {
        siteMetadata {
          formUrl
        }
      }
      strapiGlobal {
        FormMessage {
          contactFormSuccessMessage
        }
        footer {
          contactForm {
            heardAboutUS {
              id
              title
            }
          }
        }
      }
    }
  `);
};
