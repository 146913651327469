/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface LoadMoreProps {
  children: any;
  className?: string;
  delaySec?: number;
  cardClassName?: string;
}

export const LoadMore: FC<LoadMoreProps> = ({
  children,
  className,
  delaySec = 0,
  cardClassName,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const ParagraphAnimationPosition =
      window.innerWidth < 576
        ? 'top-=0 bottom-=10'
        : window.innerWidth < 990
        ? 'top-=10 bottom-=160'
        : 'top-=180 bottom-=220';
    const delayTime = window.innerWidth < 1400 ? 0 : delaySec;
    const element: any = ref.current;
    if (element != null) {
      const lines = element.querySelectorAll(cardClassName);
      for (let i = 0; i < lines.length; i += 1) {
        gsap.fromTo(
          lines[i],
          { autoAlpha: 0, y: 100 },
          {
            duration: 0.9,
            autoAlpha: 1,
            y: 0,
            delay: delayTime,
            scrollTrigger: {
              trigger: lines[i],
              start: ParagraphAnimationPosition,
              toggleActions: 'play none none reverse',
            },
          }
        );
      }
    }
  }, []);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
