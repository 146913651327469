import React, { FC, useState, useEffect } from 'react';
import { QuizQuestion } from 'types';
import { useDispatch } from 'react-redux';
import TickIcon from 'assets/svg/TickWithBlueBgIcon.svg';

interface McqQuestionPropTypes {
  Question: QuizQuestion;
  showNextQuestion: any;
  submitedQuestions: any;
  currentquestion: number;
  QuestionsListLength: number;
  previewAnswer: any;
}

const McqAndTextQuestion: FC<McqQuestionPropTypes> = ({
  Question,
  showNextQuestion,
  submitedQuestions,
  currentquestion,
  QuestionsListLength,
  previewAnswer,
}) => {
  const dispatch = useDispatch();
  const {
    question,
    multipleChoiceQuestionTypeOptions,
    description,
    TextQuestionTypeOption,
  } = Question.node;
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionSummary, setSelectedOptionSummary] = useState('');
  const InputFieldInitialState: any = {};

  TextQuestionTypeOption.forEach((item: any) => {
    InputFieldInitialState[item.value] = '';
  });
  const [inputFieldState, setInputFieldState] = useState(
    InputFieldInitialState
  );
  const handelOptionSelection = (item: any) => {
    setSelectedOption(item.value);
  };
  const handelInputChange = (e: any, currentLabel: string) => {
    setInputFieldState((prevState: any) => ({
      ...prevState,
      [currentLabel]: e.target.value,
    }));
  };
  const handelNextQuestion = () => {
    const InputTypeSummary = TextQuestionTypeOption.map((item: any) => {
      return `${inputFieldState[item.value]}${item.optionSummaryContent}`;
    }).join(' ');
    const McqTypeSummary = multipleChoiceQuestionTypeOptions.filter(
      (option: any) => {
        return option.value === selectedOption;
      }
    );
    const dataToSave = {
      question,
      mcqAnswer: selectedOption,
      inputTextAnswers: inputFieldState,
      optionSummary:
        `${McqTypeSummary[0].optionSummaryContent} ${InputTypeSummary}` ||
        selectedOptionSummary,
    };
    dispatch(showNextQuestion(dataToSave));
    setSelectedOption('');
    setSelectedOptionSummary('');
    setInputFieldState(InputFieldInitialState);
  };
  const SubmitAndPreviewAnswers = () => {
    const InputTypeSummary = TextQuestionTypeOption.map((item: any) => {
      return `${inputFieldState[item.value]}${item.optionSummaryContent}`;
    }).join(' ');
    const McqTypeSummary = multipleChoiceQuestionTypeOptions.filter(
      (option: any) => {
        return option.value === selectedOption;
      }
    );
    const dataToSave = {
      question,
      mcqAnswer: selectedOption,
      inputTextAnswers: inputFieldState,
      optionSummary:
        `${McqTypeSummary[0].optionSummaryContent} ${InputTypeSummary}` ||
        selectedOptionSummary,
    };
    dispatch(previewAnswer(dataToSave));
  };
  useEffect(() => {
    if (submitedQuestions[currentquestion - 1]) {
      setSelectedOption(submitedQuestions[currentquestion - 1].mcqAnswer);
      setSelectedOptionSummary(
        submitedQuestions[currentquestion - 1].optionSummary
      );
      setInputFieldState(
        submitedQuestions[currentquestion - 1].inputTextAnswers
      );
    }
  }, [currentquestion]);

  return (
    <div className="question-component mcq-text-type">
      <div className="quiz-item-question-option-wrapper">
        <h4 className="quiz-item-question">{question}</h4>
        <ul className="quiz-item-option-list">
          {multipleChoiceQuestionTypeOptions &&
            multipleChoiceQuestionTypeOptions.map((item: any) => {
              return (
                <li key={item.id}>
                  <button
                    type="button"
                    className={`quiz-item-option-list-item ${
                      selectedOption === item.value ? 'selected' : ''
                    }`}
                    onClick={() => handelOptionSelection(item)}
                  >
                    <div className="quiz-item-option-list-item-label">
                      <span className="quiz-item-option-list-item-icon-tick">
                        {item.value === selectedOption && <TickIcon />}
                      </span>
                      <span>{item.value}</span>
                    </div>
                  </button>
                </li>
              );
            })}
        </ul>
        <ul className="quiz-item-text-option-list">
          {TextQuestionTypeOption &&
            TextQuestionTypeOption.map((item: any) => {
              return (
                <li key={item.id}>
                  <span className="quiz-item-text-option-list-label">
                    {item.value}
                  </span>
                  <div className="quiz-item-text-option-list-item-wrapper">
                    <input
                      type={item.type ?? 'text'}
                      onChange={e => handelInputChange(e, item.value)}
                      value={inputFieldState[item.value]}
                      placeholder={item.placeholder ?? ''}
                    />
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="quiz-item-action-description-wrapper">
        <p className="quiz-item-description">{description}</p>
        {QuestionsListLength === currentquestion ? (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={
              !(
                Object.values(inputFieldState).every(x => x !== '') &&
                selectedOption !== ''
              )
            }
            onClick={() => SubmitAndPreviewAnswers()}
          >
            <span>View My Curated Plan</span>
          </button>
        ) : (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={
              !(
                Object.values(inputFieldState).every(x => x !== '') &&
                selectedOption !== ''
              )
            }
            onClick={() => handelNextQuestion()}
          >
            <span>Next</span>
          </button>
        )}
      </div>
    </div>
  );
};
export { McqAndTextQuestion };
