/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
import React, { FC, useEffect, useRef, useState } from 'react';
import VolumeIcon from 'assets/svg/success-story-video-play-icon.svg';
import { VideoPlayerPropsType } from 'types';
import { VideoModal } from 'components';

const VideoPlayer: FC<VideoPlayerPropsType> = ({
  src,
  showPlayButton = false,
  showBorder = false,
  buttonType,
  buttonSize = '',
  posterImage,
  showControls = false,
  videogallery,
  showModal = false,
  showModalforMobile = false,
}) => {
  const VideoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const VideoPlayRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [play, setPlayed] = useState(!showPlayButton);
  const [isModalOpen, setisModalOpen] = useState(false);
  let medias: any[];
  let PlayButton;
  switch (buttonType) {
    case 'icon':
      PlayButton = <VolumeIcon />;
      break;
    case 'text':
      PlayButton = <div className="videoplayer-action-title">PLAY</div>;
      break;
    default:
      PlayButton = null;
      break;
  }
  const ActivateSound = () => {
    if (VideoRef) {
      VideoRef.current!.muted = false;
    }
  };
  const DeactivateSound = () => {
    if (VideoRef) {
      VideoRef.current!.muted = true;
    }
  };
  const handleModalOpen = () => {
    setisModalOpen(true);
  };
  const [ModalVideoIndex, setModalVideoIndex]: any = useState(null);
  const handleClick = (url: string) => {
    if (showModal) {
      if (window.innerWidth > 768) {
        setModalVideoIndex(videogallery.indexOf(url));
        handleModalOpen();
      } else if (showModalforMobile) {
        setModalVideoIndex(videogallery.indexOf(url));
        handleModalOpen();
      } else {
        setPlayed(!play);
      }
    } else {
      setPlayed(!play);
    }
  };
  const handleVideo = () => {
    medias.forEach(media => {
      if (media === VideoRef.current) VideoRef.current.play();
      else media.pause();
    });
  };
  useEffect(() => {
    if (VideoRef) {
      if (play) {
        VideoRef.current.play();
        if (VideoPlayRef.current) {
          VideoPlayRef.current.style.opacity = '0';
        }
      } else {
        VideoRef.current.pause();
        if (VideoPlayRef.current) {
          VideoPlayRef.current.style.opacity = '1';
        }
      }
    }
  }, [play]);
  useEffect(() => {
    medias = Array.prototype.slice.apply(document.querySelectorAll('video'));
  });

  return (
    <div className={`videoplayer ${showBorder ? 'has-border' : ''}`}>
      <video
        preload="auto"
        loop
        playsInline
        ref={VideoRef}
        poster={posterImage}
        muted
        controls={showControls && play}
        onPlay={handleVideo}
      >
        <source src={src} type="video/mp4" />
      </video>
      <button
        type="button"
        className={`videoplayer-action ${buttonSize}`}
        onClick={() => {
          handleClick(src);
        }}
        ref={VideoPlayRef}
      >
        {PlayButton}
      </button>
      {!showModal && (
        <div className="video-volume-controls">
          <button
            type="button"
            onClick={() => {
              ActivateSound();
            }}
          >
            Activate sound
          </button>{' '}
          |{' '}
          <button
            type="button"
            onClick={() => {
              DeactivateSound();
            }}
          >
            Deactivate sound
          </button>
        </div>
      )}
      <VideoModal
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        gallery={videogallery}
        setModalVideoIndex={setModalVideoIndex}
        ModalVideoIndex={ModalVideoIndex}
      />
    </div>
  );
};

export { VideoPlayer };
