import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { VideoPlayer } from 'components';
import { RenderVideoAndImageProps } from 'types';

export const RenderVideoAndImage: FC<RenderVideoAndImageProps> = ({
  data,
  altText,
  hasPlayButton = false,
  hasBorder = false,
  buttontype,
  buttonsize = '',
  showControls = false,
  videogallery,
  showModal = false,
  showModalforMobile = false,
}) => {
  const videoPoster = data?.image?.localFile?.publicURL;
  return (
    <>
      {(data?.video?.localFile ||
        data?.image?.localFile?.childImageSharp?.gatsbyImageData) &&
        (data?.video?.localFile ? (
          <VideoPlayer
            src={data.video.localFile.publicURL}
            showPlayButton={hasPlayButton}
            showBorder={hasBorder}
            buttonType={buttontype}
            buttonSize={buttonsize}
            posterImage={videoPoster}
            showControls={showControls}
            videogallery={videogallery}
            showModal={showModal}
            showModalforMobile={showModalforMobile}
          />
        ) : (
          <GatsbyImage
            image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
            alt={data?.image?.alternativeText ?? (altText || '')}
          />
        ))}
    </>
  );
};
