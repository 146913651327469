/* eslint-disable react/jsx-props-no-spreading */
// import React, { FC, useState } from 'react';
import React, { FC } from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
// import { DietitianBookingsModal } from 'components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { truncate } from 'utils';

const TrainerQuery = () => {
  return useStaticQuery(graphql`
    query trainer {
      strapiPersonalTrainerPage {
        slug
      }
    }
  `);
};

const TrainerSection: FC = ({ personalTrainer, sectionHeading }: any) => {
  const query = TrainerQuery();
  const trainerPageLink = query.strapiPersonalTrainerPage.slug;
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  if (process.env.GATSBY_SHOW_TRAINERS === 'false') {
    return null;
  }
  return (
    <section className="trainer-section">
      <div className="container">
        <div className="page-heading">
          <div className="page-heading-title title-with-line">
            {sectionHeading?.title ?? ''}
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="page-heading-content">
                {sectionHeading?.tagline && (
                  <div className="page-heading-tagline">
                    {sectionHeading?.tagline}
                  </div>
                )}
                {sectionHeading?.content && (
                  <div className="page-heading-description">
                    <ReactMarkdown className="md-html">
                      {sectionHeading?.content}
                    </ReactMarkdown>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="btn-link-wrapper">
                <Link to={`/services/fitness/${trainerPageLink}`}>
                  <p className="dietitian-link">
                    {sectionHeading?.seeAllText}{' '}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="trainer-section-slider">
        <Slider {...settings}>
          {personalTrainer.map((item: any) => {
            const trainer = item.node ? item.node : item;
            const { id, name, description, image, slug, qualification } =
              trainer;
            const trainerImage: any = image
              ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
              : null;
            const content = truncate(description, 100);

            return (
              <div className="trainer-section-slider-item" key={id}>
                <div className="trainer-section-slider-item-wrapper">
                  <div className="trainer-section-slider-item-image">
                    <Link to={`/services/fitness/${slug}`}>
                      {trainerImage && (
                        <GatsbyImage
                          image={trainerImage}
                          alt={image?.alternativeText ?? (name || '')}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="trainer-section-slider-item-qualification">
                    {qualification}
                  </div>
                  <div className="trainer-section-slider-item-detail">
                    <div className="trainer-section-slider-item-detail-wrapper">
                      <h4 className="trainer-section-slider-item-title">
                        <Link to={`/services/fitness/${slug}`}>{name}</Link>
                      </h4>

                      <div className="trainer-section-slider-item-description">
                        <ReactMarkdown className="md-html">
                          {content}
                        </ReactMarkdown>
                      </div>
                    </div>
                    <Link to={`/services/fitness/${slug}`}>
                      <div className="trainer-section-slider-item-contact">
                        <span> Book Personal Trainer </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export { TrainerSection };
