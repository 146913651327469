/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { toggleNav } from 'Redux/actions';
import { SocialMedia, Menu } from 'components';
import Logo from 'assets/svg/getgoing-logo.svg';

const Header: FC = () => {
  const [scroll, setScroll] = useState(false);
  const dispatch = useDispatch();
  const { isNavOpen } = useSelector((state: any) => {
    return state.NavReducer;
  });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
    document.body.classList.toggle('nav-open', isNavOpen);
  }, [isNavOpen]);
  return (
    <header
      className={classNames('header', {
        active: isNavOpen,
        scrolled: scroll,
      })}
    >
      <div className="container">
        <div className="header-container">
          <span className="logo" data-test="logo">
            <Link to="/" className="logo_icon">
              <Logo />
            </Link>
          </span>
          <div className="header_wrapper">
            <div className="header_wrapper_content">
              <Menu />
              <SocialMedia />
            </div>
            <button
              type="button"
              className="hamburger-menu"
              onClick={() => {
                dispatch(toggleNav());
              }}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export { Header };
