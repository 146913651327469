/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface BannerAnimationProps {
  children: any;
  className?: string;
  durationTime?: number;
  delaySec?: number;
  direction?: string;
}

export const BannerAnimation: FC<BannerAnimationProps> = ({
  children,
  className,
  durationTime,
  direction = 'left',
  delaySec = 1,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const element: any = ref.current;

    const animationConfig = {
      duration: durationTime ?? 2,
      delay: delaySec,
      x: 0,
      y: 0,
      rotateX: 0,
      opacity: 1,
    };
    if (direction === 'right') {
      animationConfig.x = 100;
    } else if (direction === 'left') {
      animationConfig.x = -100;
    } else if (direction === 'top') {
      animationConfig.y = -100;
    } else if (direction === 'bottom') {
      animationConfig.y = 100;
    }
    gsap.from(element, animationConfig);
  }, []);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
