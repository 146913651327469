/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import { truncate } from 'utils';

const DietitianSection: FC = ({
  dietitians,
  meetdietitiancontent,
  dietitianLink,
  handleDietitianModalOpen,
}: any) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="dietitian-section">
      <div className="container">
        <div className="page-heading">
          <div className="page-heading-title title-with-line">
            {meetdietitiancontent?.title ?? ''}
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="page-heading-content">
                {meetdietitiancontent?.tagline && (
                  <div className="page-heading-tagline">
                    {meetdietitiancontent?.tagline}
                  </div>
                )}
                {meetdietitiancontent?.content && (
                  <div className="page-heading-description">
                    <ReactMarkdown className="md-html">
                      {meetdietitiancontent?.content}
                    </ReactMarkdown>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="btn-link-wrapper">
                <Link to="/services/nutrition/dietitian">
                  <p className="dietitian-link">See All Dietitians </p>
                </Link>
                <button
                  className="btn-diet-book"
                  type="button"
                  onClick={handleDietitianModalOpen}
                >
                  <span>{dietitianLink.bookDietitianButtonText}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dietitian-section-slider">
        <Slider {...settings}>
          {dietitians.map((dietitian: any) => {
            const data = dietitian.node ? dietitian.node : dietitian;
            const { id, title, description, image, slug, qualification } = data;
            const dietitianImage: any = image
              ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
              : null;
            const content = truncate(description, 100);

            return (
              <div className="dietitian-section-slider-item" key={id}>
                <div className="dietitian-section-slider-item-wrapper">
                  <div className="dietitian-section-slider-item-image">
                    <Link to={`/services/nutrition/${slug}`}>
                      {dietitianImage && (
                        <GatsbyImage
                          image={dietitianImage}
                          alt={image?.alternativeText ?? (title || '')}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="dietitian-section-slider-item-qualification">
                    {qualification}
                  </div>
                  <div className="dietitian-section-slider-item-detail">
                    <div className="dietitian-section-slider-item-detail-wrapper">
                      <h4 className="dietitian-section-slider-item-title">
                        <Link to={`/services/nutrition/${slug}`}>{title}</Link>
                      </h4>

                      <div className="dietitian-section-slider-item-description">
                        <ReactMarkdown className="md-html">
                          {content}
                        </ReactMarkdown>
                      </div>
                    </div>
                    <Link to={`/services/nutrition/${slug}`}>
                      <div className="dietitian-section-slider-item-contact">
                        <span> Book Consulting Dietitian </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export { DietitianSection };
