import React from 'react';
import { BannerAnimation } from 'components/animations';

const WeightLossBannerNew = ({ banner }: any) => {
  const {
    bannerTagline,
    bannerTitle,
    bannerLeftBottomImage,
    bannerRightBottomImage,
    bannerRightTopImage,
    bannerLeftTopText,
    bannerImage,
  } = banner;

  return (
    <div className="container">
      <div className="weightloss-banner">
        <div className="weightloss-banner-main">
          <div className="weightloss-banner-main-banner">
            <img src={bannerImage.localFile.publicURL} alt={bannerTitle} />
          </div>
          <div className="weightloss-banner-main-details">
            <div className="weightloss-banner-main-details-title">
              <h3>{bannerTitle}</h3>
            </div>
            <div className="weightloss-banner-main-details-tagline">
              {bannerTagline}
            </div>
          </div>
          {bannerLeftTopText && (
            <BannerAnimation
              className="weightloss-banner-content"
              direction="top"
              delaySec={0.5}
            >
              <span>{bannerLeftTopText}</span>
            </BannerAnimation>
          )}

          {bannerRightTopImage?.localFile?.publicURL && (
            <BannerAnimation
              className="weightloss-banner-img-1"
              direction="right"
              delaySec={0.6}
            >
              <img
                src={bannerRightTopImage?.localFile?.publicURL}
                alt={bannerTitle}
              />
            </BannerAnimation>
          )}

          {bannerLeftBottomImage?.localFile?.publicURL && (
            <BannerAnimation
              className="weightloss-banner-img-2"
              direction="left"
              delaySec={0.7}
            >
              <img
                src={bannerLeftBottomImage?.localFile?.publicURL}
                alt={bannerTitle}
              />
            </BannerAnimation>
          )}
          {bannerRightBottomImage?.localFile?.publicURL && (
            <BannerAnimation
              className="weightloss-banner-quote"
              direction="bottom"
              delaySec={0.8}
            >
              <img
                src={bannerRightBottomImage?.localFile?.publicURL}
                alt={bannerTitle}
              />
            </BannerAnimation>
          )}
        </div>
      </div>
    </div>
  );
};

export { WeightLossBannerNew };
