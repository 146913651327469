import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ImageSlide, SlideIn } from 'components/animations';

const MobileHowItWorksItems = ({ item, index }: any) => {
  const { id, title, description, image } = item;
  return (
    <>
      <li className="mobilehowitworks-section" key={id}>
        <div className="mobilehowitworks-img-details-wrapper">
          <div className="mobilehowitworks-img">
            {image?.localFile?.publicURL && (
              <ImageSlide
                className="how-it-works-wrapper-image"
                direction={index === 1 ? 'left' : 'right'}
              >
                <img src={image.localFile.publicURL} alt={title} />
              </ImageSlide>
            )}
          </div>
          <SlideIn
            className="mobilehowitworks-details"
            direction={index === 1 ? 'left' : 'right'}
          >
            <h2 className="mobilehowitworks-number">{index + 1}</h2>
            <p className="mobilehowitworks-title">{title}</p>
            <div className="mobilehowitworks-description">
              <ReactMarkdown className="md-html">{description}</ReactMarkdown>
            </div>
          </SlideIn>
        </div>
      </li>
    </>
  );
};

export { MobileHowItWorksItems };
