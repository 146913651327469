/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { BlogFeedPropType } from 'types';
import ReactMarkdown from 'react-markdown';
import { truncate } from 'utils';

const BlogFeed: FC<BlogFeedPropType> = ({ Blogs, characterLimit, blogUrl }) => {
  const { title, description, content, slug, image, imageLink } = Blogs;
  const blogImage: any = image
    ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
    : null;

  const blurb = description
    ? truncate(description, characterLimit)
    : content && truncate(content, characterLimit);
  return (
    <div className="blog_feed_item">
      {blogImage && (
        <div className="blog_feed_item_image">
          <Link to={imageLink || `/${blogUrl}/${slug}`}>
            <GatsbyImage
              image={blogImage}
              alt={image?.alternativeText ?? (title || '')}
            />
          </Link>
        </div>
      )}
      <div className="blog_feed_item_content">
        <div className="blog_feed_summary">
          <h3 className="blog_feed_title">{title}</h3>
          {blurb && (
            <ReactMarkdown className="blog_feed_description md-html">
              {blurb}
            </ReactMarkdown>
          )}
        </div>
        <Link
          className={`blog_feed${imageLink ? '_read' : '_link'}`}
          to={`/${blogUrl}/${slug}`}
        >
          Read full article &gt;
        </Link>
      </div>
    </div>
  );
};

export { BlogFeed };
