import React from 'react';
import { DesktopHowItWorks, MobileViewHowItWorks } from 'components';

const HowItWorksSection = ({ howItWorks, ctaButtonText, scrollPlan }: any) => {
  return (
    <>
      <MobileViewHowItWorks
        MobileHowItWorks={howItWorks}
        ctaButtonText={ctaButtonText}
        scrollPlan={scrollPlan}
      />
      <DesktopHowItWorks
        howItWorks={howItWorks}
        ctaButtonText={ctaButtonText}
        scrollPlan={scrollPlan}
      />
    </>
  );
};

export { HowItWorksSection };
