/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

interface ProgressIndicatorPropsType {
  completedQuestions: any;
  activeQuestion: any;
  SortedQuestions: any;
}

export const ProgressIndicator: FC<ProgressIndicatorPropsType> = ({
  completedQuestions,
  activeQuestion,
  SortedQuestions,
}) => {
  let initialAngle = 0;
  const angleOfRotation = 360 / SortedQuestions.length;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
      <defs>
        <g id="indicator">
          <circle
            cx="0"
            cy="0"
            r="6"
            strokeWidth="2"
            fill="white"
            stroke="#69DC7D"
          />
        </g>
        <g id="indicator-done">
          <circle cx="0" cy="0" r="10" stroke="none" fill="#38D6B1" />
        </g>
        <g id="indicator-active">
          <circle
            className="indicator-active-outer"
            cx="0"
            cy="0"
            r="10"
            stroke="none"
            fill="#38D6B1"
          />
          <circle
            className="indicator-active-inner"
            cx="0"
            cy="0"
            r="6"
            strokeWidth="2"
            fill="white"
            stroke="#69DC7D"
          />
        </g>
      </defs>
      <circle
        className="indicator-circle"
        cx="60"
        cy="60"
        r="50"
        fill="white"
        stroke="#69DC7D"
        strokeWidth="2"
      />
      <g transform="translate(60,10)">
        {SortedQuestions.length &&
          SortedQuestions.map((_element: any, index: number) => {
            if (index !== 0) initialAngle += angleOfRotation;
            return (
              <use
                href={
                  activeQuestion === index + 1
                    ? '#indicator-active'
                    : completedQuestions &&
                      activeQuestion !== index + 1 &&
                      completedQuestions.length >= index + 1
                    ? '#indicator-done'
                    : '#indicator'
                }
                transform={`rotate(${initialAngle})`}
                // eslint-disable-next-line react/no-unknown-property
                transform-origin="0 50"
              />
            );
          })}
      </g>
    </svg>
  );
};
