import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import BgImg from 'assets/images/Bg_gradient_paler.png';

export const ContentSideImageSection = ({ data }: any) => {
  return (
    <section className="content-side-image-list">
      {data.map((contentImage: any) => {
        const Image =
          contentImage.image && contentImage.image.localFile
            ? getImage(
                contentImage.image.localFile.childImageSharp?.gatsbyImageData
              )
            : null;
        return (
          <div
            className={`content-side-image-item ${
              contentImage.hasBg ? 'has-bg' : ''
            }`}
            style={{
              backgroundImage: contentImage.hasBg ? `url(${BgImg})` : '',
            }}
          >
            <div className="container">
              <div className="content-side-image-item-wrapper">
                <div className="content-side-image-item-details">
                  <h6 className="title-with-line content-side-image-item-title">
                    {contentImage.tagline}
                  </h6>
                  <div className="content-side-image-item-image mobile">
                    {' '}
                    {Image && <GatsbyImage image={Image} alt="image" />}
                  </div>
                  <div className="content-side-image-item-content-wrapper">
                    <ReactMarkdown className="md-html content-side-image-item-heading">
                      {contentImage.title}
                    </ReactMarkdown>
                    <p className="content-side-image-item-desc">
                      <ReactMarkdown className="md-html">
                        {contentImage.description}
                      </ReactMarkdown>
                    </p>
                  </div>
                </div>
                <div className="content-side-image-item-image desktop">
                  {' '}
                  {Image && <GatsbyImage image={Image} alt="image" />}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};
