import React, { FC } from 'react';
import ReactModal from 'react-modal';

interface DietitianBookingsModalType {
  DietitianModalOpen: boolean;
  setDietitianModalOpen: any;
  link: string;
}

const DietitianBookingsModal: FC<DietitianBookingsModalType> = ({
  DietitianModalOpen,
  setDietitianModalOpen,
  link,
}) => {
  const handleDietitianModalClose = () => {
    setDietitianModalOpen(false);
  };
  return (
    <ReactModal
      closeTimeoutMS={100}
      isOpen={DietitianModalOpen}
      onRequestClose={handleDietitianModalClose}
      ariaHideApp={false}
      portalClassName="ReactModalPortal dietitian_modal"
    >
      <div className="dietitianmodal_bookings">
        <button
          type="button"
          onClick={() => handleDietitianModalClose()}
          className="modal-close"
        >
          <span>&#x2715;</span>
        </button>
        <iframe
          id="cliniko-44744185"
          title="dietitian"
          src={`${link}&embedded=true`}
          frameBorder="0"
          scrolling="auto"
          width="100%"
          height="1000"
          style={{ pointerEvents: 'auto' }}
        />
      </div>
    </ReactModal>
  );
};

export { DietitianBookingsModal };
