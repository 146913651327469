import React, { FC, useState, useEffect } from 'react';
import { QuizQuestion } from 'types';
import { useDispatch } from 'react-redux';

interface TextareaQuestionType {
  Question: QuizQuestion;
  showNextQuestion: any;
  submitedQuestions: any;
  currentquestion: number;
  QuestionsListLength?: number;
  previewAnswer: any;
}

const TextareaQuestions: FC<TextareaQuestionType> = ({
  Question,
  showNextQuestion,
  submitedQuestions,
  currentquestion,
  QuestionsListLength,
  previewAnswer,
}) => {
  const dispatch = useDispatch();
  const { question, TextAreaQuestionTypeOption } = Question.node;

  const [inputFieldState, setInputFieldState] = useState('');
  const [textareaValidate, istextareaValidate] = useState(false);

  const handelInputChange = (e: any) => {
    setInputFieldState(e.target.value);
    if (inputFieldState.length < 10) {
      istextareaValidate(true);
    } else {
      istextareaValidate(false);
    }
  };
  const handelNextQuestion = () => {
    const dataToSave = {
      question,
      answer: inputFieldState,
    };
    dispatch(showNextQuestion(dataToSave));
    setInputFieldState('');
  };
  const SubmitAndPreviewAnswers = () => {
    const dataToSave = {
      question,
      answer: inputFieldState,
    };
    dispatch(previewAnswer(dataToSave));
  };
  useEffect(() => {
    if (submitedQuestions[currentquestion - 1]) {
      setInputFieldState(submitedQuestions[currentquestion - 1].answer);
    }
  }, [currentquestion]);

  return (
    <div className="question-component textarea-question">
      <div className="quiz-item-question-option-wrapper">
        <h4 className="quiz-item-question">{question}</h4>
        {TextAreaQuestionTypeOption && (
          <>
            <div className="quiz-item-text-option-list-item-wrapper">
              <textarea
                onChange={e => handelInputChange(e)}
                placeholder={TextAreaQuestionTypeOption.placeholder ?? ''}
                value={inputFieldState}
                rows={8}
                cols={20}
              />
            </div>
            {textareaValidate && (
              <div className="error-messsage">
                Text must be atleast 10 characters
              </div>
            )}
          </>
        )}
      </div>
      <div className="quiz-item-action-description-wrapper">
        {QuestionsListLength === currentquestion ? (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={inputFieldState.length < 10}
            onClick={() => SubmitAndPreviewAnswers()}
          >
            <span>Next</span>
          </button>
        ) : (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={inputFieldState.length < 10}
            onClick={() => handelNextQuestion()}
          >
            <span>Next</span>
          </button>
        )}
      </div>
    </div>
  );
};
export { TextareaQuestions };
