import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FeaturedJobPerks } from 'components';
import { CareerContentComponentPropsType, CareerContentItemType } from 'types';
import { SlideUpContent } from 'components/animations';

export const CareerContent = ({
  data,
  featuredJobPerks,
}: CareerContentComponentPropsType) => {
  return (
    <div className="career-page-content-wrapper">
      {data?.length &&
        data.map((item: CareerContentItemType, index: number) => {
          const Image = item?.image
            ? getImage(item.image?.localFile?.childImageSharp?.gatsbyImageData)
            : null;
          return (
            <div key={item.id} className="career-page-content-item">
              <SlideUpContent delaySec={0.5}>
                <div className="career-page-content-item-wrapper">
                  <div className="career-page-content-detail">
                    {item?.title && (
                      <h6 className="career-page-content-title">
                        {item.title}
                      </h6>
                    )}
                    {item?.description && (
                      <ReactMarkdown className="career-page-content-description md-html">
                        {item.description}
                      </ReactMarkdown>
                    )}
                  </div>
                  {Image && (
                    <div className="career-page-content-image">
                      <GatsbyImage
                        image={Image}
                        alt={item.image?.alternativeText ?? (item.title || '')}
                      />
                    </div>
                  )}
                </div>
              </SlideUpContent>
              {index === 0 && <FeaturedJobPerks data={featuredJobPerks} />}
            </div>
          );
        })}
    </div>
  );
};
