/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useEffect, useReducer } from 'react';
import { QuizQuestion } from 'types';
import { useDispatch } from 'react-redux';
import TickIcon from 'assets/svg/TickWithBlueBgIcon.svg';

interface LikertScaleQuestionPropTypes {
  Question: QuizQuestion;
  showNextQuestion: any;
  submitedQuestions: any;
  currentquestion: number;
  QuestionsListLength: number;
  previewAnswer: any;
}

export const LikertScaleQuestion: FC<LikertScaleQuestionPropTypes> = ({
  Question,
  showNextQuestion,
  submitedQuestions,
  currentquestion,
  QuestionsListLength,
  previewAnswer,
}) => {
  const dispatch = useDispatch();
  const { question, description, likertScaleQuestionOptions } = Question.node;
  const initialState: any = {
    completedOptions: [],
    ActiveOption: 0,
    OptionToDisplay: 1,
  };

  const LikertScalereducer = (state: any, action: any) => {
    switch (action.type) {
      case 'UPDATE_SELECTED_OPTION': {
        const ActiveOption =
          // eslint-disable-next-line no-nested-ternary
          state.completedOptions.length <= likertScaleQuestionOptions.length
            ? state.completedOptions[action.payload.optionIndex]
              ? state.completedOptions.length
              : action.payload.optionIndex + 1
            : null;
        const newCompletedOptions = [...state.completedOptions];
        newCompletedOptions[action.payload.optionIndex] = action.payload;
        const OptionToDisplay =
          state.completedOptions.length <= likertScaleQuestionOptions.length
            ? state.OptionToDisplay + 1
            : state.OptionToDisplay;
        return {
          ...state,
          completedOptions: [...newCompletedOptions],
          ActiveOption,
          OptionToDisplay,
        };
      }
      case 'UPDATE_OPTIONS_FROM_REDUX':
        return {
          ...state,
          completedOptions: action.payload,
          ActiveOption: null,
          OptionToDisplay: action.payload.length,
        };
      case 'UPDATE_ACTIVE_OPTION': {
        const OptionToDisplay =
          state.completedOptions.length <= likertScaleQuestionOptions.length
            ? state.completedOptions.length
            : state.OptionToDisplay;
        return {
          ...state,
          ActiveOption: action.payload,
          OptionToDisplay,
        };
      }
      default:
        return state;
    }
  };
  const [selectedOptions, handelSelectedOptions] = useReducer(
    LikertScalereducer,
    initialState
  );
  const handelNextQuestion = () => {
    const Summary = selectedOptions.completedOptions
      .map((item: any) => {
        return item.optionSummary;
      })
      .join();
    const dataToStore = {
      question,
      answer: selectedOptions.completedOptions,
      optionSummary: Summary,
    };
    dispatch(showNextQuestion(dataToStore));
  };
  const SubmitAndPreviewAnswers = () => {
    const dataToStore = {
      question,
      answer: selectedOptions.completedOptions,
    };
    dispatch(previewAnswer(dataToStore));
  };
  const handelScaleSelection = (
    optionIndex: number,
    scaleIndex: number,
    selectedScale: string,
    optionSummaryContent: string
  ) => {
    const option = {
      optionIndex,
      scale: scaleIndex,
      scaleSummary: selectedScale,
      optionSummary: optionSummaryContent,
    };
    handelSelectedOptions({ type: 'UPDATE_SELECTED_OPTION', payload: option });
  };
  useEffect(() => {
    if (submitedQuestions[currentquestion - 1]) {
      handelSelectedOptions({
        type: 'UPDATE_OPTIONS_FROM_REDUX',
        payload: submitedQuestions[currentquestion - 1].answer,
      });
    }
  }, [currentquestion]);
  return (
    <div className="question-component likert-scale-type">
      <div className="quiz-item-question-option-wrapper">
        <h4 className="quiz-item-question">{question}</h4>
        <ul className="quiz-item-option-list">
          {likertScaleQuestionOptions &&
            likertScaleQuestionOptions
              .slice(0, selectedOptions.OptionToDisplay)
              .map((optionToShow: any, index: number) => {
                return (
                  <li
                    className={`quiz-item-option-list-item ${
                      selectedOptions.ActiveOption === index ? 'active' : ''
                    }`}
                    key={optionToShow.id}
                  >
                    {selectedOptions.completedOptions[index] && (
                      <button
                        type="button"
                        className="quiz-item-option-list-item-label"
                        onClick={() => {
                          handelSelectedOptions({
                            type: 'UPDATE_ACTIVE_OPTION',
                            payload: index,
                          });
                        }}
                      >
                        <span className="quiz-item-option-list-item-icon-tick">
                          <TickIcon />
                        </span>
                        <span>
                          {selectedOptions.completedOptions[index].scaleSummary}
                        </span>
                      </button>
                    )}

                    <div className="quiz-item-option-list-item-wrapper">
                      <div className="quiz-item-option-list-item-detail">
                        <h6>{optionToShow.optiontitle}</h6>
                        <p>{optionToShow.description}</p>
                      </div>
                      <div className="quiz-item-option-list-item-scale-wrapper">
                        <span className="quiz-item-option-list-item-scale-min">
                          {optionToShow.scaleMinLabel}
                        </span>
                        <ul className="quiz-item-option-list-item-scale-list">
                          {optionToShow.likertScaleOptions.map(
                            (likertScaleOption: any, i: number) => {
                              return (
                                <li
                                  className={`quiz-item-option-list-item-scale-list-item likert-scale-list-item-${
                                    i + 1
                                  } ${
                                    selectedOptions.completedOptions[index] &&
                                    selectedOptions.completedOptions[index]
                                      .scale === i &&
                                    'selected-scale'
                                  }`}
                                  key={likertScaleOption.id}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handelScaleSelection(
                                        index,
                                        i,
                                        likertScaleOption.summary,
                                        likertScaleOption.optionSummaryContent
                                      );
                                    }}
                                  >
                                    <span> </span>
                                  </button>
                                </li>
                              );
                            }
                          )}
                        </ul>
                        <span className="quiz-item-option-list-item-scale-max">
                          {optionToShow.scaleMaxLabel}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>
      <div className="quiz-item-action-description-wrapper">
        <p className="quiz-item-description">{description}</p>
        {QuestionsListLength === currentquestion ? (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={
              selectedOptions.completedOptions.length !==
              likertScaleQuestionOptions.length
            }
            onClick={() => SubmitAndPreviewAnswers()}
          >
            <span>View My Curated Plan</span>
          </button>
        ) : (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={
              selectedOptions.completedOptions.length !==
              likertScaleQuestionOptions.length
            }
            onClick={() => handelNextQuestion()}
          >
            <span>Next</span>
          </button>
        )}
      </div>
    </div>
  );
};
