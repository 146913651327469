import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

interface QuestionnaireSummaryPropsType {
  updateQuizSummaryReviewedStatus: any;
  submitedQuestions: any;
  // resetQuiz: any;
}

const QuestionnaireSummary: FC<QuestionnaireSummaryPropsType> = ({
  updateQuizSummaryReviewedStatus,
  submitedQuestions,
  // resetQuiz,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="quiz-summary-section">
      <div className="quiz-summary">
        <div className="quiz-summary-wrapper">
          <h3>
            <span>So here’s your tailored plan</span>
          </h3>
          <ul className="quiz-summary-list">
            {submitedQuestions &&
              submitedQuestions.map((item: any, i: number) => {
                return (
                  <li
                    className="quiz-summary-list-item"
                    key={item.optionSummary}
                  >
                    <div className="count">
                      <span>{i + 1}</span>
                    </div>
                    <p className="quiz-summary-content">
                      {' '}
                      {item.optionSummary}
                    </p>
                  </li>
                );
              })}
          </ul>
          <div className="quiz-summary-submit-btn">
            <button
              type="button"
              onClick={() => {
                dispatch(updateQuizSummaryReviewedStatus());
              }}
            >
              <span>Submit and Getgoing</span>
            </button>
          </div>
        </div>
        {/* <button type="button" onClick={() => dispatch(resetQuiz())}>
          start over
        </button> */}
      </div>
      <button type="button" className="quiz-summary-start-over-btn">
        <span>Start Over</span>
      </button>
    </div>
  );
};

export { QuestionnaireSummary };
