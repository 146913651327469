import React, { FC, useEffect } from 'react';
import { Seo, Reviews, Brands, Banner } from 'components';
import { useLocation } from '@reach/router';
import { BrandQuery } from 'queries/brand';

export interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  bannerTitle?: string;
  bannerTagline?: string;
  bannerImage?: any;
  bannerVideo?: any;
  bannerButton?: string;
  bannerButtonCtaHandler?: () => void;
  bannerPlaceholder?: string;
  centerAlign?: boolean;
  metaTitle?: string;
  hasContainerClass?: boolean;
  className?: string;
  delaySec?: number;
  description?: string;
  image?: string;
  dealTitle?: string;
  type?: string;
  productImage?: boolean;
  showBrand?: boolean;
  showReviews?: boolean;
  product?: {
    id?: string;
    price?: {
      currency?: string;
      amount?: string;
    };
    condition?: string;
    availability?: string;
  };
  showNdisFields?: boolean;
  showBgImage?: boolean;
  pageClassName?: string;
  faqs?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  bannerTitle,
  bannerTagline,
  bannerImage,
  bannerButton,
  bannerPlaceholder,
  centerAlign = false,
  title,
  delaySec,
  metaTitle,
  dealTitle,
  hasContainerClass,
  className,
  description,
  image,
  type,
  productImage,
  bannerVideo = null,
  showReviews = true,
  product,
  showBrand = true,
  bannerButtonCtaHandler,
  showNdisFields = false,
  showBgImage = false,
  pageClassName = '',
  faqs,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  const brandquery = BrandQuery();
  const { brandsWorked } = brandquery.Brands;
  return (
    <>
      <Seo
        title={metaTitle ?? title}
        description={description}
        image={image}
        type={type}
        productImage={productImage}
        product={product}
        pageClassName={pageClassName}
        faq={faqs}
      />
      <div className={`${title} ${className}`}>
        <Banner
          centerAlign={centerAlign}
          title={bannerTitle}
          tagline={bannerTagline}
          image={bannerImage}
          video={bannerVideo}
          ctaButton={bannerButton}
          postcodePlaceholder={bannerPlaceholder}
          dealTitle={dealTitle || `${title} Page form`}
          contactFormModalTitle={
            brandquery.strapiContactUsPage.locationsSection
              .contactFormModalTitle
          }
          bannerButtonCtaHandler={bannerButtonCtaHandler}
          showNdisFields={showNdisFields}
          showBgImage={showBgImage}
        />
        <div
          className={
            hasContainerClass ? 'container page-content' : 'page-content'
          }
        >
          {children}
        </div>
        {showBrand && brandsWorked.showBrand && (
          <Brands brandsWorked={brandsWorked} />
        )}
        {showReviews && <Reviews delaySec={delaySec} />}
      </div>
    </>
  );
};
