import { FlipCard } from 'components';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import StarIcon from 'assets/svg/stargreen.svg';
import WLGuarantee from 'assets/svg/WLGuarantee.svg';
import PayLater from 'assets/svg/WLPayLater.svg';
import WLNutrition from 'assets/svg/WLNutrition.svg';
import WlResultDriven from 'assets/svg/WLResultsDriven.svg';
import WLReview from 'assets/svg/WL-Reviews-Trusted.svg';
import WlTrainer from 'assets/svg/WLTrainers.svg';
import NeverDiet from 'assets/svg/NeverDiet.svg';
import WLSupport from 'assets/svg/WLSupport.svg';
import { WeightlossStepsAnimation } from 'components/animations';
import { truncate } from 'utils';

const WeightLossBenefits = ({ wlBenefits }: any) => {
  return (
    <>
      <WeightlossStepsAnimation className="wl-benefits">
        {wlBenefits.map((item: any, index: number) => {
          const { title, description, image, icon } = item;
          const Image: any = image
            ? getImage(image.localFile.childImageSharp.gatsbyImageData)
            : null;
          const desc = truncate(description, 150);
          let Icon;
          switch (icon) {
            case 'resultDriven':
              Icon = <WlResultDriven />;
              break;
            case 'flexiblePay':
              Icon = <PayLater />;
              break;
            case 'fullSatisfication':
              Icon = <WLGuarantee />;
              break;
            case 'betterNutrition':
              Icon = <WLNutrition />;
              break;
            case 'handPickedCoaches':
              Icon = <WlTrainer />;
              break;
            case 'trustedByThousand':
              Icon = <WLReview />;
              break;
            case 'supportMotivation':
              Icon = <WLSupport />;
              break;
            case 'neverDietAgain':
              Icon = <NeverDiet />;
              break;
            default:
              Icon = <StarIcon />;
              break;
          }
          const Card1 = (
            <>
              {image && <GatsbyImage image={Image} alt={title} />}
              <div className="wl-benefits-content">
                <div className="wl-benefits-content-listicon">
                  <div className="wl-benefits-icon">{Icon}</div>
                </div>
                <div className="wl-benefits-content-title">
                  <span>{title}</span>
                </div>
              </div>
            </>
          );
          const Card2 = (
            <div className="wl-benefits-details">
              <div className="wl-benefits-title">{title}</div>
              <div className="wl-benefits-description"> {desc}</div>
            </div>
          );
          return (
            <div className="wl-benefits-list" key={title}>
              <FlipCard
                card1={Card1}
                card2={Card2}
                card1Class="wl-benefits-card"
                card2Class="wl-benefits-card"
                index={index + 1}
              />
            </div>
          );
        })}
      </WeightlossStepsAnimation>
    </>
  );
};

export { WeightLossBenefits };
