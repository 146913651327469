import React from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerIcon from 'assets/images/Marker.png';

const mapStyle = () => {
  return {
    styles: [
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
  };
};

// Todo get Locations  from cms
const mapProp = {
  defaultCenter: { lat: -33.082541005654775, lng: 146.06679798628832 },
  defaultZoom: 5,
  styles: mapStyle,
  locations: [
    {
      locationName: 'Melbourne',
      address: 'Melbourne',
      position: {
        lat: -37.815765656796,
        lng: 144.94904347534447,
      },
    },
    {
      locationName: 'Sydney',
      address: 'Sydney',
      position: {
        lat: -33.80979535002084,
        lng: 151.23896258102542,
      },
    },
    {
      locationName: 'Brisbane',
      address: 'Brisbane',
      position: {
        lat: -27.464014773263674,
        lng: 153.0510984972271,
      },
    },
  ],
};

const ContactMap = ({
  defaultCenter = mapProp.defaultCenter,
  locations = mapProp.locations,
  defaultZoom = mapProp.defaultZoom,
}: any) => {
  const renderMarkers = (map: any, maps: any) => {
    locations.map(
      (location: any) =>
        new maps.Marker({
          position: {
            lat: location.position.lat,
            lng: location.position.lng,
          },
          map,
          icon: MarkerIcon,
          title: location.address,
        })
    );
  };
  return (
    <div style={{ height: '450px', width: '100%' }}>
      <GoogleMapReact
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        options={mapProp.styles}
        yesIWantToUseGoogleMapApiInternals
        resetBoundsOnResize
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        bootstrapURLKeys={{ key: 'AIzaSyBblmNFCpFnuQDi8EIq9rTaKU7LyogqYMk' }}
      />
    </div>
  );
};

export { ContactMap };
