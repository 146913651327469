import {
  SHOW_HOME_PAGE_QUIZ_NEXT_QUESTION,
  SHOW_HOME_PAGE_QUIZ_PREV_QUESTION,
  SHOW_CAREER_PAGE_QUIZ_NEXT_QUESTION,
  SHOW_CAREER_PAGE_QUIZ_PREV_QUESTION,
  SHOW_NUTRITION_PAGE_QUIZ_NEXT_QUESTION,
  SHOW_NUTRITION_PAGE_QUIZ_PREV_QUESTION,
  HOME_PAGE_QUIZ_MODAL_OPEN,
  HOME_PAGE_QUIZ_MODAL_CLOSE,
  NUTRITION_PAGE_QUIZ_MODAL_OPEN,
  NUTRITION_PAGE_QUIZ_MODAL_CLOSE,
  SUBMIT_HOME_PAGE_QUIZ_AND_PREVIEW_ANSWERS,
  SUBMIT_NUTRITION_PAGE_QUIZ_AND_PREVIEW_ANSWERS,
  UPDATE_HOME_PAGE_QUIZ_PREVIEW_STATUS,
  UPDATE_NUTRITION_PAGE_QUIZ_PREVIEW_STATUS,
  UPDATE_NUTRITION_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS,
  UPDATE_HOME_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS,
  UPDATE_CAREER_PAGE_QUIZ_FORM,
  SUBMIT_CAREER_PAGE_QUIZ,
  RESET_HOME_PAGE_QUIZ,
  RESET_NUTRITION_PAGE_QUIZ,
  RESET_CAREER_PAGE_QUIZ,
} from 'Redux/actionTypes';

type quizModalStateType = {
  isHomePageQuizModalOpen: boolean;
  isNutritionPageQuizModalOpen: boolean;
};

type QuizInitialStateType = {
  currentQuestion: number;
  submitedQuestions: {}[];
  showQuizSummary?: boolean;
  isQuizSummaryReviewed?: boolean;
  showcareerform?: boolean;
};

const QuizModalInitialState: quizModalStateType = {
  isHomePageQuizModalOpen: false,
  isNutritionPageQuizModalOpen: false,
};

const HomePageQuizInitialState: QuizInitialStateType = {
  currentQuestion: 1,
  submitedQuestions: [],
  showQuizSummary: false,
  isQuizSummaryReviewed: false,
};

const NutritionPageQuizInitialState: QuizInitialStateType = {
  currentQuestion: 1,
  submitedQuestions: [],
  showQuizSummary: false,
  isQuizSummaryReviewed: false,
};

const CareerPageQuizInitialState: QuizInitialStateType = {
  currentQuestion: 1,
  submitedQuestions: [],
  showcareerform: false,
};

const QuizModalReducer: any = (state = QuizModalInitialState, action: any) => {
  switch (action.type) {
    case HOME_PAGE_QUIZ_MODAL_OPEN:
      return {
        ...state,
        isHomePageQuizModalOpen: !state.isHomePageQuizModalOpen,
      };
    case HOME_PAGE_QUIZ_MODAL_CLOSE:
      return {
        ...state,
        isHomePageQuizModalOpen: !state.isHomePageQuizModalOpen,
      };
    case NUTRITION_PAGE_QUIZ_MODAL_OPEN:
      return {
        ...state,
        isNutritionPageQuizModalOpen: !state.isNutritionPageQuizModalOpen,
      };
    case NUTRITION_PAGE_QUIZ_MODAL_CLOSE:
      return {
        ...state,
        isNutritionPageQuizModalOpen: !state.isNutritionPageQuizModalOpen,
      };
    default:
      return state;
  }
};

const HomePageQuizReducer: any = (
  state = HomePageQuizInitialState,
  action: any
) => {
  switch (action.type) {
    case SHOW_HOME_PAGE_QUIZ_NEXT_QUESTION: {
      const newArray = [...state.submitedQuestions];
      newArray[state.currentQuestion - 1] = action.payload;
      return {
        ...state,
        submitedQuestions: newArray,
        currentQuestion: state.currentQuestion + 1,
      };
    }
    case SHOW_HOME_PAGE_QUIZ_PREV_QUESTION:
      return { ...state, currentQuestion: state.currentQuestion - 1 };
    case SUBMIT_HOME_PAGE_QUIZ_AND_PREVIEW_ANSWERS: {
      const newArray = [...state.submitedQuestions];
      newArray[state.currentQuestion - 1] = action.payload;
      return {
        ...state,
        submitedQuestions: newArray,
        showQuizSummary: true,
      };
    }
    case UPDATE_HOME_PAGE_QUIZ_PREVIEW_STATUS:
      return { ...state, showQuizSummary: false };
    case UPDATE_HOME_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS:
      return {
        ...state,
        isQuizSummaryReviewed: !state.isQuizSummaryReviewed,
        showQuizSummary: !state.showQuizSummary,
      };
    case RESET_HOME_PAGE_QUIZ:
      return {
        currentQuestion: 1,
        submitedQuestions: [],
        showQuizSummary: false,
        isQuizSummaryReviewed: false,
      };
    default:
      return state;
  }
};

const NutritionPageQuizReducer: any = (
  state = NutritionPageQuizInitialState,
  action: any
) => {
  switch (action.type) {
    case SHOW_NUTRITION_PAGE_QUIZ_NEXT_QUESTION: {
      const newArray = [...state.submitedQuestions];
      newArray[state.currentQuestion - 1] = action.payload;
      return {
        ...state,
        submitedQuestions: newArray,
        currentQuestion: state.currentQuestion + 1,
      };
    }
    case SHOW_NUTRITION_PAGE_QUIZ_PREV_QUESTION:
      return { ...state, currentQuestion: state.currentQuestion - 1 };
    case SUBMIT_NUTRITION_PAGE_QUIZ_AND_PREVIEW_ANSWERS: {
      const newArray = [...state.submitedQuestions];
      newArray[state.currentQuestion - 1] = action.payload;
      return {
        ...state,
        submitedQuestions: newArray,
        showQuizSummary: true,
      };
    }
    case UPDATE_NUTRITION_PAGE_QUIZ_PREVIEW_STATUS:
      return { ...state, showQuizSummary: false };
    case UPDATE_NUTRITION_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS:
      return {
        ...state,
        isQuizSummaryReviewed: !state.isQuizSummaryReviewed,
        showQuizSummary: !state.showQuizSummary,
      };
    case RESET_NUTRITION_PAGE_QUIZ:
      return {
        currentQuestion: 1,
        submitedQuestions: [],
        showQuizSummary: false,
        isQuizSummaryReviewed: false,
      };
    default:
      return state;
  }
};

const CareerPageQuizReducer: any = (
  state = CareerPageQuizInitialState,
  action: any
) => {
  switch (action.type) {
    case SHOW_CAREER_PAGE_QUIZ_NEXT_QUESTION: {
      const newArray = [...state.submitedQuestions];
      newArray[state.currentQuestion - 1] = action.payload;
      return {
        ...state,
        submitedQuestions: newArray,
        currentQuestion: state.currentQuestion + 1,
      };
    }
    case SHOW_CAREER_PAGE_QUIZ_PREV_QUESTION:
      return { ...state, currentQuestion: state.currentQuestion - 1 };
    case SUBMIT_CAREER_PAGE_QUIZ: {
      const newArray = [...state.submitedQuestions];
      newArray[state.currentQuestion - 1] = action.payload;
      return {
        ...state,
        submitedQuestions: newArray,
        showcareerform: true,
      };
    }
    case UPDATE_CAREER_PAGE_QUIZ_FORM: {
      return {
        ...state,
        showcareerform: !state.showcareerform,
      };
    }
    case RESET_CAREER_PAGE_QUIZ:
      return {
        currentQuestion: 1,
        submitedQuestions: [],
      };
    default:
      return state;
  }
};

export {
  HomePageQuizReducer,
  NutritionPageQuizReducer,
  QuizModalReducer,
  CareerPageQuizReducer,
};
