/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { PageHeadingPropsType } from 'types';
import ReactMarkdown from 'react-markdown';
import { SlideUpTitle, SlideUpContent } from 'components/animations';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

const PageHeading: FC<PageHeadingPropsType> = ({
  title,
  subtitle,
  description,
  delaySec = 0,
  level,
  durationTime,
  children,
}) => {
  if (
    (!title || (typeof title === 'string' && title.trim() === '')) &&
    (!subtitle || subtitle.trim() === '') &&
    (!description || description.trim() === '')
  ) {
    return null;
  }
  const HTag = level ? (`h${level}` as HeadingTag) : 'h2';
  const HNextTag = level ? (`h${level + 1}` as HeadingTag) : 'h3';

  return (
    <div className="page-heading">
      <SlideUpTitle durationTime={durationTime || 1} delaySec={delaySec}>
        <HTag className="page-heading-title title-with-line">
          <ReactMarkdown>{title}</ReactMarkdown>
        </HTag>
      </SlideUpTitle>
      <div className="row">
        <div className="col-lg-8">
          <div className="page-heading-content">
            {subtitle && (
              <SlideUpTitle
                durationTime={durationTime || 1.1}
                delaySec={delaySec}
              >
                <HNextTag className="page-heading-tagline">{subtitle}</HNextTag>
              </SlideUpTitle>
            )}
            {description && (
              <SlideUpContent
                className="page-heading-description"
                delaySec={delaySec}
              >
                <ReactMarkdown className="md-html">{description}</ReactMarkdown>
              </SlideUpContent>
            )}
          </div>
        </div>
        <div className="col-lg-4">{children}</div>
      </div>
    </div>
  );
};
export { PageHeading };
