/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideUpContentProps {
  children: any;
  className?: string;
  delaySec?: number;
}

export const SlideUpContent: FC<SlideUpContentProps> = ({
  children,
  className,
  delaySec = 0,
}) => {
  const showAnimations =
    process.env.GATSBY_SHOW_ANIMATIONS === undefined ||
    (process.env.GATSBY_SHOW_ANIMATIONS &&
      process.env.GATSBY_SHOW_ANIMATIONS === 'true');
  const ref = useRef(null);

  useEffect(() => {
    const ParagraphAnimationPosition =
      window.innerWidth < 576
        ? 'top-=0 bottom-=10'
        : window.innerWidth < 990
        ? 'top-=10 bottom-=160'
        : 'top-=180 bottom-=220';
    const delayTime = window.innerWidth < 1400 ? 0 : delaySec;
    const element: any = ref.current;
    if (showAnimations && element != null) {
      const lines = element.querySelectorAll('p,li,h1,h2,h3,h4,h5,h6,div');

      for (let i = 0; i < lines.length; i += 1) {
        gsap.fromTo(
          lines[i],
          { autoAlpha: 0, y: 100 },
          {
            duration: 0.9,
            autoAlpha: 1,
            y: 0,
            delay: delayTime,
            scrollTrigger: {
              trigger: lines[i],
              start: ParagraphAnimationPosition,
              toggleActions: 'play none none reverse',
            },
          }
        );
      }
    }
  }, [showAnimations]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
