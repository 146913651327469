/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

interface RecipeSliderProps {
  relatedRecipes: {
    node: {
      id: string;
      title: string;
      duration: number;
      slug: string;
      image: {
        localFile: {
          childImageSharp: {
            gatsbyImageData: {
              layout: string;
              backgroundColor: string;
              height: number;
              width: number;
              images: {
                fallback: {
                  sizes: string;
                  src: string;
                  srcSet: string;
                };
                sources: {
                  sizes: string;
                  srcSet: string;
                  type: string;
                }[];
              };
            };
          };
        };
      };
    };
  }[];
  recipesUrl: string;
}

const RecipeSlider: FC<RecipeSliderProps> = ({
  relatedRecipes,
  recipesUrl,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="recipe-slider">
      <Slider {...settings}>
        {relatedRecipes &&
          relatedRecipes.map((slide: any) => {
            const { id, title, image, duration, slug } = slide.node;
            const recipeImage: any =
              image && image.localFile
                ? getImage(image.localFile.childImageSharp)
                : null;
            return (
              <div className="recipe-item" key={id}>
                <div className="recipe-item-list">
                  <div className="recipe-item-image">
                    <Link to={`/${recipesUrl}/${slug}`}>
                      <h4 className="recipe-item-title">{title}</h4>
                    </Link>
                  </div>
                  <div className="recipe-item-detail">
                    {duration && (
                      <div className="recipe-item-timming">
                        <span className="recipe-item-timming-value">
                          {duration}
                        </span>
                        <span className="recipe-item-timming-label">MINS</span>
                      </div>
                    )}
                    <div className="recipe-item-image">
                      <GatsbyImage
                        image={recipeImage}
                        alt={image.alternativeText ?? (title || '')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export { RecipeSlider };
