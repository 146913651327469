import { useStaticQuery, graphql } from 'gatsby';

const ReviewsQuery = () => {
  return useStaticQuery(graphql`
    query ReviewsQuery {
      Reviews: allStrapiReviews(
        filter: { rating: { gte: 4 }, type: { regex: "/Google|Facebook/" } }
        sort: { order: DESC, fields: dateAndTime }
        limit: 15
      ) {
        edges {
          node {
            id
            name
            link
            text
            rating
            type
            dateAndTime(formatString: "YYYY-MM-DD hh:mm:ss")
          }
        }
      }
    }
  `);
};

export { ReviewsQuery };
