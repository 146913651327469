import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

export const Fitin56Banner = ({ info }: any) => {
  return (
    <div className="fitin56-banner">
      <div className="container">
        <div className="fitin56-banner-wrapper">
          <div className="fitin56-banner-image">
            {info.image && (
              <GatsbyImage
                image={info.image.localFile.childImageSharp.gatsbyImageData}
                alt={info.image?.alternativeText ?? ''}
              />
            )}
          </div>
          <div className="fitin56-banner-content">
            <ReactMarkdown className="fitin56-banner-content-tagline">
              {info.title}
            </ReactMarkdown>
            <Link to={info.ctaLink}>
              <button type="button" className="fitin56-banner-content-cta">
                {info.ctaButton}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
