import React, { FC, useState, useEffect } from 'react';
import { QuizQuestion } from 'types';
import { useDispatch } from 'react-redux';
import TickIcon from 'assets/svg/TickWithBlueBgIcon.svg';

interface McqQuestionPropTypes {
  Question: QuizQuestion;
  showNextQuestion: any;
  submitedQuestions: any;
  currentquestion: number;
  QuestionsListLength: number;
  previewAnswer: any;
}

const McqQuestion: FC<McqQuestionPropTypes> = ({
  Question,
  showNextQuestion,
  submitedQuestions,
  currentquestion,
  QuestionsListLength,
  previewAnswer,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionSummary, setSelectedOptionSummary] = useState('');
  const { question, multipleChoiceQuestionTypeOptions, description } =
    Question.node;
  const handelOptionSelection = (item: any) => {
    setSelectedOption(item.value);
    setSelectedOptionSummary(item.optionSummaryContent);
  };
  const handelNextQuestion = () => {
    const dataToSave = {
      question,
      answer: selectedOption,
      optionSummary: selectedOptionSummary,
    };
    dispatch(showNextQuestion(dataToSave));
    setSelectedOption('');
    setSelectedOptionSummary('');
  };
  const SubmitAndPreviewAnswers = () => {
    const dataToSave = {
      question,
      answer: selectedOption,
      optionSummary: selectedOptionSummary,
    };
    dispatch(previewAnswer(dataToSave));
  };
  useEffect(() => {
    if (submitedQuestions[currentquestion - 1]) {
      setSelectedOption(submitedQuestions[currentquestion - 1].answer);
      setSelectedOptionSummary(
        submitedQuestions[currentquestion - 1].optionSummary
      );
    }
  }, [currentquestion]);
  return (
    <div className="question-component mcq-type">
      <div className="quiz-item-question-option-wrapper">
        <h4 className="quiz-item-question">{question}</h4>
        <ul className="quiz-item-option-list">
          {multipleChoiceQuestionTypeOptions &&
            multipleChoiceQuestionTypeOptions.map((item: any) => {
              return (
                <li key={item.id}>
                  <button
                    type="button"
                    className={`quiz-item-option-list-item ${
                      selectedOption === item.value ? 'selected' : ''
                    }`}
                    onClick={() => handelOptionSelection(item)}
                  >
                    <div className="quiz-item-option-list-item-label">
                      <span className="quiz-item-option-list-item-icon-tick">
                        {item.value === selectedOption && <TickIcon />}
                      </span>
                      <span>{item.value}</span>
                    </div>
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="quiz-item-action-description-wrapper">
        <p className="quiz-item-description">{description}</p>
        {QuestionsListLength === currentquestion ? (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={selectedOption === ''}
            onClick={() => SubmitAndPreviewAnswers()}
          >
            <span>View My Curated Plan</span>
          </button>
        ) : (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={selectedOption === ''}
            onClick={() => handelNextQuestion()}
          >
            <span>Next</span>
          </button>
        )}
      </div>
    </div>
  );
};
export { McqQuestion };
