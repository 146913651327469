/* eslint-disable react/jsx-no-comment-textnodes */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { MediaHubData } from 'types';
import { truncate } from 'utils';

const MediaAppearance: FC<MediaHubData> = ({ Media }) => {
  const mediaImage: any = Media.image
    ? getImage(Media.image?.localFile?.childImageSharp?.gatsbyImageData)
    : null;
  const characterLimit = 250;
  const description = truncate(Media.content, characterLimit);

  return (
    <div className="mediahub_item">
      <div>
        <Link
          to={Media.mediaLink}
          target="blank"
          className="mediahub_item_title"
        >
          {Media.title}
        </Link>
      </div>
      <div className="mediahub-item-image">
        {mediaImage && (
          <GatsbyImage
            image={mediaImage}
            alt={Media?.image?.alternativeText ?? (Media.title || '')}
          />
        )}{' '}
      </div>
      <div className="mediahub_item_description">{description}</div>
    </div>
  );
};
export { MediaAppearance };
