/* eslint-disable react/jsx-no-comment-textnodes */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { LearningHubData } from 'types';

const LearningHub: FC<LearningHubData> = ({ Item, Parentslug }) => {
  const blogImage: any = Item.image
    ? getImage(Item.image?.localFile?.childImageSharp?.gatsbyImageData)
    : null;
  return (
    <div className="recipe-item">
      <h4 className="recipe-item-title">{Item.title}</h4>
      <div className="recipe-item-detail">
        {Item.duration && (
          <div className="recipe-item-timming">
            {' '}
            <span className="recipe-item-timming-value">{Item.duration}</span>
            <span className="recipe-item-timming-lable">MINS</span>{' '}
          </div>
        )}
      </div>
      <div className="recipe-item-image">
        <Link to={`/${Parentslug}/${Item.slug}`}>
          {blogImage && (
            <GatsbyImage
              image={blogImage}
              alt={Item?.image?.alternativeText ?? (Item.title || '')}
            />
          )}{' '}
        </Link>
      </div>
    </div>
  );
};
export { LearningHub };
