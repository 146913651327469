/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { RenderVideoAndImage } from 'components';
import ReactMarkdown from 'react-markdown';
import { truncate } from 'utils';
import RightArrowIcon from 'assets/svg/arrow-right.svg';

const ChallengeStories = ({ challengeStories, characterLimit }: any) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 2.8,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="challenge-stories-slider">
      <Slider {...settings}>
        {challengeStories.edges &&
          challengeStories.edges.map((story: any) => {
            const {
              id,
              title,
              description,
              weightLoss,
              videoimage,
              starRating,
              clientDetails,
            } = story.node;
            const VideoImage: any = [];
            const [showDesc, setShowDesc] = useState(false);
            const storyDescription = useRef() as any;
            const readMoreButton = () => {
              setTimeout(() => {
                if (showDesc === false) {
                  setShowDesc(true);
                } else {
                  setShowDesc(false);
                  const activeHeaderOffset =
                    storyDescription.current.getBoundingClientRect().top +
                    document.documentElement.scrollTop;
                  const windowScrolly = window.scrollY;
                  if (activeHeaderOffset < windowScrolly) {
                    window.scrollBy({
                      top: activeHeaderOffset - windowScrolly - 250,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }
                }
              }, 500);
            };

            if (videoimage) {
              VideoImage.push(videoimage.video?.localFile.publicURL);
            }
            const content =
              description.length > characterLimit
                ? truncate(description, characterLimit)
                : description;
            const starTotal = 5;
            let starPercentageRounded = null;
            const starPercentage = (starRating * 100) / starTotal;
            starPercentageRounded = `${(starPercentage / 10) * 10}%`;
            return (
              <div
                key={id}
                className={`stories-item ${showDesc ? 'set-height' : ''} `}
              >
                <div className="stories-item-wrapper">
                  <div className="stories-item-image">
                    {weightLoss ? (
                      <div className="stories-item-timming">
                        <span className="stories-item-timming-value">
                          {weightLoss}
                          <span className="stories-item-timming-label">KG</span>
                        </span>
                      </div>
                    ) : (
                      starRating && (
                        <div className="stories-item-timming">
                          <span className="stories-item-timming-value">
                            {starRating}
                            <div className="stars-outer">
                              <div
                                className="stars-inner"
                                style={{ width: starPercentageRounded }}
                              />
                            </div>
                          </span>
                        </div>
                      )
                    )}

                    <RenderVideoAndImage
                      data={videoimage}
                      altText={title}
                      hasPlayButton
                      hasBorder={false}
                      buttontype="icon"
                      buttonsize="small"
                      videogallery={VideoImage}
                      showControls
                      showModal
                      showModalforMobile
                    />
                  </div>
                  <div className="stories-item-content">
                    <h4 className="stories-item-title">{title}</h4>
                    {clientDetails && (
                      <h2 className="stories-item-name">{clientDetails}</h2>
                    )}
                    <div className="stories-description" ref={storyDescription}>
                      {description && (
                        <>
                          <ReactMarkdown className="md-html">
                            {showDesc ? description : content}
                          </ReactMarkdown>
                          {description.length > characterLimit && (
                            <button
                              type="button"
                              className={`read-btn ${
                                !showDesc ? 'title' : ''
                              } `}
                              onClick={readMoreButton}
                            >
                              {showDesc ? 'Read less' : 'Read More'}
                              <RightArrowIcon className="read-btn-svg" />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export { ChallengeStories };
