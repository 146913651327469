import React, { FC, useState, useEffect } from 'react';
import {
  DieteticsFilter,
  DieteticsService,
  DietitianSection,
} from 'components';
import { DieteticsServiceAreaPageType } from 'types';
import { LoadMore } from 'components/animations';

const DieteticsServiceArea: FC<DieteticsServiceAreaPageType> = ({
  dieteticsarea,
  dieteticservice,
  dietitian,
  meetdietitiancontent,
  pathname,
  dietitianLink,
  handleDietitianModalOpen,
}: any) => {
  const dieteticsFilter = dieteticsarea.edges.map(
    (dietetics: any) => dietetics.node.title
  );
  const [DieteticsFilteredValue, setDieteticsFilteredValue] = useState(
    dieteticsarea.edges
  );
  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = useState('');
  const getFilterList = () => {
    let item: any = [];
    const FilterdValue = dieteticsarea.edges.filter((filterItem: any) => {
      item = filterItem.node.dietetics_services.filter((services: any) => {
        return services.title.toLowerCase().includes(keyword.toLowerCase());
      });
      return (
        filterItem.node.title.includes(filter) &&
        filterItem.node.dietetics_services.some(i => item.includes(i))
      );
    });
    setDieteticsFilteredValue(FilterdValue);
  };
  useEffect(() => {
    setDieteticsFilteredValue(dieteticsarea.edges);
    getFilterList();
  }, [keyword, filter]);

  return (
    <section>
      <div className="container">
        <div className="dietetics-area">
          <DieteticsFilter
            Filters={dieteticsFilter}
            setKeyword={setKeyword}
            setFilter={setFilter}
            List={dieteticsarea}
          />
          {DieteticsFilteredValue.length < 1 && (
            <div className="no-record">No Records Found</div>
          )}
          <LoadMore cardClassName=".dietetics-service,.dietetics-main">
            {DieteticsFilteredValue.map((dietetics: any) => {
              return (
                <div className="dietetics-main">
                  <div className="dietetics-title">{dietetics.node.title}</div>
                  <div className="dietetics-content">
                    {dietetics.node.description}
                  </div>
                  <div className="dietetics-service">
                    {dietetics.node.dietetics_services.map((service: any) => {
                      return service.title
                        .toLowerCase()
                        .includes(keyword.toLowerCase()) ? (
                        <DieteticsService
                          service={service}
                          dieteticservice={dieteticservice}
                          pathname={pathname}
                        />
                      ) : null;
                    })}
                  </div>
                </div>
              );
            })}
          </LoadMore>
        </div>
      </div>
      <DietitianSection
        dietitians={dietitian.edges}
        meetdietitiancontent={meetdietitiancontent}
        dietitianLink={dietitianLink}
        handleDietitianModalOpen={handleDietitianModalOpen}
      />
    </section>
  );
};
export { DieteticsServiceArea };
