/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { getDieticianCount } from 'utils';

const DieteticsService: FC = ({ service, dieteticservice, pathname }: any) => {
  const { title, image } = service;
  const serviceimage: any = image
    ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
    : null;
  const dietician =
    dieteticservice && getDieticianCount(dieteticservice.edges, title);
  return (
    <div className="dietetics-service-item">
      <div className="dietetics-service-item-title">
        <Link to={`${pathname}/${service.slug}`}> {service.title} </Link>
      </div>
      {serviceimage && (
        <div className="dietetics-service-item-image">
          <div className="dietetics-service-item-image-wrapper">
            <Link to={`${pathname}/${service.slug}`}>
              <GatsbyImage
                image={serviceimage}
                alt={image?.alternativeText ?? (title || '')}
              />
            </Link>
            {dieteticservice && (
              <div className="dietetics-service-item-dietitian">
                <div className="count">{dietician}</div>
                {dietician > 1 ? (
                  <span>Dietitians</span>
                ) : (
                  <span>Dietitian</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { DieteticsService };
