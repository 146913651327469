/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { FC, useState, useReducer, useEffect } from 'react';
import {
  UPDATE_FORM,
  onInputChange,
  checkFormStatusOnSubmit,
  reset,
} from 'utils';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import Loader from 'assets/svg/loader.svg';
import { useLocation } from '@reach/router';

type FormEvent = React.FormEvent<HTMLFormElement>;

interface initialStateKeys {
  [key: string]: any;
}
interface initialStateValueType extends initialStateKeys {
  value: string;
  hasError: boolean;
  error: string;
}

interface updateFormActionType {
  type: string;
  data: {
    name: string;
    value: string;
    hasError: boolean;
    error: string;
    isFormValid: boolean;
  };
}
interface initialStateForAllFieldType {
  name: initialStateValueType;
  email: initialStateValueType;
  message: initialStateValueType;
  heardAboutUs?: initialStateValueType;
  dealTitle: initialStateValueType;
  service?: initialStateValueType;
  phoneNo?: initialStateValueType | any | undefined;
  postCode?: initialStateValueType | any | undefined;
  callTime?: initialStateValueType | any | undefined;
  isFormValid: boolean;
}
interface ContactFormPropType {
  restrictFields?: boolean;
  dealTitle: string;
  buttonText?: any;
}

const ContactForm: FC<ContactFormPropType> = ({
  restrictFields = false,
  dealTitle,
  buttonText,
}) => {
  let initialState: initialStateForAllFieldType;
  if (!restrictFields) {
    initialState = {
      name: { value: '', hasError: true, error: '' },
      email: { value: '', hasError: true, error: '' },
      message: { value: '', hasError: true, error: '' },
      phoneNo: { value: '', hasError: true, error: '' },
      postCode: { value: '', hasError: true, error: '' },
      callTime: { value: '', hasError: true, error: '' },
      heardAboutUs: { value: '', hasError: true, error: '' },
      service: { value: '', hasError: true, error: '' },
      dealTitle: { value: '', hasError: true, error: '' },
      isFormValid: false,
    };
  } else {
    initialState = {
      name: { value: '', hasError: true, error: '' },
      email: { value: '', hasError: true, error: '' },
      message: { value: '', hasError: true, error: '' },
      dealTitle: { value: '', hasError: true, error: '' },
      isFormValid: false,
    };
  }
  /**
   * Reducer which will perform form state update
   */
  const formsReducer = (
    state: initialStateForAllFieldType | any,
    action: updateFormActionType
  ) => {
    switch (action.type) {
      case UPDATE_FORM:
        const { name, value, hasError, error, isFormValid } = action.data;
        return {
          ...state,
          [name]: { ...state[name], value, hasError, error },
          isFormValid,
        };
      default:
        return state;
    }
  };
  const data: any = contactFormQuery();
  const [formState, dispatch] = useReducer(formsReducer, initialState);
  const [showError, setShowError] = useState(false);
  const [validation, changeValidation] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, isloading] = useState(false);
  const { pathname } = useLocation();
  const { contactFormSuccessMessage, contactFormFailureMessage } =
    data.strapiGlobal.FormMessage;
  const hearaboutusForm = data.strapiGlobal.footer.contactForm;
  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault(); // prevents the form from submitting
    const isFormValid = checkFormStatusOnSubmit(formState, dispatch);
    const URL: string = data.site.siteMetadata.formUrl;
    if (isFormValid) {
      isloading(true);
      const updatedFormState = {
        ...formState,
        dealTitle: { value: dealTitle, hasError: true, error: '' },
      };
      axios
        .post(URL, {
          formState: updatedFormState,
        })
        .then(response => {
          if (response) {
            setShowSuccess(true);
            isloading(false);
          }
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeValidation(contactFormFailureMessage);
          } else {
            changeValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
          }
          setShowError(true);
          isloading(false);
        });
    }

    // Hide the error message after 10 seconds
    setTimeout(() => {
      setShowError(false);
      setShowSuccess(false);
    }, 15000);
  };

  useEffect(() => {
    if (showSuccess === true) reset(formState, dispatch);
  }, [showSuccess]);

  useEffect(() => {
    reset(formState, dispatch);
  }, [pathname]);

  return (
    <form onSubmit={e => formSubmitHandler(e)}>
      {showError && <div>{validation}</div>}
      {showSuccess && (
        <div className="submit-message">{contactFormSuccessMessage}</div>
      )}
      <input
        type="text"
        id="get-in-touch-name"
        placeholder="Name"
        onChange={e => {
          onInputChange('name', e.target.value, dispatch, formState);
        }}
        name="name"
        value={formState.name.value}
        className="form-field"
      />
      {formState.name.hasError && (
        <div className="submit-message">{formState.name.error}</div>
      )}
      <input
        type="text"
        placeholder="E-mail"
        onChange={e => {
          onInputChange('email', e.target.value, dispatch, formState);
        }}
        name="email"
        value={formState.email.value}
        className="form-field"
      />
      {formState.email.hasError && (
        <div className="submit-message">{formState.email.error}</div>
      )}
      {!restrictFields && (
        <>
          <input
            type="tel"
            placeholder="Phone"
            onChange={e => {
              onInputChange('phoneNo', e.target.value, dispatch, formState);
            }}
            name="phoneNo"
            value={formState.phoneNo.value}
            className="form-field"
          />
          {formState.phoneNo.hasError && (
            <div className="submit-message">{formState.phoneNo.error}</div>
          )}

          <input
            type="number"
            placeholder="Postcode"
            onChange={e => {
              onInputChange('postCode', e.target.value, dispatch, formState);
            }}
            name="postCode"
            value={formState.postCode.value}
            className="form-field"
          />
          {formState.postCode.hasError && (
            <div className="submit-message">{formState.postCode.error}</div>
          )}
          <input
            type="text"
            placeholder="Best Time To Call"
            onChange={e => {
              onInputChange('callTime', e.target.value, dispatch, formState);
            }}
            name="callTime"
            value={formState.callTime.value}
            className="form-field"
          />
          {formState.callTime.hasError && (
            <div className="submit-message">{formState.callTime.error}</div>
          )}

          <select
            className="form-field form_Select"
            onChange={e => {
              onInputChange(
                'heardAboutUs',
                e.target.value,
                dispatch,
                formState
              );
            }}
            value={formState.heardAboutUs.value}
          >
            <option value="" disabled>
              Please select how you heard about us?
            </option>
            {hearaboutusForm.heardAboutUS.map((item: any) => {
              return (
                <option value={item.title} key={item.id}>
                  {item.title}
                </option>
              );
            })}
          </select>
          {formState.heardAboutUs.hasError && (
            <div className="submit-message">{formState.heardAboutUs.error}</div>
          )}

          <select
            className="form-field form_Select"
            onChange={e => {
              onInputChange('service', e.target.value, dispatch, formState);
            }}
            value={formState.service.value}
          >
            <option value="" disabled>
              Please select the service.
            </option>
            <option value="Personal Trainers">Personal Training</option>
            <option value="Dietitians">Dietetics</option>
          </select>
          {formState.service.hasError && (
            <div className="submit-message">{formState.service.error}</div>
          )}
        </>
      )}
      <textarea
        name="message"
        placeholder="Message"
        rows={6}
        cols={20}
        onChange={e => {
          onInputChange('message', e.target.value, dispatch, formState);
        }}
        value={formState.message.value}
        className="form-field"
      />
      {formState.message.hasError && (
        <div className="submit-message">{formState.message.error}</div>
      )}
      <button type="submit" className="form-action" disabled={loading}>
        {!loading ? (
          <span> {buttonText ? <span>{buttonText}</span> : 'Submit'}</span>
        ) : (
          <div className="form-action-loader">
            <Loader />
          </div>
        )}
      </button>
    </form>
  );
};

export { ContactForm };

const contactFormQuery = () => {
  return useStaticQuery(graphql`
    query contactFormQuery {
      site {
        siteMetadata {
          formUrl
        }
      }
      strapiGlobal {
        FormMessage {
          contactFormSuccessMessage
          contactFormFailureMessage
        }
        footer {
          contactForm {
            heardAboutUS {
              id
              title
            }
          }
        }
      }
    }
  `);
};
