/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react';
import FbIcon from 'assets/svg/Header-Fb-Icon.svg';
import InstaIcon from 'assets/svg/Header-Insta-Icon.svg';
import { SocialMediaQuery } from 'queries/social-media';
import MailIconBlack from 'assets/svg/MailIconBlack.svg';

const SocialMedia: FC = () => {
  const data = SocialMediaQuery();

  return (
    <ul className="social_media_menu">
      <li className="header_contact_mail">
        {data.ContactUsPage.contactFormSection.email &&
        data.ContactUsPage.contactFormSection.email ? (
          <a href={`mailto:${data.ContactUsPage.contactFormSection.email}`}>
            <MailIconBlack />
          </a>
        ) : null}
      </li>
      <li>
        {data.GlobalSocialMedia.socialMediaLinks.facebookLink &&
        data.GlobalSocialMedia.socialMediaLinks.facebookLink ? (
          <a
            href={data.GlobalSocialMedia.socialMediaLinks.facebookLink}
            target="_blank"
            rel="noreferrer"
          >
            <FbIcon />
          </a>
        ) : null}
      </li>
      <li>
        {data.GlobalSocialMedia.socialMediaLinks.instagramLink &&
        data.GlobalSocialMedia.socialMediaLinks.instagramLink ? (
          <a
            href={data.GlobalSocialMedia.socialMediaLinks.instagramLink}
            target="_blank"
            rel="noreferrer"
          >
            <InstaIcon />
          </a>
        ) : null}
      </li>
    </ul>
  );
};

export { SocialMedia };
