/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-case-declarations */
import React, { FC, useReducer, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  UPDATE_FORM,
  onInputChange,
  checkFormStatusOnSubmit,
  reset,
} from 'utils';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import Loader from 'assets/svg/loader.svg';

type FormEvent = React.FormEvent<HTMLFormElement>;

interface CareerFormQuizPropsType {
  submitedQuestions: any;
  resetQuiz: any;
  handleModalClose: any;
  formSuccessMessage: string;
  formSuccessTitle: string;
  showSuccess: any;
  setShowSuccess: any;
}

interface initialStateKeys {
  [key: string]: any;
}
interface initialStateValueType extends initialStateKeys {
  value: string;
  hasError: boolean;
  error: string;
}

interface updateFormActionType {
  type: string;
  data: {
    name: string;
    value: string;
    hasError: boolean;
    error: string;
    isFormValid: boolean;
  };
}
interface initialStateForAllFieldType {
  name: initialStateValueType;
  email: initialStateValueType;
  phoneNo: initialStateValueType;
  subUrb: initialStateValueType;
  isFormValid: boolean;
  dealTitle: initialStateValueType;
}

const CareerFormQuiz: FC<CareerFormQuizPropsType> = ({
  submitedQuestions,
  resetQuiz,
  handleModalClose,
  formSuccessMessage,
  formSuccessTitle,
  showSuccess,
  setShowSuccess,
}) => {
  const initialState: initialStateForAllFieldType = {
    name: { value: '', hasError: true, error: '' },
    email: { value: '', hasError: true, error: '' },
    phoneNo: { value: '', hasError: true, error: '' },
    subUrb: { value: '', hasError: true, error: '' },
    dealTitle: { value: '', hasError: true, error: '' },
    isFormValid: false,
  };
  const data: any = quizCareerFormQuery();
  const URL: string = data.site.siteMetadata.quizUrl;
  const formsReducer = (
    state: initialStateForAllFieldType | any,
    action: updateFormActionType
  ) => {
    switch (action.type) {
      case UPDATE_FORM:
        const { name, value, hasError, error, isFormValid } = action.data;
        return {
          ...state,
          [name]: { ...state[name], value, hasError, error },
          isFormValid,
        };
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(formsReducer, initialState);
  const [showError, setShowError] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [validation, changeValidation] = useState('');
  const { pathname } = useLocation();
  const set = useDispatch();
  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault();
    const isFormValid = checkFormStatusOnSubmit(formState, dispatch);
    if (isFormValid) {
      setFormSubmitted(true);
      const questionnaire = submitedQuestions.map((element: any) => {
        const obj = {
          [element.question]: element.answer,
        };
        return obj;
      });
      axios
        .post(URL, {
          formState: {
            ...formState,
            dealTitle: { value: 'Careers Quiz', hasError: true, error: '' },
          },
          questionnaire,
        })
        .then(() => {
          setShowSuccess(true);
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeValidation('Sorry something went wrong');
          } else {
            changeValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
            setShowError(true);
          }
        });
    }
  };

  const completeQuiz = (e: FormEvent) => {
    e.preventDefault();
    handleModalClose();
    setTimeout(() => {
      set(resetQuiz());
    }, 500);
  };

  useEffect(() => {
    reset(formState, dispatch);
  }, [pathname]);

  return (
    <div className="quiz-form-section career-quiz-form">
      <div className="quiz-form-container">
        {!showSuccess && !showError && (
          <h4 className="quiz-form-title">Contact Details</h4>
        )}
        {showSuccess && (
          <>
            <h4 className="quiz-form-title">{formSuccessTitle}</h4>
            <p>{formSuccessMessage}</p>
            <form onSubmit={e => completeQuiz(e)}>
              <button className="quiz-form-submit-btn" type="submit">
                <span>Close</span>
              </button>
            </form>
          </>
        )}
        {showError && (
          <>
            <h4 className="quiz-form-title">{validation}</h4>
            <p>Please try again</p>
          </>
        )}
        {!showSuccess && !showError && (
          <form onSubmit={e => formSubmitHandler(e)}>
            <div className="career-quiz-form-wrapper">
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  disabled={isFormSubmitted}
                  className="quiz-form-field"
                  value={formState.name.value}
                  onChange={e => {
                    onInputChange('name', e.target.value, dispatch, formState);
                  }}
                />
                {formState.name.hasError && (
                  <div className="submit-message">{formState.name.error}</div>
                )}
              </div>

              <input
                type="text"
                placeholder="Email"
                className="quiz-form-field"
                name="email"
                disabled={isFormSubmitted}
                onChange={e => {
                  onInputChange('email', e.target.value, dispatch, formState);
                }}
                value={formState.email.value}
              />
              {formState.email.hasError && (
                <div className="submit-message">{formState.email.error}</div>
              )}
              <input
                type="text"
                placeholder="Phone Number"
                className="quiz-form-field"
                name="phoneNo"
                disabled={isFormSubmitted}
                onChange={e => {
                  onInputChange('phoneNo', e.target.value, dispatch, formState);
                }}
                value={formState.phoneNo.value}
              />
              {formState.phoneNo.hasError && (
                <div className="submit-message">{formState.phoneNo.error}</div>
              )}
              <div>
                <input
                  type="text"
                  placeholder="Suburb"
                  className="quiz-form-field"
                  name="Suburb"
                  disabled={isFormSubmitted}
                  onChange={e => {
                    onInputChange(
                      'subUrb',
                      e.target.value,
                      dispatch,
                      formState
                    );
                  }}
                  value={formState.subUrb.value}
                />
                {formState.subUrb.hasError && (
                  <div className="submit-message">{formState.subUrb.error}</div>
                )}
              </div>
            </div>
            <div className="career-quiz-form-button">
              <button
                type="submit"
                className="quiz-form-submit-btn"
                disabled={isFormSubmitted}
              >
                {isFormSubmitted && (
                  <span className="form-action-loader">
                    <Loader />
                  </span>
                )}
                {!isFormSubmitted && <span>Submit Form</span>}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
const quizCareerFormQuery = () => {
  return useStaticQuery(graphql`
    query quizCareerFormQuery {
      site {
        siteMetadata {
          quizUrl
        }
      }
    }
  `);
};
export { CareerFormQuiz };
