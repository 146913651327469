import React from 'react';
import tickIcon from 'assets/images/tick.png';
import { FeaturedJobPerkItemType } from 'types';
import { SlideUpElement } from 'components/animations';

interface FeaturedJobPerksPropType {
  data: [FeaturedJobPerkItemType];
}

export const FeaturedJobPerks = ({ data }: FeaturedJobPerksPropType) => {
  return (
    <div className="career-page-featured-job-perk">
      {data?.length &&
        data.map((item: FeaturedJobPerkItemType) => {
          return (
            <SlideUpElement
              className={`featured-job-perk-item ${item?.hasBg ? 'hasBg' : ''}`}
              key={item.id}
            >
              {item?.hasBg && <img src={tickIcon} alt={item.title} />}
              {item?.title && (
                <p className="featured-job-perk-item-title">{item.title}</p>
              )}
            </SlideUpElement>
          );
        })}
    </div>
  );
};
