import React, { FC } from 'react';
import { Link } from 'gatsby';

interface BreadCrumbsPropsType {
  breadCrumb: { title: string; slug: string }[];
}

const BreadCrumbs: FC<BreadCrumbsPropsType> = ({
  breadCrumb: breadCrumbObject,
}) => {
  if (breadCrumbObject) {
    return (
      <ul className="breadcrumb-list">
        {breadCrumbObject.map((page, index) => {
          const arrow = index !== breadCrumbObject.length - 1;
          return (
            <li key={page.slug} className="breadcrumb-list-item">
              <Link to={page.slug}>{page.title}</Link>
              {arrow && <span>&gt;</span>}
            </li>
          );
        })}
      </ul>
    );
  }
  return null;
};

export { BreadCrumbs };
