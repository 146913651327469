import React from 'react';
import ReactMarkdown from 'react-markdown';

export const ParagraphWithGradientTop = ({ children }: any) => (
  <div className="paragraph-gradient-top">
    <div className="content">
      <ReactMarkdown className="md-html">{children}</ReactMarkdown>
    </div>
  </div>
);
