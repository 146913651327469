import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { TitleAndTextComponentType } from 'types';
import { SlideUpTitle, SlideUpContent } from 'components/animations';

const TwoColumnSection: FC<TitleAndTextComponentType> = ({
  title,
  content,
}) => {
  return (
    <section className="twocolumnsection">
      <SlideUpTitle className="twocolumnsection-title" durationTime={1.3}>
        <h2 className="twocolumnsection-heading">{title}</h2>
      </SlideUpTitle>
      <SlideUpContent className="twocolumnsection-content">
        <ReactMarkdown className="md-html">{content}</ReactMarkdown>
      </SlideUpContent>
    </section>
  );
};
export { TwoColumnSection };
