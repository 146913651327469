import React, { FC, useState, useEffect } from 'react';
import { Header, Footer, Cart } from 'components';
import 'sass/style.scss';
import { FooterQuery } from 'queries/footer';
import Logo from 'assets/svg/get-going-logo.svg';

export interface LayoutProps {
  children: JSX.IntrinsicElements['div'];
  path: string;
}

const Layout: FC<LayoutProps> = props => {
  const { children, path } = props;
  const data: any = FooterQuery();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  const weightlossslug = data.strapiWeightLossPage.slug;
  if (path === '/checkout/' || path === `/${weightlossslug}/`) {
    return (
      <>
        <Header />
        <main>
          <main>{children}</main>
          <Footer data={data} />
        </main>
        {loading && (
          <div className="page-loader">
            <Logo />
          </div>
        )}
        {path === `/${weightlossslug}/` && <Cart />}
      </>
    );
  }
  return (
    <>
      <Header />
      <main>
        <main>{children}</main>
        <Footer data={data} />
      </main>
      {loading && (
        <div className="page-loader">
          <Logo />
        </div>
      )}
    </>
  );
};

export default Layout;
