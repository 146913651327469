import React from 'react';
import ReactMarkdown from 'react-markdown';
import Circle from 'assets/svg/circle.svg';
import UserIcon from 'assets/svg/User-icon.svg';
import { ImageSlide, SlideIn } from 'components/animations';

const DesktopHowItWorks = ({ howItWorks, ctaButtonText, scrollPlan }: any) => {
  return (
    <div className="how-it-works-wrapper">
      <Circle />
      <div className="how-it-works-wrapper-item">
        <div className="how-it-works-wrapper-item-list">
          <div className="how-it-works-wrapper-item-list-title">
            <UserIcon />
            <span>{howItWorks.weightLossJourneyTitle}</span>
            {ctaButtonText && (
              <div className="how-it-works-cta-btn-container">
                <button
                  type="button"
                  className="how-it-works-cta-btn"
                  onClick={() => scrollPlan()}
                >
                  <span>{ctaButtonText}</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <ul className="how-it-works-wrapper-journey">
          {howItWorks.weightLossJourney.map((item: any, index: number) => {
            const { id, title, description, image } = item;
            return (
              <li className="how-it-works-wrapper-journey-wrapper" key={id}>
                <div className="how-it-works-wrapper-journey-wrapper-content">
                  {image?.localFile?.publicURL && (
                    <ImageSlide
                      className="how-it-works-wrapper-image"
                      direction={index === 1 ? 'right' : 'left'}
                    >
                      <img src={image?.localFile?.publicURL} alt={title} />
                    </ImageSlide>
                  )}
                  <SlideIn
                    className="how-it-works-wrapper-title"
                    direction={index === 1 ? 'right' : 'left'}
                  >
                    <div className="how-it-works-wrapper-number">
                      {index + 1}
                    </div>
                    <div className="how-it-works-wrapper-summary">
                      <span>{title}</span>
                      <div className="how-it-works-wrapper-description">
                        <ReactMarkdown className="md-html">
                          {description}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </SlideIn>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export { DesktopHowItWorks };
