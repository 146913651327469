/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-undef */
import React, { FC, useState } from 'react';
import ReactModal from 'react-modal';
import { EnquireContactForm } from 'components';
import { useStaticQuery, graphql } from 'gatsby';

interface EnquireFormModalProps {
  isEnquireModalOpen: boolean;
  setisEnquireModalOpen: any;
  contactFormModalTitle: string;
  modalText: string;
  dietitian?: string;
  trainer?: string;
  optionalMessage?: boolean;
  dealTitle?: string;
  posCodeValue?: any;
  showNdisFields?: boolean;
}

export const EnquireFormModal: FC<EnquireFormModalProps> = ({
  isEnquireModalOpen,
  setisEnquireModalOpen,
  contactFormModalTitle,
  posCodeValue,
  modalText,
  optionalMessage = false,
  trainer,
  dietitian,
  dealTitle,
  showNdisFields = false,
}) => {
  const handleEnquireModalClose = () => {
    setisEnquireModalOpen(false);
    setFormSubmited(false);
  };
  const data = EnquireContactFormMessageQuery();
  const { contactFormSuccessMessage } = data.strapiGlobal.FormMessage;
  const [isFormSubmited, setFormSubmited] = useState(false);
  return (
    <div>
      <ReactModal
        closeTimeoutMS={100}
        isOpen={isEnquireModalOpen}
        onRequestClose={handleEnquireModalClose}
        ariaHideApp={false}
        portalClassName="ReactModalPortal enquiremodal"
      >
        <div className="enquiremodal_form">
          {!isFormSubmited && (
            <>
              <button
                type="button"
                onClick={() => handleEnquireModalClose()}
                className="modal-close"
              >
                <span>&#x2715;</span>
              </button>
              <h3>{contactFormModalTitle}</h3>
              {modalText && <p>{modalText}</p>}
              <EnquireContactForm
                setFormSubmited={setFormSubmited}
                title={contactFormModalTitle}
                optionalMessage={optionalMessage}
                trainer={trainer}
                dietitian={dietitian}
                dealTitle={dealTitle}
                posCodeValue={posCodeValue}
                showNdisFields={showNdisFields}
              />
            </>
          )}
          {isFormSubmited && (
            <div className="enquiremodal_form_success">
              <h4>Success!</h4>
              <p className="enquiremodal_form_success_content">
                {contactFormSuccessMessage}
              </p>
              <button
                type="button"
                onClick={() => handleEnquireModalClose()}
                className="enquiremodal_form_success_button"
              >
                <span>Done</span>
              </button>
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

const EnquireContactFormMessageQuery = () => {
  return useStaticQuery(graphql`
    query EnquireContactFormMessageQuery {
      strapiGlobal {
        FormMessage {
          contactFormSuccessMessage
        }
      }
    }
  `);
};
