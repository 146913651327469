/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import RightQuoteSign from 'assets/svg/right-quote-sign.svg';
import Facebook from 'assets/svg/facebook.svg';
import Trustpilot from 'assets/svg/trustpilot.svg';
import Google from 'assets/svg/google.svg';
import { PageHeading, StarRating } from 'components';
import { ReviewsQuery } from 'queries/reviews';
import { getDateandTime, truncate } from 'utils';

interface ReviewsProps {
  delaySec?: number;
}
const Reviews: FC<ReviewsProps> = ({ delaySec }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 4,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  const reviewList = ReviewsQuery();
  return (
    <section className="review-section">
      <div className="container">
        <PageHeading title="Our reviews" delaySec={delaySec} />
      </div>
      <div className="review-list">
        <Slider {...settings}>
          {reviewList?.Reviews?.edges?.map((review: any) => {
            let ReviewIcon;
            switch (review.node.type) {
              case 'Facebook':
                ReviewIcon = Facebook;
                break;
              case 'TrustPilot':
                ReviewIcon = Trustpilot;
                break;
              case 'Google':
                ReviewIcon = Google;
                break;
              default:
                ReviewIcon = Google;
                break;
            }
            return (
              <div key={review.node.id} className="review-item">
                <div className="review-item-header">
                  <div className="review-item-icon">
                    <a href={review.node.link} target="_blank" rel="noreferrer">
                      <ReviewIcon />
                    </a>
                  </div>
                  <span> {getDateandTime(review.node.dateAndTime)}</span>
                </div>
                <div className="review-item-content">
                  <StarRating rating={review.node.rating} />
                  {review.node.text && truncate(review.node.text, 200)}
                </div>
                <div className="review-item-footer">
                  <h5 className="review-item-author">{review.node.name}</h5>
                  <RightQuoteSign />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export { Reviews };
