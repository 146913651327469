/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from 'react';
import Slider from 'react-slick';
import { RenderVideoAndImage } from 'components';
import ReactMarkdown from 'react-markdown';
import { BeforeAfterSliderComponentPropsType } from 'types';

const BeforeAfterSlider: FC<BeforeAfterSliderComponentPropsType> = ({
  slides,
  successStoryBeforeAndAfterSliderTitle,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const VideoGallery: any = [];
  useEffect(() => {
    slides.forEach(element => {
      VideoGallery.push(element.videoImage.video?.localFile.publicURL);
    });
  }, [VideoGallery]);
  return (
    <section className="successstories">
      <div className="successstories_list">
        <Slider {...settings}>
          {slides.map(slide => {
            return (
              <div className="successstories_item" key={slide.id}>
                <div className="successstories_heading">
                  <p className="successstories_title">
                    {successStoryBeforeAndAfterSliderTitle}
                  </p>
                  <p className="successstories_name">{slide.name}</p>
                </div>
                <div className="successstories_wrapper">
                  <div className="successstories_img_progress">
                    <RenderVideoAndImage
                      data={slide.videoImage}
                      altText={slide.name}
                      hasPlayButton
                      buttontype="icon"
                      buttonsize="medium"
                      videogallery={VideoGallery}
                      showModal
                    />
                  </div>
                  <div className="successstories_content_progress">
                    <div className="successstories_highlights">
                      {slide.highlight}
                    </div>
                    <div className="successstories_summary">
                      <ReactMarkdown className="md-html">
                        {slide.content}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};
export { BeforeAfterSlider };
