import React from 'react';
import ReactMarkdown from 'react-markdown';
import { SlideUpContent, SlideIn } from 'components/animations';
import WeightPackage from 'assets/svg/weight-package.svg';
import Teamconsult from 'assets/svg/team-consult.svg';
import FitnessProffesional from 'assets/svg/match-fitness.svg';
import Customplan from 'assets/svg/custom-plan.svg';
import Feelhappy from 'assets/svg/feel-happy.svg';

const WeightLossSteps = ({ data }: any) => {
  const { description, enquiryText, tagline, title, stepsToWeightLoss } = data;
  return (
    <div className="container">
      <section className="weight-loss-steps">
        <ReactMarkdown className="page-heading-title title-with-line">
          {title}
        </ReactMarkdown>
        <SlideUpContent className="weightloss-page-content">
          <div className="weightloss-page-tagline">
            <ReactMarkdown className="md-html">{tagline}</ReactMarkdown>
          </div>
          <div className="weightloss-page-description">
            <ReactMarkdown className="md-html">{description}</ReactMarkdown>
          </div>
        </SlideUpContent>
        <div className="weight-loss-steps-content">
          <div className="weight-loss-steps-wrapper">
            <ul className="weight-loss-steps-list">
              {stepsToWeightLoss.map((item: any, index: number) => {
                let Icon;
                switch (item.icon) {
                  case 'chooseWeightPackage':
                    Icon = WeightPackage;
                    break;
                  case 'consultTeam':
                    Icon = Teamconsult;
                    break;
                  case 'matchProfessionals':
                    Icon = FitnessProffesional;
                    break;
                  case 'customPlan':
                    Icon = Customplan;
                    break;
                  case 'feelHappier':
                    Icon = Feelhappy;
                    break;
                  default:
                    Icon = Feelhappy;
                    break;
                }
                return (
                  <SlideIn
                    durationTime={index + 1 >= 4 ? index - 1.5 : index + 0.5}
                    className="weight-loss-steps-wrapper-list"
                  >
                    <li className="weight-loss-steps-items" key={item.id}>
                      <div>{item.title}</div>
                      <div className="weight-loss-steps-icon">
                        <div>
                          {' '}
                          <Icon />
                        </div>
                      </div>
                      <div className="weight-loss-steps-description">
                        {item.description}
                      </div>
                    </li>
                  </SlideIn>
                );
              })}
            </ul>
            <ReactMarkdown className="weight-loss-steps-enquiry">
              {enquiryText}
            </ReactMarkdown>
          </div>
        </div>
      </section>
    </div>
  );
};

export { WeightLossSteps };
