import React, { useState } from 'react';
import { EnquireFormModal } from 'components';

const GetInTouch = ({ getInTouchCtaLabel }: any) => {
  const [isEnquireModalOpen, setisEnquireModalOpen] = useState(false);

  const handleEnquireModalOpen = () => {
    setisEnquireModalOpen(true);
  };

  return (
    <>
      <div className="story-page-cta-link-container">
        <button
          className="story-page-cta-link"
          type="button"
          onClick={handleEnquireModalOpen}
        >
          <span>{getInTouchCtaLabel}</span>
        </button>
      </div>
      <EnquireFormModal
        isEnquireModalOpen={isEnquireModalOpen}
        setisEnquireModalOpen={setisEnquireModalOpen}
        contactFormModalTitle={getInTouchCtaLabel}
        optionalMessage
        dealTitle="Reviews Page"
      />
    </>
  );
};

export { GetInTouch };
