/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import React, { useRef } from 'react';
import TickIcon from 'assets/svg/TickWithBlueBgIcon.svg';
import InCorrectIcon from 'assets/svg/incorrect-mark.svg';
import PopupIcon from 'assets/svg/popupIcon.svg';

const weightLossOptionsStyle = {
  height: 0,
  overflow: 'hidden',
  transition: 'height 0.8s, overflow 0s',
} as React.CSSProperties;

const AccordionSlide = ({
  weightlosskeys,
  weightlossIcons,
  onToggle,
  activeClass,
  handleClick,
  weightLossListAddTorefs,
}: any) => {
  const { title, id, weight_loss_keys } = weightlosskeys;

  const weightLossListWrapper =
    useRef() as React.MutableRefObject<HTMLDivElement>;

  return (
    <div
      className={`mobile-wl-package-services-details ${
        activeClass ? 'active' : ''
      } `}
      key={id}
      ref={weightLossListAddTorefs}
    >
      <h3
        className="mobile-wl-package-services-details-heading"
        onClick={onToggle}
        onKeyPress={onToggle}
      >
        <span>{weightlossIcons}</span>
        {title}
      </h3>
      <div
        className={`mobile-wl-package-services-key-detailslist `}
        style={activeClass ? { height: 'auto' } : weightLossOptionsStyle}
      >
        <div ref={weightLossListWrapper}>
          {weight_loss_keys.map((weightLossKey: any) => {
            return (
              <div
                key={weightLossKey.description}
                className="mobile-wl-package-services-key"
              >
                {weightLossKey.infoPopup.length > 0 ? (
                  <div className="mobile-wl-package-key-wrapper">
                    <button
                      type="button"
                      className="info-icon-btn"
                      onClick={() => {
                        handleClick(weightLossKey);
                      }}
                    >
                      <h4>{weightLossKey.description}</h4>
                      <span>
                        <PopupIcon className="popup-icon" />
                      </span>
                    </button>
                  </div>
                ) : (
                  <div className="mobile-wl-package-key-wrapper">
                    <h4>{weightLossKey.description}</h4>
                  </div>
                )}
                <div className="mobile-wl-package-services-key-item">
                  {weightLossKey.weightLossKeyPoint.map((keyPoint: any) => {
                    const { included, id: keyPointId } = keyPoint;
                    return (
                      <div
                        key={keyPointId}
                        className="mobile-wl-package-services-key-content"
                      >
                        {included === true ? (
                          <TickIcon className="tick-icon" />
                        ) : (
                          <InCorrectIcon />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { AccordionSlide };
