/* eslint-disable  no-console */
import {
  TOOGLE_NAV,
  SHOW_HOME_PAGE_QUIZ_NEXT_QUESTION,
  SHOW_HOME_PAGE_QUIZ_PREV_QUESTION,
  SHOW_CAREER_PAGE_QUIZ_NEXT_QUESTION,
  SHOW_CAREER_PAGE_QUIZ_PREV_QUESTION,
  SHOW_NUTRITION_PAGE_QUIZ_NEXT_QUESTION,
  SHOW_NUTRITION_PAGE_QUIZ_PREV_QUESTION,
  HOME_PAGE_QUIZ_MODAL_OPEN,
  HOME_PAGE_QUIZ_MODAL_CLOSE,
  NUTRITION_PAGE_QUIZ_MODAL_OPEN,
  NUTRITION_PAGE_QUIZ_MODAL_CLOSE,
  SUBMIT_HOME_PAGE_QUIZ_AND_PREVIEW_ANSWERS,
  SUBMIT_NUTRITION_PAGE_QUIZ_AND_PREVIEW_ANSWERS,
  UPDATE_HOME_PAGE_QUIZ_PREVIEW_STATUS,
  UPDATE_NUTRITION_PAGE_QUIZ_PREVIEW_STATUS,
  UPDATE_NUTRITION_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS,
  UPDATE_HOME_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS,
  UPDATE_CAREER_PAGE_QUIZ_FORM,
  SUBMIT_CAREER_PAGE_QUIZ,
  RESET_HOME_PAGE_QUIZ,
  RESET_NUTRITION_PAGE_QUIZ,
  RESET_CAREER_PAGE_QUIZ,
  SHOW_CART,
  HIDE_CART,
  UPDATE_CART,
  REMOVE_CART,
  UPDATE_CART_LOADER_STATUS,
  UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS,
  UPDATE_ADD_TO_CART_ERROR,
  UPDATE_CART_ERROR,
  CLEAR_CART_ERROR,
  STORE_INVENTORY_DATA,
  UPDATE_CART_DIGITAL,
} from 'Redux/actionTypes';
import axios from 'axios';

export const toggleNav = () => ({
  type: TOOGLE_NAV,
});

export const homePageQuizModalOpen = () => ({
  type: HOME_PAGE_QUIZ_MODAL_OPEN,
});
export const homePageQuizModalClose = () => ({
  type: HOME_PAGE_QUIZ_MODAL_CLOSE,
});

export const nutritionPageQuizModalOpen = () => ({
  type: NUTRITION_PAGE_QUIZ_MODAL_OPEN,
});

export const nutritionPageQuizModalClose = () => ({
  type: NUTRITION_PAGE_QUIZ_MODAL_CLOSE,
});
export const showHomePageQuizNextQuestion = (data: any) => ({
  type: SHOW_HOME_PAGE_QUIZ_NEXT_QUESTION,
  payload: data,
});

export const showHomePageQuizPrevQuestion = () => ({
  type: SHOW_HOME_PAGE_QUIZ_PREV_QUESTION,
});

export const showCareerPageQuizNextQuestion = (data: any) => ({
  type: SHOW_CAREER_PAGE_QUIZ_NEXT_QUESTION,
  payload: data,
});

export const showCareerPageQuizPrevQuestion = () => ({
  type: SHOW_CAREER_PAGE_QUIZ_PREV_QUESTION,
});

export const showNutritionPageQuizNextQuestion = (data: any) => ({
  type: SHOW_NUTRITION_PAGE_QUIZ_NEXT_QUESTION,
  payload: data,
});

export const showNutritionPageQuizPrevQuestion = () => ({
  type: SHOW_NUTRITION_PAGE_QUIZ_PREV_QUESTION,
});

export const submitHomePageQuizAndPreviewAnswers = (data: any) => ({
  type: SUBMIT_HOME_PAGE_QUIZ_AND_PREVIEW_ANSWERS,
  payload: data,
});

export const submitCareerPageQuiz = (data: any) => ({
  type: SUBMIT_CAREER_PAGE_QUIZ,
  payload: data,
});

export const submitNutritionPageQuizAndPreviewAnswers = (data: any) => ({
  type: SUBMIT_NUTRITION_PAGE_QUIZ_AND_PREVIEW_ANSWERS,
  payload: data,
});

export const updateHomePageQuizPreviewStatus = () => ({
  type: UPDATE_HOME_PAGE_QUIZ_PREVIEW_STATUS,
});

export const updateNutritionPageQuizPreviewStatus = () => ({
  type: UPDATE_NUTRITION_PAGE_QUIZ_PREVIEW_STATUS,
});

export const updateHomePageQuizSummaryReviewedStatus = () => ({
  type: UPDATE_HOME_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS,
});

export const updateNutritionPageQuizSummaryReviewedStatus = () => ({
  type: UPDATE_NUTRITION_PAGE_QUIZ_SUMMARY_REVIEWED_STATUS,
});

export const updateCarrerPageQuizForm = () => ({
  type: UPDATE_CAREER_PAGE_QUIZ_FORM,
});

export const resetHomePageQuiz = () => ({
  type: RESET_HOME_PAGE_QUIZ,
});

export const resetNutritionPageQuiz = () => ({
  type: RESET_NUTRITION_PAGE_QUIZ,
});

export const resetCareerPageQuiz = () => ({
  type: RESET_CAREER_PAGE_QUIZ,
});

// Cart Actions

export const showCart = () => ({
  type: SHOW_CART,
});

export const hideCart = () => ({
  type: HIDE_CART,
});

export const clearErrorMessage = () => ({ type: CLEAR_CART_ERROR });

// Todo get value from env

export const addProductToCart = (
  productId: any,
  variantId: any,
  qty: any,
  cartId: string,
  cartUrl: string,
  errorMsg: string
) => {
  return async (dispatch: any) => {
    const config = { productId, variantId, qty, cartId, cartUrl };
    dispatch({ type: UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS });
    await axios
      .post(`${cartUrl}handleAddToCartAction`, config)
      .then(response => {
        dispatch({
          type: UPDATE_CART,
          payload: response.data.data,
        });
        dispatch({ type: SHOW_CART });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ADD_TO_CART_ERROR,
          payload: errorMsg || 'There was an error!',
        });
        console.error('There was an error!', error);
      });
  };
};
export const addDigitalProductToCart = (
  productId: any,
  variantId: any,
  qty: any,
  cartUrl: string,
  errorMsg: string
) => {
  return async (dispatch: any) => {
    const config = { productId, variantId, qty, cartUrl };
    // dispatch({ type: UPDATE_ADD_TO_CART_BUTTON_LOADER_STATUS });
    await axios
      .post(`${cartUrl}handleDigitalAddToCartAction`, config)
      .then(response => {
        dispatch({
          type: UPDATE_CART_DIGITAL,
          payload: response.data.data,
        });
        // dispatch({ type: SHOW_CART });
        window.location.href = '/checkout/?digital=true';
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ADD_TO_CART_ERROR,
          payload: errorMsg || 'There was an error!',
        });
        console.error('There was an error!', error);
      });
  };
};
export const fetchCart = (cartId: string, cartUrl: string) => {
  return async (dispatch: any) => {
    const config = { cartId, cartUrl };
    await axios
      .post(`${cartUrl}handleFetchCartAction`, config)
      .then(response => {
        dispatch({
          type: UPDATE_CART,
          payload: response.data.data,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };
};

export const deleteProductFromCart = (
  id: any,
  cartId: any,
  cartUrl: string
) => {
  return async (dispatch: any) => {
    const config = { id, cartId };
    dispatch({ type: UPDATE_CART_LOADER_STATUS });

    await axios
      .post(`${cartUrl}handleDeleteItemFromCartAction`, config)
      .then(response => {
        if (response.data) {
          dispatch({
            type: UPDATE_CART,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: REMOVE_CART,
          });
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_CART_ERROR,
          payload: 'Something went wrong please try again',
        });
        console.error('There was an error!', error);
      });
  };
};

export const deleteCart = () => {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_CART,
    });
  };
};

export const updateProductQuantity = (
  id: any,
  productId: any,
  variantId: any,
  qty: any,
  cartId: any,
  cartUrl: string
) => {
  return async (dispatch: any) => {
    const config = { id, productId, variantId, qty, cartId, cartUrl };
    dispatch({ type: UPDATE_CART_LOADER_STATUS });
    await axios
      .post(`${cartUrl}handleCartItemQuantityChangeAction`, config)
      .then(response => {
        dispatch({
          type: UPDATE_CART,
          payload: response.data.data,
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_CART_ERROR,
          payload: 'Something went wrong please try again',
        });
        console.error('There was an error!', error);
      });
  };
};

export const storeInventoryData = (data: any) => ({
  type: STORE_INVENTORY_DATA,
  payload: data,
});
