/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react';
import StarIcon from 'assets/svg/stargreen.svg';
import { AccordionSlide, InfoPopup, EnquireFormModal } from 'components';
import FitnessIcon from 'assets/svg/weight.svg';
import NutritionIcon from 'assets/svg/apple.svg';
import MindsetIcon from 'assets/svg/stopwatch.svg';
import Enquire from 'assets/svg/enquire.svg';
import Humm from 'assets/svg/humm.svg';

const MobileWeightLossPackage = ({
  weightLossPackage,
  weightLossPackageService,
  // products,
  // weightLossPackageDescription,
  // functionUrl,
  humPayment,
  setActiveTitle,
}: any) => {
  const [active, setActive]: any = useState(0);
  const [InfoModal, setInfoModal] = useState(false);
  const [wlKey, setwlKeys] = useState() as any;
  const weightLossListRef = useRef() as any;

  weightLossListRef.current = [];
  const weightLossListAddTorefs = (el: any) => {
    if (el && !weightLossListRef.current.includes(el as never)) {
      weightLossListRef.current.push(el as never);
    }
  };
  const handleClick = (desc: any) => {
    setInfoModal(true);
    setwlKeys(desc);
  };

  const closeModal = () => {
    setInfoModal(false);
  };
  const wlPackageRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    const packageItem = document.querySelectorAll('.mobile-wl-package-wrapper');
    const ActiveList = weightLossListRef.current.filter((curnt: any) =>
      curnt.classList.contains('active')
    );
    if (ActiveList.length > 0) {
      if (ActiveList[0]) {
        const wlPackageHeight =
          packageItem[1].children[0].clientHeight +
          packageItem[1].children[1].clientHeight +
          220 +
          ActiveList[0].clientHeight;
        wlPackageRef.current.style.height = `${wlPackageHeight}px`;
      }
    }
  }, [wlPackageRef.current]);

  const [WeightlossFormModal, setWeightlossFormModal] = useState(false);
  const [formTitle, setFormTitle] = useState('');

  const formHandler = (form: string) => {
    setWeightlossFormModal(true);
    setFormTitle(form);
  };

  const handleToggle = (item: any) => {
    if (item === active) {
      setActive();
      setTimeout(() => {
        const packageItem = document.querySelectorAll(
          '.mobile-wl-package-wrapper'
        );
        const wlPackageHeight =
          packageItem[0].children[0].clientHeight +
          packageItem[0].children[1].clientHeight +
          250 +
          weightLossListRef.current[0].clientHeight;
        wlPackageRef.current.style.height = `${wlPackageHeight}px`;
        const activeHeaderOffset =
          weightLossListRef.current[0].getBoundingClientRect().top +
          document.documentElement.scrollTop;
        const windowScrolly = window.scrollY;
        if (activeHeaderOffset < windowScrolly) {
          window.scrollBy({
            top: activeHeaderOffset - windowScrolly - 250,
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 700);
    } else {
      setActive(item);
      setTimeout(() => {
        const ActiveList = weightLossListRef.current.filter((curnt: any) =>
          curnt.classList.contains('active')
        );
        const packageItem = document.querySelectorAll(
          '.mobile-wl-package-wrapper'
        );

        if (ActiveList.length > 0) {
          if (ActiveList[0]) {
            const wlPackageHeight =
              packageItem[0].children[0].clientHeight +
              packageItem[0].children[1].clientHeight +
              270 +
              ActiveList[0].clientHeight;

            wlPackageRef.current.style.height = `${wlPackageHeight}px`;
            const activeHeaderOffset =
              ActiveList[0].getBoundingClientRect().top +
              document.documentElement.scrollTop;
            const windowScrolly = window.scrollY;
            if (activeHeaderOffset < windowScrolly) {
              window.scrollBy({
                top: activeHeaderOffset - windowScrolly - 250,
                left: 0,
                behavior: 'smooth',
              });
            }
          }
        }
      }, 200);
    }
  };
  return (
    <>
      <div className="mobile-wl-package" ref={wlPackageRef}>
        <div className="mobile-wl-package-item">
          {weightLossPackage.edges.map((item: any) => {
            const {
              title,
              tagline,
              price,
              id,
              popular,
              weeklyPrice,
              priceInstallmentText,
              hummTotalPrice,
            } = item.node;
            return (
              <div
                className={`mobile-wl-package-wrapper ${
                  popular ? 'active' : ''
                }`}
                key={id}
                onMouseOver={() => setActiveTitle(item.node.title)}
                onFocus={() => setActiveTitle(item.node.title)}
              >
                <div className="mobile-wl-package-content">
                  <div className="mobile-wl-package-popular">
                    {popular && (
                      <>
                        <h4 className="mobile-wl-package-popular-tagline">
                          {tagline}
                        </h4>
                        <StarIcon />
                      </>
                    )}
                    <h3 className="mobile-wl-package-popular-title">{title}</h3>
                    {humPayment && (
                      <>
                        <div className="mobile-wl-package-popular-price">
                          {weeklyPrice}
                          <span> / wk</span>
                        </div>
                        <div className="mobile-wl-package-popular-summary">
                          <p>{priceInstallmentText}</p>
                          <Humm />
                          <p className="mobile-wl-package-popular-total">
                            {hummTotalPrice}
                          </p>
                        </div>
                      </>
                    )}
                    {!humPayment && (
                      <div className="weightloss-plan-product-item-heading-price">
                        {' '}
                        {price}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mobile-wl-package-wrapper-description">
                  {/* {products.edges.map((product: any) => {
                    return (
                      <>
                        {product.node.name === item.node.title && (
                          <AddToCartButton
                            productId={product.node.bigcommerce_id}
                            qty={1}
                            variantId={product.node.variants[0].id}
                            url={functionUrl}
                            isProductOutOfStock={false}
                            butttonLabel={
                              weightLossPackageDescription.purchaseButtonText
                            }
                            digital
                          />
                        )}
                      </>
                    );
                  })} */}
                  <button
                    type="button"
                    className="mobile-wl-package-wrapper-enquire-button"
                    onClick={() => {
                      formHandler(title);
                    }}
                  >
                    <div className="mobile-wl-package-wrapper-enquire-svg">
                      <div className="mobile-wl-package-wrapper-enquire-svg-item">
                        <Enquire />
                      </div>
                    </div>
                    <h5 className="mobile-wl-package-wrapper-enquire-title">
                      Enquire
                    </h5>
                  </button>
                </div>
              </div>
            );
          })}
          <EnquireFormModal
            isEnquireModalOpen={WeightlossFormModal}
            setisEnquireModalOpen={setWeightlossFormModal}
            contactFormModalTitle={formTitle}
            optionalMessage
            dealTitle="weight-loss"
          />
        </div>
        <div className="mobile-wl-package-services">
          {weightLossPackageService.edges.map((content: any, index: number) => {
            const { icon } = content.node;
            let Icon;
            switch (icon) {
              case 'Fitness':
                Icon = <FitnessIcon />;
                break;
              case 'Nutrition':
                Icon = <NutritionIcon />;
                break;
              case 'Bonus':
                Icon = <MindsetIcon />;
                break;
              default:
                Icon = <FitnessIcon />;
                break;
            }
            return (
              <AccordionSlide
                weightlosskeys={content.node}
                weightlossIcons={Icon}
                onToggle={() => handleToggle(index)}
                activeClass={active === index}
                weightLossList={weightLossListRef}
                handleClick={handleClick}
                weightLossListAddTorefs={weightLossListAddTorefs}
              />
            );
          })}
        </div>
      </div>
      <InfoPopup
        InfoModal={InfoModal}
        closeModal={closeModal}
        portalClassName="home-page-quiz-modal"
        weightLossPackageService={weightLossPackageService}
        wlKey={wlKey}
      />
    </>
  );
};

export { MobileWeightLossPackage };
