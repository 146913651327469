import { TOOGLE_NAV } from 'Redux/actionTypes';

type InitialStateType = {
  isNavOpen: boolean;
};

const InitialState: InitialStateType = {
  isNavOpen: false,
};

const NavReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case TOOGLE_NAV:
      return { ...state, isNavOpen: !state.isNavOpen };
    default:
      return state;
  }
};
export { NavReducer };
