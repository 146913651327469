/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import WeightIcon from 'assets/svg/weightIcon.svg';
import Gastrointestinal from 'assets/svg/Gastrointestinal.svg';
import FoodIntolerence from 'assets/svg/FoodIntolerence.svg';
import EatingDisorder from 'assets/svg/eating-disorder.svg';
import HealthConditions from 'assets/svg/HealthConditions.svg';
import WomensHealth from 'assets/svg/WomensHealth.svg';
import { PageHeading, DietitianBookingsModal } from 'components';

const DietitianArea = ({ dieteticsSpecialtyAreas }: any) => {
  const [DietitianModalOpen, setDietitianModalOpen] = useState(false);

  const handleDietitianModalOpen = () => {
    setDietitianModalOpen(true);
  };
  return (
    <>
      <section className="dietitianArea">
        <div className="dietitianArea-wrapper">
          <PageHeading
            title={dieteticsSpecialtyAreas.title}
            subtitle={dieteticsSpecialtyAreas.tagline}
            description={dieteticsSpecialtyAreas.content}
            level={1}
          />
          <div className="dietitian-icons-wrapper">
            {dieteticsSpecialtyAreas.dieteticIcon.map((item: any) => {
              let Icon;
              switch (item.icon) {
                case 'Weight':
                  Icon = WeightIcon;
                  break;
                case 'Food_Intolerances':
                  Icon = FoodIntolerence;
                  break;
                case 'Gastrointestinal':
                  Icon = Gastrointestinal;
                  break;
                case 'Eating_Disorder':
                  Icon = EatingDisorder;
                  break;
                case 'Health_Conditions':
                  Icon = HealthConditions;
                  break;
                case 'Womens_Health':
                  Icon = WomensHealth;
                  break;
                default:
                  Icon = WeightIcon;
                  break;
              }
              return (
                <>
                  <div className="diet-icon-wrapper">
                    <div className="diet-icon">
                      <span className="diet-icon-list">
                        {' '}
                        <Icon />
                      </span>
                    </div>
                    <div className="diet-text">{item.title}</div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="btn-link-wrapper">
          <button
            className="btn-diet-book"
            type="button"
            onClick={handleDietitianModalOpen}
          >
            <span>{dieteticsSpecialtyAreas.dietitianButtonText}</span>
          </button>
        </div>
        <DietitianBookingsModal
          DietitianModalOpen={DietitianModalOpen}
          setDietitianModalOpen={setDietitianModalOpen}
          link={dieteticsSpecialtyAreas.dietitianLink}
        />
      </section>
    </>
  );
};

export { DietitianArea };
