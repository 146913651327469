import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ThumbnailTextComponentPropsType } from 'types';
import RightArrowIcon from 'assets/svg/arrow-next.svg';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

const ThumbnailText: FC<ThumbnailTextComponentPropsType> = ({
  services,
  servicePageSlug,
  parentSlug,
}) => {
  return (
    <>
      {services.length > 0 ? (
        <section className="thumbnail-section">
          <div className="thumbnail-section-list">
            {services.map(service => {
              const { id, title, content, image, servicePage } = service;
              const slug = servicePage?.slug;

              const ThumbnailUrl =
                servicePage?.pageType === 'rootServicePage'
                  ? `/${slug}`
                  : slug && `/${parentSlug}/${servicePageSlug}/${slug}`;
              return (
                <div className="thumbnail-section-list-item" key={id}>
                  <div className="thumbnail-section-list-item-image">
                    {image?.localFile?.childImageSharp?.gatsbyImageData && (
                      <GatsbyImage
                        image={
                          image?.localFile?.childImageSharp?.gatsbyImageData
                        }
                        alt={image?.alternativeText ?? title}
                      />
                    )}
                  </div>
                  <div className="thumbnail-section-list-item-content">
                    <div className="thumbnail-section-item">
                      <h3>
                        {ThumbnailUrl ? (
                          <Link to={ThumbnailUrl}>
                            <span> {title}</span>
                          </Link>
                        ) : (
                          <span> {title}</span>
                        )}
                      </h3>
                      <ReactMarkdown className="md-html">
                        {content}
                      </ReactMarkdown>
                      {ThumbnailUrl && (
                        <Link to={ThumbnailUrl}>
                          <span>Read More</span>{' '}
                          <span>
                            <RightArrowIcon />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      ) : null}
    </>
  );
};

export { ThumbnailText };
