import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { McqQuestion, TextareaQuestions, CareerFormQuiz } from 'components';
import careerpagequiz from './careerquiz.json';

export const CareerPageQuiz: FC = ({
  isQuizModalOpen,
  portalClassName,
  handleModalClose,
  currentQuestion,
  showNextQuestion,
  submitedQuestions,
  previewAnswer,
  showPrevQuestion,
  showcareerform,
  resetQuiz,
  updateCarrerPageQuizForm,
}: any) => {
  const dispatch = useDispatch();
  const currentQuizItem = careerpagequiz.careerquiz[currentQuestion - 1];
  // form state
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div>
      <ReactModal
        closeTimeoutMS={100}
        isOpen={isQuizModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName={`ReactModalPortal ${portalClassName}`}
      >
        <div className="quiz-question-item-wrapper">
          <div className="quiz-question-item-detail">
            <button
              type="button"
              onClick={() => handleModalClose()}
              className="modal-close"
            >
              <span>&#x2715;</span>
            </button>
            {currentQuestion - 1 > 0 && !showSuccess && (
              <button
                type="button"
                className="quiz-question-item-back"
                onClick={() => dispatch(showPrevQuestion())}
              >
                Back
              </button>
            )}
            {showcareerform && !showSuccess && (
              <button
                type="button"
                className="quiz-question-item-back"
                onClick={() => dispatch(updateCarrerPageQuizForm())}
              >
                Back
              </button>
            )}
            {!showcareerform &&
              currentQuizItem.node.questionType ===
                'multipleChoiceQuestion' && (
                <McqQuestion
                  Question={currentQuizItem}
                  showNextQuestion={showNextQuestion}
                  submitedQuestions={submitedQuestions}
                  currentquestion={currentQuestion}
                  previewAnswer={previewAnswer}
                  QuestionsListLength={careerpagequiz.careerquiz.length}
                />
              )}
            {!showcareerform &&
              currentQuizItem.node.questionType === 'textareaQuestion' && (
                <TextareaQuestions
                  Question={currentQuizItem}
                  showNextQuestion={showNextQuestion}
                  submitedQuestions={submitedQuestions}
                  currentquestion={currentQuestion}
                  previewAnswer={previewAnswer}
                  QuestionsListLength={careerpagequiz.careerquiz.length}
                />
              )}
            {showcareerform && (
              <CareerFormQuiz
                submitedQuestions={submitedQuestions}
                resetQuiz={resetQuiz}
                handleModalClose={handleModalClose}
                formSuccessMessage={careerpagequiz.formSuccessMessage}
                formSuccessTitle={careerpagequiz.formSuccessTitle}
                showSuccess={showSuccess}
                setShowSuccess={setShowSuccess}
              />
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
