import React, { FC } from 'react';
import MailIcon from 'assets/svg/MailIcon.svg';
import CallIcon from 'assets/svg/CallIcon.svg';
import { ContactDetailsComponentPropTypes } from 'types';

const ContactDetails: FC<ContactDetailsComponentPropTypes> = ({
  email,
  phone: { phoneText, phoneNumber },
}) => {
  return (
    <ul className="contact-detail-cta">
      <li className="contact-detail-mail">
        <MailIcon />
        <a href={`mailto:${email}`}>{email}</a>
      </li>
      <li className="contact-detail-call">
        <CallIcon /> <a href={`tel:${phoneNumber}`}>{phoneText}</a>
      </li>
    </ul>
  );
};
export { ContactDetails };
