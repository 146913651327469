import React, { FC } from 'react';
import Star from 'assets/svg/star.svg';

const StarRating: FC = ({ rating }: any) => {
  return (
    <div className="star-rating">
      {[...Array(rating)].map(() => {
        return <Star key={Math.random()} />;
      })}
    </div>
  );
};

export { StarRating };
