/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';
import Slider from 'react-slick';
import { PageHeading, RenderVideoAndImage } from 'components';
import ReactMarkdown from 'react-markdown';
import { SuccessStoriesComponentPropsType } from 'types';

const SuccessStories: FC<SuccessStoriesComponentPropsType> = ({
  title,
  tagline,
  sucessStorySlides,
  successStoryResultSliderTitle,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // Todo condition need to be passed as props
  const VideoGallery: any = [];

  useEffect(() => {
    sucessStorySlides.forEach(element => {
      VideoGallery.push(element.videoImage.video?.localFile.publicURL);
    });
  }, [VideoGallery]);

  return (
    <section className="successstories">
      <PageHeading title={title} subtitle={tagline} />
      <div className="successstories_list">
        <Slider {...settings}>
          {sucessStorySlides.map(story => {
            if (story) {
              const { id, name, content, highlight } = story;
              return (
                <div className="successstories_item" key={id}>
                  <div className="successstories_heading">
                    <p className="successstories_title">
                      {successStoryResultSliderTitle}
                    </p>
                    <p className="successstories_name">{name}</p>
                  </div>
                  <div className="successstories_wrapper">
                    <div className="successstories_img">
                      <RenderVideoAndImage
                        data={story.videoImage}
                        altText={story.name}
                        hasPlayButton
                        buttontype="icon"
                        buttonsize="medium"
                        videogallery={VideoGallery}
                        showModal
                        showControls
                      />
                    </div>
                    <div className="successstories_content">
                      <div className="successstories_highlights">
                        {highlight}
                      </div>
                      <div className="successstories_summary">
                        <ReactMarkdown className="md-html">
                          {content}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </Slider>
      </div>
    </section>
  );
};
export { SuccessStories };
