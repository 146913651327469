/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { FaqComponentPropsType, FaqType } from 'types';
import ReactMarkdown from 'react-markdown';

const Style = {
  height: 0,
} as React.CSSProperties;

const FAQ: FC<FaqComponentPropsType> = ({ Faqs }) => {
  const [FaqColumns, setFaqColumns]: any[] = useState([]);

  useEffect(() => {
    const FaqColumnCount = window.innerWidth < 768 ? 1 : 2;
    const FaqColumnArray: Array<FaqType[]> =
      FaqColumnCount === 2 ? [[], []] : [[]];
    Faqs.forEach((element: FaqType, i: number) => {
      // eslint-disable-next-line default-case
      switch (i % FaqColumnCount) {
        case 0:
          FaqColumnArray[0].push(element);
          break;
        case 1:
          FaqColumnArray[1].push(element);
          break;
      }
    });
    setFaqColumns(FaqColumnArray);
    setTimeout(() => {
      const faqItem = document.getElementsByClassName('faq-section-wrapper');
      const faqTitle = document.getElementsByClassName('faq-section-title');

      const removeActiveClass = () => {
        for (let i = 0; i < faqItem.length; i++) {
          faqItem[i].classList.remove('active');
          faqItem[i].children[1].style.height = '0px';
        }
      };

      for (let i = 0; i < faqTitle.length; i++) {
        faqTitle[i].addEventListener('click', () => {
          const isFaqActive = !faqItem[i].classList.contains('active');
          removeActiveClass();
          if (isFaqActive) {
            faqItem[i].classList.toggle('active');
            const height =
              faqItem[i].children[1].children[0].getBoundingClientRect()
                .height + 20;

            faqItem[i].children[1].style.height = `${height}px`;
          }
        });
      }
    }, 1500);
  }, []);
  return (
    <div className="faq">
      <div className="faq-section">
        <div className="faq-section-content" />
        <div className="faq-section-item">
          {FaqColumns.map((FaqColumn: FaqType[], index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="faq-section-item-list" key={index}>
                {FaqColumn.map((item: FaqType) => {
                  const { id, title: question, answer } = item;
                  return (
                    <div className="faq-section-wrapper" key={id}>
                      <button type="button" className="faq-section-title">
                        <div className="toggle_icon" />
                        <p className="faq-section-title-question">{question}</p>
                      </button>
                      <div className="faq-section-description" style={Style}>
                        <div className="faq-section-answer">
                          <ReactMarkdown className="md-html">
                            {answer}
                          </ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export { FAQ };
