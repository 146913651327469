/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SocialShare, BreadCrumbs } from 'components';
import { useLocation } from '@reach/router';
import { getFormatedDate } from 'utils';

interface BlogDetailProps {
  BlogDetail: {
    title: string;
    description: string;
    content: any;
    slug: string;
    authorName: string;
    date: string;
    image?: {
      alternativeText?: string;
      localFile?: {
        publicURL?: string;
        childImageSharp?: {
          gatsbyImageData?: {
            layout?: string;
            backgroundColor?: string;
            height?: number;
            width?: number;
            images?: {
              fallback?: {
                sizes?: string;
                src?: string;
                srcSet?: string;
              };
              sources?: {
                sizes?: string;
                srcSet?: string;
                type?: string;
              };
            };
          };
        };
      };
    };
    video?: {
      localFile?: {
        publicURL: string;
      };
    };
    article_tags?: {
      title?: string;
    }[];
  };
  blogUrl: string;
  breadCrumb?: { title: string; slug: string }[];
}
const BlogDetails: FC<BlogDetailProps> = ({
  BlogDetail,
  blogUrl,
  breadCrumb,
  children,
}) => {
  const {
    title,
    description,
    content,
    image,
    article_tags,
    authorName,
    date,
    video,
  } = BlogDetail;
  const blogImage: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const { pathname } = useLocation();
  return (
    <section>
      <BreadCrumbs pathname={pathname} breadCrumb={breadCrumb} />
      <div className="blog_details">
        <div className="blog_details_heading">
          <h1>{title}</h1>
          <div className="blog_details-author-date-wrapper">
            {authorName && (
              <span>
                by <span className="value">{authorName}</span>
              </span>
            )}
            {date && (
              <span>
                on <span className="value">{getFormatedDate(date)}</span>
              </span>
            )}
          </div>
        </div>
        <div className="blog_details_image">
          <GatsbyImage
            image={blogImage}
            alt={(image?.alternativeText && title) || ''}
          />
          <div className="blog_details_shares">
            <SocialShare title={title} image={image?.localFile?.publicURL} />
            <ul className="blog_details_btn">
              {article_tags
                ? article_tags.map((item: any) => {
                    return (
                      <li key={item.title}>
                        <Link to={`/${blogUrl}?filter=${item.title}`}>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          {video?.localFile?.publicURL && (
            <video loop playsInline muted autoPlay controls>
              <source src={video?.localFile?.publicURL} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="blog_details_wrapper">
          <p className="blog_details_summary">{description}</p>
          <div className="blog_details_content">
            <ReactMarkdown className="md-html">{content}</ReactMarkdown>
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};

export { BlogDetails };
