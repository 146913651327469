/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { RenderVideoAndImage } from 'components';
import ReactMarkdown from 'react-markdown';

const MemberStories: FC = ({ memberstories, cardsToDisplay }: any) => {
  const [CardsColumns, setsCardsColumns]: any[] = useState([]);
  useEffect(() => {
    const cardsListinDesktop = window.innerWidth < 1200 ? 2 : 3;
    const cardsColumnCount = window.innerWidth < 576 ? 1 : cardsListinDesktop;

    const cardsColumnArray: Array<any> =
      cardsColumnCount === 3
        ? [[], [], []]
        : cardsColumnCount === 2
        ? [[], []]
        : [[]];

    memberstories.edges
      .slice(0, cardsToDisplay)
      .forEach((element: any, i: number) => {
        switch (i % cardsColumnCount) {
          case 0:
            cardsColumnArray[0].push(element);
            break;
          case 1:
            cardsColumnArray[1].push(element);
            break;
          case 2:
            cardsColumnArray[2].push(element);
            break;
        }
      });
    setsCardsColumns(cardsColumnArray);
  }, [memberstories.edges, cardsToDisplay]);

  return (
    <section className="stories-list">
      {memberstories.edges.length === 0
        ? 'No Result Found'
        : CardsColumns.map((CardColumn: any) => {
            return (
              <div
                className="stories-item-list"
                key={`${CardColumn}${Math.random()}`}
              >
                {CardColumn.map((story: any) => {
                  const {
                    id,
                    title,
                    description,
                    weightLoss,
                    videoimage,
                    starRating,
                    clientDetails,
                  } = story.node;
                  const VideoImage: any = [];
                  if (videoimage) {
                    VideoImage.push(videoimage.video?.localFile.publicURL);
                  }
                  const starTotal = 5;
                  let starPercentageRounded = null;
                  const starPercentage = (starRating * 100) / starTotal;
                  starPercentageRounded = `${(starPercentage / 10) * 10}%`;

                  return (
                    <div className="stories-item" key={id}>
                      <div className="stories-item-wrapper">
                        <div className="stories-item-image">
                          {weightLoss ? (
                            <div className="stories-item-timming">
                              <span className="stories-item-timming-value">
                                {weightLoss}
                                <span className="stories-item-timming-label">
                                  KG
                                </span>
                              </span>
                            </div>
                          ) : (
                            starRating && (
                              <div className="stories-item-timming">
                                <span className="stories-item-timming-value">
                                  {starRating}
                                  <div className="stars-outer">
                                    <div
                                      className="stars-inner"
                                      style={{ width: starPercentageRounded }}
                                    />
                                  </div>
                                </span>
                              </div>
                            )
                          )}

                          <RenderVideoAndImage
                            data={videoimage}
                            altText={title}
                            hasPlayButton
                            hasBorder
                            buttontype="icon"
                            buttonsize="small"
                            videogallery={VideoImage}
                            showControls
                            showModal
                            showModalforMobile
                          />
                        </div>
                        <div className="stories-item-content">
                          <h4 className="stories-item-title">{title}</h4>
                          {clientDetails && (
                            <h2 className="stories-item-name">
                              {clientDetails}
                            </h2>
                          )}
                          {description && (
                            <ReactMarkdown className="md-html">
                              {description}
                            </ReactMarkdown>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
    </section>
  );
};
export { MemberStories };
