import React, { FC } from 'react';
import RightArrow from 'assets/svg/arrow-right.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ThumbnailsTitleComponentPropsType } from 'types';
import { Link } from 'gatsby';
import { getFromAndToTime } from 'utils';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

// Todo add condition to check if day changes (events from and to time)
const ThumbnailsTitle: FC<ThumbnailsTitleComponentPropsType> = ({
  events,
  eventType,
  communityPageSlug,
  showCompetition,
}) => {
  const checkdate = (From: string, To: string) => {
    const currentdate = new Date();
    const from = moment(From);
    const to = moment(To);
    const cdate = moment(currentdate);
    if (from <= cdate && to >= cdate) {
      return true;
    }
    return false;
  };
  return (
    <section className="thumbnailstitle_section">
      <div className="thumbnailstitle-item">
        {eventType.map(item => {
          const { id, title, content } = item;
          return (
            <div className="thumbnailstitle-item-event" key={id}>
              <div className="thumbnailstitle-item-event-heading">{title}</div>
              <div className="thumbnailstitle-item-event-description">
                <ReactMarkdown className="md-html">{content}</ReactMarkdown>
              </div>
            </div>
          );
        })}
      </div>

      <div className="thumbnailstitle-content">
        {events.length > 0
          ? events.map(event => {
              const {
                id,
                title,
                images,
                summary,
                FromTime,
                ToTime,
                showLiveButton,
                ctaLabel,
                ctaLink,
                slug,
              } = event;
              const ThumbnailImage =
                images && images[0]
                  ? getImage(
                      images[0].localFile.childImageSharp.gatsbyImageData
                    )
                  : null;
              if (id != null) {
                return (
                  <>
                    {showCompetition || slug !== 'competition' ? (
                      <div className="thumbnailstitle-content-list" key={id}>
                        <div className="thumbnailstitle-content-image">
                          <div className="thumbnailstitle-content-image-item">
                            <div className="thumbnailstitle-content-image-item-image">
                              {ThumbnailImage && (
                                <Link to={`/${communityPageSlug}/${slug}`}>
                                  {' '}
                                  <GatsbyImage
                                    image={ThumbnailImage}
                                    alt={images[0]?.alternativeText ?? ''}
                                  />{' '}
                                </Link>
                              )}
                            </div>
                            <div className="thumbnailstitle-content-image-item-event">
                              <Link to={`/${communityPageSlug}/${slug}`}>
                                {' '}
                                <h4 className="thumbnailstitle-content-image-item-event-heading">
                                  {' '}
                                  {title}{' '}
                                </h4>
                              </Link>
                              <p className="thumbnailstitle-content-image-item-event-list">
                                {summary ?? getFromAndToTime(FromTime, ToTime)}
                              </p>
                              {checkdate(FromTime, ToTime) && showLiveButton ? (
                                <div className="thumbnailstitle-content-image-item-event-watch">
                                  <Link to={`${ctaLink} ?`}>{ctaLabel}</Link>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="thumbnailstitle-content-image-svg">
                            <Link to={`/${communityPageSlug}/${slug}`}>
                              <RightArrow />
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              }
              return false;
            })
          : null}
      </div>
    </section>
  );
};
export { ThumbnailsTitle };
