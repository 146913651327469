/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Humm from 'assets/svg/humm.svg';
import MasterCard from 'assets/svg/mastercard.svg';
import Visa from 'assets/svg/visa.svg';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const PaymentSection = ({
  humPayment,
  setHumPayment,
  challengeTermText,
  logoImage,
}: any) => {
  const logImg: any = logoImage
    ? getImage(logoImage?.localFile?.childImageSharp?.gatsbyImageData)
    : null;
  return (
    <div className="paymentSection-wrapper">
      <div className="paymentSection-Icons-wrapper">
        <p className="paymentSection-caption">Flexible Payment Options</p>
        <div className="paymentSection-Icons-container">
          <div className="paymentSection-Icons-container-inner">
            <p className="humm_option">Pay later with</p>
            <Humm className="humm_icon" />
          </div>
          <label className="switch" htmlFor="payment_option">
            <input
              type="checkbox"
              name="payment_option"
              id="payment_option"
              onChange={() => setHumPayment(!humPayment)}
            />
            <span className="slider" />
          </label>
          <div className="paymentSection-other-payments">
            <p>Upfront with </p>
            <div className="paymentSection-other-payments-inner">
              <MasterCard />
              <Visa />
            </div>
          </div>
        </div>
      </div>
      {logImg ? (
        <div className="paymentSection-wrapper-logo-image">
          <GatsbyImage image={logImg} alt="logo" />
        </div>
      ) : (
        <p className="paymentSection-wrapper-challange-term">
          {challengeTermText}
        </p>
      )}
      {/* <div className="paymentSection-plan-wrapper">
        <p className="paymentSection-caption">Challenge Terms</p>
        <div className="paymentSection-plan-container">
          <p>8 weeks only</p>
        </div>
      </div> */}
    </div>
  );
};

export { PaymentSection };
