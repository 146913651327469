import React, { FC } from 'react';
import { PageHeading, RenderVideoAndImage } from 'components';
import ReactMarkdown from 'react-markdown';
import { OurTeamComponentPropsType } from 'types';

const OurTeam: FC<OurTeamComponentPropsType> = ({ TeamList }) => {
  return (
    <section className="our-team_section">
      {TeamList.map(team => {
        const { id, title, content, videoImage } = team;
        return (
          <div className="our-team" key={id}>
            <PageHeading title={title} />
            <div className="our-team-wrapper">
              <div className="our-team-image">
                <RenderVideoAndImage data={videoImage} altText={title} />
              </div>
              <div className="our-team-content">
                <ReactMarkdown className="md-html">{content}</ReactMarkdown>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export { OurTeam };
