/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { FaqType } from 'types';

interface SeoProps {
  description?: string;
  lang?: string;
  title: string;
  pageClassName?: string;
  // type?: string;
  // keywords?: string;
  image?: any;
  faq?: any;
  productImage?: boolean;
  product?: {
    id?: string;
    price?: {
      currency?: string;
      amount?: string;
    };
    condition?: string;
    availability?: string;
  };
}

const Seo: FC<SeoProps> = ({
  description,
  lang = 'en',
  title,
  image,
  productImage = false,
  product,
  pageClassName,
  faq,
}) => {
  const { pathname } = useLocation();
  return (
    <StaticQuery
      query={seoDetailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata: {
              defaultTitle,
              siteUrl,
              googleSiteVerification,
              faceBookAppId,
              twitterusername,
              isDevEnvironment,
            },
          },
          strapiGlobal: {
            defaultSeo: {
              metaTitle: defaultMetaTitle,
              metaDescription: defaultMetaDescription,
              shareImage: {
                localFile: { publicURL: shareImageURL },
              },
            },
          },
        } = data;

        const seoTitle = title || defaultMetaTitle;
        const seoDescription = description || defaultMetaDescription;
        const metaUrl = `${siteUrl}${pathname}`;
        const metaImage = productImage
          ? image && `${image}`
          : (image && `${siteUrl}${image}`) ??
            (shareImageURL && `${siteUrl}${shareImageURL}`);
        const canonical = pathname ? `${siteUrl}${pathname}` : null;
        const googleVerificationId = googleSiteVerification;
        const twitterUsername = twitterusername || '';
        const fbAppId = faceBookAppId || '';
        const isDev = isDevEnvironment;
        const formattedFaq = faq
          ? {
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: faq.map((item: FaqType) => {
                return {
                  '@type': 'Question',
                  name: `${item.title}`,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: `${item.answer}`,
                  },
                };
              }),
            }
          : null;

        return (
          <Helmet
            bodyAttributes={{
              class: pageClassName,
            }}
            htmlAttributes={{
              lang,
            }}
            title={seoTitle}
            titleTemplate={`%s | ${defaultTitle}`}
            link={
              canonical
                ? [
                    {
                      rel: 'canonical',
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: 'description',
                content: seoDescription,
              },
              {
                name: 'image',
                content: metaImage,
              },
              {
                name: 'og:image:width',
                content: 200,
              },
              {
                name: 'og:image:height',
                content: 150,
              },
              {
                property: 'og:title',
                content: seoTitle,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: 'og:description',
                content: seoDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: twitterUsername,
              },
              {
                name: 'twitter:title',
                content: seoTitle,
              },
              {
                name: 'twitter:description',
                content: seoDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
              {
                name: 'google-site-verification',
                content: googleVerificationId,
              },
              {
                name: 'fb:app_id',
                content: fbAppId,
              },
            ]
              .concat(
                metaImage
                  ? [
                      {
                        property: `og:image`,
                        content: metaImage,
                      },
                      {
                        property: `og:image:alt`,
                        content: seoTitle,
                      },
                    ]
                  : []
              )
              .concat(
                product?.price?.currency
                  ? [
                      {
                        property: `product:price:currency`,
                        content: product.price.currency,
                      },
                    ]
                  : []
              )
              .concat(
                product?.price?.amount
                  ? [
                      {
                        property: `product:price:amount`,
                        content: product.price.amount,
                      },
                    ]
                  : []
              )
              .concat(
                product?.condition
                  ? [
                      {
                        property: `product:condition`,
                        content: product.condition,
                      },
                    ]
                  : []
              )
              .concat(
                product?.id
                  ? [
                      {
                        property: `product:retailer_item_id`,
                        content: product.id,
                      },
                    ]
                  : []
              )
              .concat(
                product?.availability
                  ? [
                      {
                        property: `product:availability`,
                        content: product.availability,
                      },
                    ]
                  : []
              )
              .concat(
                isDev
                  ? [
                      {
                        property: `robots`,
                        content: `noindex`,
                      },
                    ]
                  : []
              )}
          >
            {formattedFaq && (
              <script type="application/ld+json">
                {JSON.stringify(formattedFaq)}
              </script>
            )}
          </Helmet>
        );
      }}
    />
  );
};

export { Seo };

const seoDetailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        siteUrl
        defaultDescription: description
        author
        googleSiteVerification
        twitterusername: twitterUsername
        defaultImage: image
        faceBookAppId
        isDevEnvironment
      }
    }
    strapiGlobal {
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
