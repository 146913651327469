import React from 'react';
import { MobileHowItWorksItems } from 'components';
import CircleMobile from 'assets/svg/Circle-mobile.svg';
import UserIcon from 'assets/svg/User-icon.svg';

const MobileViewHowItWorks = ({
  MobileHowItWorks,
  ctaButtonText,
  scrollPlan,
}: any) => {
  return (
    <>
      <section className="mobilehowitworks-wrapper">
        <div className="mobilehowitworks-background">
          <CircleMobile />
          <div className="mobilehowitworks-circle">
            <div className="mobilehowitworks-circle-details">
              <UserIcon />
              <h3>{MobileHowItWorks.weightLossJourneyTitle}</h3>
            </div>
          </div>
          <ul className="mobilehowitworks-section-wrapper">
            {MobileHowItWorks.weightLossJourney.map(
              (item: any, index: number) => {
                return <MobileHowItWorksItems index={index} item={item} />;
              }
            )}
          </ul>
        </div>
        {ctaButtonText && (
          <div className="how-it-works-cta-btn-container">
            <button
              type="button"
              className="how-it-works-cta-btn"
              onClick={() => scrollPlan()}
            >
              <span>{ctaButtonText}</span>
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export { MobileViewHowItWorks };
