/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import StarRating from 'assets/svg/starRating.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const ReviewsAndRatings = ({ data }: any) => {
  const swiperRef = React.useRef();
  const [starPercentageRounded, setPercentageRounded] = React.useState('100%');

  const starTotal = 5;

  const handleMouseEnter = (index: number) => {
    swiperRef.current.slideTo(index + 2, 2000);
    const Percentage = (data?.reviewPlatform[index].rating * 100) / starTotal;
    const PercentageRounded = `${(Percentage / 10) * 10}%`;
    setPercentageRounded(PercentageRounded);
  };

  const handleMouseLeave = () => {
    setPercentageRounded('100%');
    swiperRef.current.slideTo(1, 2000);
  };
  const totalReview = data?.reviewPlatform?.reduce(
    (accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.review, 10);
    },
    0
  );

  return (
    <section className="reviews-and-ratings">
      <div className="container">
        <div className="reviews-and-ratings-wrapper">
          <h6 className="reviews-and-ratings-title">{data?.title}</h6>
          <Swiper
            onSwiper={swiper => {
              swiperRef.current = swiper;
            }}
            speed={2000}
            allowTouchMove={false}
            direction="vertical"
            loop
          >
            <SwiperSlide>
              <div className="reviews-and-ratings-content">
                <span className="reviews-and-ratings-content-review-count">
                  {parseInt(totalReview, 10).toLocaleString()}
                </span>{' '}
                <span className="reviews-and-ratings-content-star">
                  {starTotal} <StarRating />
                </span>
                <span className="">reviews</span>
              </div>
            </SwiperSlide>
            {data?.reviewPlatform?.map((platform: any) => {
              return (
                <SwiperSlide>
                  <div className="reviews-and-ratings-content">
                    <span className="reviews-and-ratings-content-review-count">
                      {parseInt(platform.review, 10).toLocaleString()}
                    </span>{' '}
                    <span className="reviews-and-ratings-content-star">
                      {platform.rating} <StarRating />
                    </span>
                    <span className="">reviews on {platform.title}</span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="reviews-and-ratings-stars-outer">
            <div
              className="reviews-and-ratings-stars-inner"
              style={{ width: starPercentageRounded }}
            />
          </div>
          <ul className="reviews-and-ratings-platform-list">
            {data?.reviewPlatform?.map((platform: any, i: number) => {
              return (
                <li
                  key={platform?.id}
                  className="reviews-and-ratings-platform-list-item"
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                >
                  <a
                    href={platform?.link}
                    aria-label={platform?.title}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {platform?.icon?.localFile?.childImageSharp
                      ?.gatsbyImageData && (
                      <GatsbyImage
                        image={
                          platform?.icon?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        }
                        alt={platform?.icon?.alternativeText ?? platform?.title}
                      />
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};
