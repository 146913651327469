/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-undef */
import React, { FC, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useStaticQuery, graphql, Link } from 'gatsby';

export const HomePopUp: FC = () => {
  const [isPopUpOpen, setisPopUpOpen] = useState(false);
  const handleModalClose = () => {
    setisPopUpOpen(false);
    sessionStorage.setItem('ShowPopUp', 'false');
  };
  const data = PopUpQuery();
  const competion = data.strapiHomePage.CompetitionPopup;

  useEffect(() => {
    const storagevalue = window.sessionStorage.getItem('ShowPopUp');
    if (!storagevalue) {
      setTimeout(() => {
        setisPopUpOpen(true);
      }, 500);
    } else {
      setisPopUpOpen(false);
    }
  }, []);
  useEffect(() => {
    // eslint-disable-next-line func-names
    window.addEventListener('click', function (event) {
      const image = document.querySelector('.popup-image');
      if (event.target !== image) {
        handleModalClose();
      }
    });
  }, [isPopUpOpen]);

  return (
    <div>
      {competion.showPopUp && (
        <ReactModal
          closeTimeoutMS={100}
          isOpen={isPopUpOpen}
          onRequestClose={handleModalClose}
          ariaHideApp={false}
          portalClassName="ReactModalPortal popupmodal"
        >
          <div className="popup-info">
            <button
              type="button"
              onClick={() => handleModalClose()}
              className="modal-close"
            >
              <span>&#x2715;</span>
            </button>
            <div className="popup-image">
              <Link to={competion.imageLink}>
                <img
                  src={competion.image.localFile.publicURL}
                  alt="Competition"
                />
              </Link>
            </div>
          </div>
        </ReactModal>
      )}
    </div>
  );
};

const PopUpQuery = () => {
  return useStaticQuery(graphql`
    query PopUpQuery {
      strapiHomePage {
        CompetitionPopup {
          image {
            localFile {
              publicURL
            }
          }
          imageLink
          showPopUp
        }
      }
    }
  `);
};
