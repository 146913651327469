import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import Heart from 'assets/svg/heart.svg';
import {
  NDISConditionComponentPropType,
  NDISCategoryType,
  NDISConditionType,
} from 'types';

export const NDISCondition = ({
  data,
  list,
  categories,
  selectedCategory,
  parentSlug,
}: NDISConditionComponentPropType) => {
  const [FilterValue, setFilterValue] = useState(selectedCategory);
  return (
    <div className="ndis-condition-section">
      <div className="ndis-condition white-gradient-background">
        {data?.title && (
          <h3 className="ndis-condition-heading">{data.title}</h3>
        )}
        {data?.content && (
          <ReactMarkdown className="ndis-condition-content -md-html">
            {data.content}
          </ReactMarkdown>
        )}
        <div className="ndis-condition-wrapper desktop">
          {categories?.edges?.length > 0 &&
            categories.edges.map((category: NDISCategoryType) => {
              const { title: filterTitle, id } = category.node;
              return (
                <ul
                  className={`ndis-condition-lists ${
                    FilterValue === filterTitle ? 'active' : ''
                  }`}
                >
                  <li
                    className={`ndis-condition-filter-item ${
                      FilterValue === filterTitle ? 'active' : ''
                    }`}
                    key={id}
                  >
                    <button
                      type="button"
                      onClick={() => setFilterValue(filterTitle)}
                    >
                      {filterTitle}
                    </button>
                  </li>
                  {list?.edges?.length > 0 &&
                    list.edges.map((listItem: NDISConditionType) => {
                      if (
                        category.node.title ===
                          listItem.node.ndis_category.title &&
                        listItem?.node?.name
                      ) {
                        return (
                          <li
                            key={listItem.node.id}
                            className="ndis-condition-list-item"
                          >
                            <Heart />
                            <Link to={`/${parentSlug}/${listItem.node.slug}`}>
                              {listItem?.node?.name}
                            </Link>
                          </li>
                        );
                      }
                      return <></>;
                    })}
                </ul>
              );
            })}
        </div>
        <div className="ndis-condition-wrapper mobile">
          <ul className="ndis-condition-filters">
            {categories?.edges?.length > 0 &&
              categories.edges.map((category: NDISCategoryType) => {
                const { title: filterTitle, id } = category.node;
                return (
                  <li
                    className={`ndis-condition-filter-item ${
                      FilterValue === filterTitle ? 'active' : ''
                    }`}
                    key={id}
                  >
                    <button
                      type="button"
                      onClick={() => setFilterValue(filterTitle)}
                    >
                      {filterTitle}
                    </button>
                  </li>
                );
              })}
          </ul>
          <div className="ndis-condition-list-wrapper">
            {categories?.edges?.length > 0 &&
              categories.edges.map((category: NDISCategoryType) => {
                return (
                  <ul className="ndis-condition-lists">
                    {list?.edges?.length > 0 &&
                      list.edges.map((listItem: NDISConditionType) => {
                        if (
                          category.node.title ===
                            listItem.node.ndis_category.title &&
                          FilterValue === category.node.title
                        ) {
                          return (
                            <li
                              key={listItem.node.id}
                              className="ndis-condition-list-item"
                            >
                              <Heart />
                              <Link to={`/${parentSlug}/${listItem.node.slug}`}>
                                {listItem.node.name}
                              </Link>
                            </li>
                          );
                        }
                        return <></>;
                      })}
                  </ul>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
