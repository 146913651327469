import React, { FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ServicesComponentPropsType } from 'types';
import FitnessIcon from 'assets/svg/weight.svg';
import NutritionIcon from 'assets/svg/apple.svg';
import MindsetIcon from 'assets/svg/stopwatch.svg';
import { SlideIn } from 'components/animations';

const Services: FC<ServicesComponentPropsType> = ({
  services,
  servicesPageSlug,
}) => {
  return (
    <section className="services">
      {services.map((serviceItem, index) => {
        if (!serviceItem.promo) {
          return null;
        }
        const { id, title, content, image, icon } = serviceItem.promo;

        let Icon;
        switch (icon) {
          case 'fitness':
            Icon = <FitnessIcon />;
            break;
          case 'nutrition':
            Icon = <NutritionIcon />;
            break;
          case 'mindset':
            Icon = <MindsetIcon />;
            break;
          default:
            Icon = <FitnessIcon />;
            break;
        }
        // check if type is rootservicepage like in ndis
        const rootSlug =
          serviceItem.pageType === 'servicePage' ? `/${servicesPageSlug}` : '';
        const ServiceUrl = serviceItem.slug
          ? `${rootSlug}/${serviceItem.slug}`
          : '';
        const ServiceImage =
          image && image.localFile
            ? getImage(image.localFile.childImageSharp.gatsbyImageData)
            : null;
        const direction = (index + 1) % 2 === 0 ? 'right' : 'left';
        return (
          <div className="services_service" key={id}>
            <SlideIn direction={direction}>
              <svg
                viewBox="0 0 761 1540"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="service-mobile-svg"
              >
                <defs>
                  <linearGradient id="orng-m" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#FFD188" />
                    <stop offset="100%" stopColor="#fff" />
                  </linearGradient>
                  <linearGradient id="grn-m" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#A6EC1B" />
                    <stop offset="100%" stopColor="#fff" />
                  </linearGradient>
                  <linearGradient id="prpl-m" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#D447D2" />
                    <stop offset="100%" stopColor="#fff" />
                  </linearGradient>
                </defs>
                <g
                  id="mobile"
                  transform="translate(380.500000, 761.000000) rotate(90.000000) translate(-380.500000, -761.000000) translate(-375.000000, 386.000000)"
                  stroke="#000000"
                  strokeWidth="10"
                  fill="none"
                >
                  <rect
                    id="verticle-top"
                    x="0"
                    y="0"
                    width="1530"
                    height="750"
                    rx="375"
                    className="rect1"
                  />
                </g>
              </svg>
              <svg
                className="service-svg"
                viewBox="0 0 1510 760"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient id="orange" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#FFD188" />
                    <stop offset="100%" stopColor="#fff" />
                  </linearGradient>
                  <linearGradient
                    id="orng2"
                    x1="0%"
                    y1="100%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#fff" stopOpacity="0" />
                    <stop offset="100%" stopColor="#FFD188" stopOpacity="1" />
                  </linearGradient>
                  <linearGradient id="green" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#fff" />
                    <stop offset="100%" stopColor="#A6EC1B" />
                  </linearGradient>
                  <linearGradient
                    id="green2"
                    x1="0%"
                    y1="100%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#A6EC1B" stopOpacity="1" />
                    <stop offset="100%" stopColor="#fff" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient id="prpl" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#D447D2" />
                    <stop offset="100%" stopColor="#fff" />
                  </linearGradient>
                  <linearGradient
                    id="prpl2"
                    x1="0%"
                    y1="100%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="20%" stopColor="#fff" stopOpacity="0" />
                    <stop offset="100%" stopColor="#D447D2" stopOpacity="1" />
                  </linearGradient>
                </defs>
                <g transform="translate(5 -754)" strokeWidth="10" fill="none">
                  <rect
                    id="horizontal"
                    x="0"
                    y="759"
                    width="1501"
                    height="750"
                    rx="375"
                    className="rect1"
                  />
                  <rect
                    id="verticle-bottom"
                    transform="translate(375.500000, 1514.000000) rotate(90.000000) translate(-375.500000, -1514.000000) "
                    x="-380"
                    y="1139"
                    width="1511"
                    height="750"
                    rx="375"
                    className="rect2"
                  />
                  <rect
                    id="verticle-top"
                    transform="translate(1125.500000, 756.000000) rotate(90.000000) translate(-1125.500000, -756.000000) "
                    x="370"
                    y="381"
                    width="1511"
                    height="750"
                    rx="375"
                    className="rect3"
                  />
                </g>
              </svg>
              <div className="services_service-wrapper">
                <div className="services_service_content">
                  <div className="services_service_content-circle">
                    <h3>{title}</h3>
                    <div className="services_list_summary">{content}</div>
                    <Link
                      className="services_service_content_link"
                      to={ServiceUrl}
                    >
                      Explore
                    </Link>
                  </div>
                </div>
                <div className="services_service-image">
                  <Link to={ServiceUrl}>
                    {ServiceImage && (
                      <>
                        <GatsbyImage
                          image={ServiceImage}
                          alt={image?.alternativeText ?? title}
                        />
                        <div className="services_service-image_icon">
                          {Icon}
                        </div>
                      </>
                    )}
                  </Link>
                </div>
              </div>
            </SlideIn>
          </div>
        );
      })}
    </section>
  );
};

export { Services };
