import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export const SlideUpElement = ({ children, className }: any) => {
  const showAnimations =
    process.env.GATSBY_SHOW_ANIMATIONS === undefined ||
    (process.env.GATSBY_SHOW_ANIMATIONS &&
      process.env.GATSBY_SHOW_ANIMATIONS === 'true');
  const ref = useRef(null);

  useEffect(() => {
    if (showAnimations) {
      gsap.fromTo(
        ref.current,
        { y: 100, opacity: 0 },
        {
          duration: 1.1,
          x: 0,
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: ref.current,
            start: `top center`,
            toggleActions: 'play none none reverse',
          },
        }
      );
    }
  }, []);
  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
