import React, { FC } from 'react';
import { ContactForm, SocialMedia, SubscribeForm } from 'components';
import { Link } from 'gatsby';
import FooterLogo from 'assets/svg/footer.svg';

interface FooterProps {
  data: {
    Footer: {
      footer: {
        copyrightMessage: string;
        footerLinksTitle: string;
        contactForm: {
          buttonText: string;
          id: number;
          title: string;
        };
        footerLink: {
          id: number;
          link: string;
          title: string;
        }[];
        subscribeForm: {
          buttonText: string;
          emailPlaceholder: string;
          id: number;
          title: string;
        };
      };
    };
    strapiContactUsPage: {
      contactFormSection: {
        email: string;
        phone: { number: number; text: string };
      };
    };
  };
}
export const Footer: FC<FooterProps> = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const {
    Footer: {
      footer: {
        copyrightMessage,
        footerLinksTitle,
        contactForm,
        footerLink,
        subscribeForm,
      },
    },
  } = data;
  return (
    <footer>
      <div className="container">
        <div className="footer-middle">
          <div className="footer-form">
            <div className="footer-form-wrapper">
              <h3>{contactForm.title}</h3>
              <ContactForm
                restrictFields
                dealTitle="Footer Form"
                buttonText={contactForm.buttonText}
              />
            </div>
          </div>
          <div className="footer-content">
            <div className="footer-content-wrapper">
              <div className="footer-subscribe">
                <h3>{subscribeForm.title}</h3>
                <SubscribeForm />
              </div>
              <div className="footer-quick-links">
                <h3>{footerLinksTitle}</h3>
                <ul>
                  {footerLink.map(item => {
                    return (
                      <li key={item.title}>
                        <Link to={item.link}>{item.title}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-logo">
        <Link to="/">
          <FooterLogo />
        </Link>
      </div>
      <div className="footer-bottom">
        <div className="footer-copy-right">{copyrightMessage}</div>
        <div className="footer-social-media-icon">
          <SocialMedia />
        </div>
      </div>
    </footer>
  );
};
