/* eslint-disable array-callback-return */
/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React, { FC, useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { BannerNavigationProps } from 'types';
import { useLocation } from '@reach/router';
import SwipeIcon from 'assets/svg/swipe.svg';

const BannerNavigationDemo: FC<BannerNavigationProps> = ({
  BannerNav,
  isMainBannerNav,
}) => {
  const sliderRef = useRef() as any;
  const sliderItemRef = useRef() as any;
  sliderItemRef.current = [];
  const addTorefs = (el: any) => {
    if (el && !sliderItemRef.current.includes(el as never)) {
      sliderItemRef.current.push(el as never);
    }
  };
  const { pathname } = useLocation();

  useEffect(() => {
    const getAllHeadingHeight = sliderItemRef.current.map((item: any) => {
      return item.children[1].clientHeight;
    });
    const MaxHedingHeight = Math.max(...getAllHeadingHeight);
    sliderRef.current.children[0].children[0].style.paddingBottom = `${MaxHedingHeight}px`;
  }, []);

  const [controlledSwiper, setControlledSwiper] = useState(null);
  const slideTo = (index: any) => {
    if (controlledSwiper) {
      controlledSwiper.slideToLoop(index, 1200, true);
    }
  };

  useEffect(() => {
    const slideIndex = BannerNav.Global.bannerNavigation.filter((nav: any) => {
      return nav.slug === pathname || `${nav.slug}/` === pathname;
    });
    if (slideIndex[0]) {
      slideTo(BannerNav.Global.bannerNavigation.indexOf(slideIndex[0]));
    } else {
      const exactMatch = BannerNav.Global.bannerNavigation.findIndex(nav => {
        return nav.slug === pathname;
      });
      // check exact match
      if (exactMatch > -1) {
        slideTo(exactMatch);
      } else {
        const pathArray = pathname.split('/');

        pathArray.pop();
        // check if parent is in banner
        const parent1Match = BannerNav.Global.bannerNavigation.findIndex(
          nav => {
            return (
              nav.slug === pathArray.join('/') || pathArray.join('/') === ''
            );
          }
        );
        if (parent1Match > -1) {
          slideTo(parent1Match);
        } else {
          pathArray.pop();
          // check if grandparent is in banner
          const parent2Match = BannerNav.Global.bannerNavigation.findIndex(
            nav => {
              return (
                nav.slug === pathArray.join('/') || pathArray.join('/') === ''
              );
            }
          );
          if (parent2Match > -1) {
            slideTo(parent2Match);
          }
        }
      }
    }
  }, [controlledSwiper, pathname]);

  const [isloaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const onSlideChange = swiper => {
    window.scroll(0, 0);
    if (isloaded) {
      const activeIndex = swiper.realIndex;
      const bannerSlug = BannerNav.Global.bannerNavigation[activeIndex].slug;

      if (pathname.startsWith(bannerSlug)) {
        const findinBanner = BannerNav.Global.bannerNavigation.findIndex(
          nav => {
            return nav.slug === pathname;
          }
        );
        if (findinBanner > -1) {
          // if bannerSlug is one in banner nav slug then navigate
          navigate(bannerSlug);
        }
      } else {
        // if bannerSlug does not match then navigate
        navigate(bannerSlug);
      }
    }
  };
  return (
    <div className="banner">
      <div
        className={`banner_navigation ${
          isMainBannerNav ? 'main-banner-nav' : 'secondary-banner-nav'
        }`}
        ref={sliderRef}
      >
        <Swiper
          onSwiper={setControlledSwiper}
          spaceBetween={32}
          slidesPerView={1}
          centeredSlides
          loop
          speed={1200}
          roundLengths
          grabCursor
          slideToClickedSlide
          loopedSlides={BannerNav.Global.bannerNavigation.length}
          onSlideChange={onSlideChange}
          breakpoints={{
            576: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: isMainBannerNav ? 3 : 5,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: isMainBannerNav ? 3 : 5,
              spaceBetween: 20,
            },
          }}
        >
          {BannerNav.Global.bannerNavigation.map((item: any) => {
            const bannerImage: any = item.image
              ? getImage(
                  item?.image?.localFile?.childImageSharp?.gatsbyImageData
                )
              : null;
            return (
              <SwiperSlide key={`${item.title}${item.slug}`}>
                <div className={`banner_item ${item.theme}`} ref={addTorefs}>
                  <div className="banner_wrapper">
                    <div className="banner_dots" />
                    <div className="banner_img">
                      {bannerImage && (
                        <Link to={item.slug}>
                          <GatsbyImage
                            image={bannerImage}
                            alt={
                              item.image?.alternativeText ?? (item.title || '')
                            }
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="banner_heading">
                    <p className="banner_title">
                      <Link to={item.slug}>{item.title}</Link>
                    </p>
                    <p className="banner_highlights">{item.tagline}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="swipe-icon">
          <SwipeIcon />
        </div>
      </div>
    </div>
  );
};
export { BannerNavigationDemo };
