/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { brandsWorkedWithProps } from 'types';

const Brands: FC<brandsWorkedWithProps> = ({ brandsWorked }) => {
  if (!brandsWorked) {
    return null;
  }
  return (
    <div className="brands">
      {brandsWorked.showTitle && (
        <div className="container">
          <div className="brands_content">
            <p className="brands_title">{brandsWorked.title}</p>
          </div>
        </div>
      )}
      <div className="brands-logos">
        <div className="brands-logos-wrapper">
          {brandsWorked.brandImage.map((item: any) => {
            return (
              <div className="brands-img-wrapper">
                <div className="brand-img" key={item.title}>
                  <img
                    src={item.image?.localFile?.publicURL}
                    alt={item.title}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export { Brands };
