/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface WeightLossStepsAnimationProps {
  children: any;
  className?: string;
  delaySec?: number;
}

export const WeightlossStepsAnimation: FC<WeightLossStepsAnimationProps> = ({
  children,
  className,
  delaySec = 0,
}) => {
  const showAnimations =
    process.env.GATSBY_SHOW_ANIMATIONS === undefined ||
    (process.env.GATSBY_SHOW_ANIMATIONS &&
      process.env.GATSBY_SHOW_ANIMATIONS === 'true');
  const ref = useRef(null);

  useEffect(() => {
    const CircleAnimationPosition =
      window.innerWidth < 990 ? 'top-=10 bottom-=160' : 'top-=180 bottom-=220';
    const delayTime = window.innerWidth < 1400 ? 0 : delaySec;
    const element: any = ref.current;
    if (showAnimations && element != null) {
      const circle = element.querySelectorAll('.wl-benefits-list');
      if (window.innerWidth < 990) {
        for (let i = 0; i < circle.length; i += 1) {
          gsap.fromTo(
            circle[2 * i],
            { autoAlpha: 0, x: '-100' },
            {
              duration: 1.2,
              autoAlpha: 1,
              x: 0,
              delay: delayTime,
              scrollTrigger: {
                trigger: circle[2 * i],
                start: CircleAnimationPosition,
                toggleActions: 'play none none reverse',
              },
            }
          );
          gsap.fromTo(
            circle[2 * i + 1],
            { autoAlpha: 0, x: 100 },
            {
              duration: 2,
              autoAlpha: 1,
              x: 0,
              delay: delayTime,
              scrollTrigger: {
                trigger: circle[2 * i + 1],
                start: CircleAnimationPosition,
                toggleActions: 'play none none reverse',
              },
            }
          );
        }
      }
    }
  }, [showAnimations]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
