import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage } from 'gatsby-plugin-image';

import { EnquireFormModal } from 'components';
import RightArrow from 'assets/svg/arrow-right-icon.svg';

export const CTASection = ({ data, modalTitle, dealTitle }: any) => {
  const [isEnquireModalOpen, setisEnquireModalOpen] = React.useState(false);
  const [inputValue, setinputValue] = React.useState('');
  const [posCodeValue, setposCodeValue] = React.useState(inputValue);
  const [error, setError] = React.useState('');

  const handleChange = (event: any) => {
    event.preventDefault();
    setinputValue(event.target.value);
  };

  const handleEnquireModalOpen = () => {
    if (inputValue.trim() === '') {
      setError('PostCode is Required');
    } else if (inputValue.length < 4) {
      setError('Postcode must be atleast 4 digits');
    } else {
      setError('');
      setisEnquireModalOpen(true);
      setposCodeValue(inputValue);
    }
  };
  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className="cta-banner-section">
        <div className="container">
          <div className="cta-banner-section-container">
            <div className="cta-banner-section-details">
              <div className="cta-banner-section-content">
                {data.title && (
                  <ReactMarkdown className="md-html">
                    {data.title}
                  </ReactMarkdown>
                )}
              </div>
              <div className="cta-banner-section-tagline">
                {data.tagline && (
                  <ReactMarkdown className="md-html">
                    {data.tagline}
                  </ReactMarkdown>
                )}
              </div>
              {data.ctaButton && data.postcodePlaceholder && (
                <>
                  <div className="cta-banner-section-form">
                    <input
                      type="number"
                      placeholder={data.postcodePlaceholder}
                      name="posCode"
                      onChange={handleChange}
                      value={inputValue}
                      min="0"
                      onKeyPress={preventMinus}
                    />
                    <button
                      type="submit"
                      className="cta-banner-section-button"
                      onClick={handleEnquireModalOpen}
                    >
                      <span>{data.ctaButton}</span>
                      <RightArrow />
                    </button>
                  </div>
                  {error && (
                    <div className="cta-banner-section-error">{error}</div>
                  )}
                </>
              )}
            </div>
            <div className={`cta-banner-section-image `}>
              <div className="cta-banner-section-image-detail">
                {data.image?.localFile?.childImageSharp?.gatsbyImageData && (
                  <GatsbyImage
                    image={
                      data.image?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={data.image?.alternativeText ?? data.title}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EnquireFormModal
        isEnquireModalOpen={isEnquireModalOpen}
        setisEnquireModalOpen={setisEnquireModalOpen}
        contactFormModalTitle={modalTitle}
        optionalMessage
        dealTitle={`${dealTitle} Page form`}
        posCodeValue={posCodeValue}
        showNdisFields={false}
      />
    </>
  );
};
