/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql, Link } from 'gatsby';
import {
  hideCart,
  updateProductQuantity,
  deleteProductFromCart,
} from 'Redux/actions';
import { getprice } from 'utils';
import DeleteIcon from 'assets/svg/delete.svg';
import { useLocation } from '@reach/router';

const Cart = () => {
  const dispatch = useDispatch();
  const data: any = AddToCartQuery();
  const { pathname } = useLocation();
  const {
    site: {
      siteMetadata: { functionsUrl },
    },
    strapiShop: { cartContent },
  } = data;
  const {
    CartReducer: {
      isCartOpen,
      cartItems,
      cartId,
      showLoaderForCart,
      errorMessageForCart,
      cartDetails,
    },
  } = useSelector((state: any) => {
    return state;
  });
  const handelHideCart = () => {
    dispatch(hideCart());
    document.body.classList.remove('overflow-hidden');
    if (pathname.includes('/cart')) {
      window.history.pushState('', '', `/shop`);
    }
  };
  return (
    <div className={`sidebar-cart-overlay ${isCartOpen ? 'show' : ''}`}>
      <div className={`sidebar-cart ${isCartOpen ? 'active' : ''}`}>
        <div className="sidebar-cart-header">
          <h4>{cartContent.title}</h4>
          <button
            className="sidebar-cart-close"
            type="button"
            onClick={() => handelHideCart()}
          >
            X
          </button>
        </div>
        <div
          className={`sidebar-cart-content ${
            cartItems.length === 0 ? 'sidebar-cart-content--empty' : ''
          }`}
        >
          {errorMessageForCart && (
            <div>{errorMessageForCart || cartContent.cartErrorMessage}</div>
          )}
          <div className="sidebar-cart-content-container">
            {cartItems.length !== 0 ? (
              <div className="sidebar-cart-product-list">
                {showLoaderForCart && (
                  <div className="sidebar-cart-loader">
                    <div className="lds-dual-ring" />
                  </div>
                )}
                {cartItems.map((cartItem: any) => {
                  return (
                    <div className="sidebar-cart-product" key={cartItem.id}>
                      <div className="sidebar-cart-product-image">
                        <img src={cartItem.image_url} alt={cartItem.name} />
                      </div>
                      <div className="sidebar-cart-product-content">
                        <div className="sidebar-cart-product-description">
                          <button
                            type="button"
                            className="sidebar-cart-product-delete"
                            onClick={() =>
                              dispatch(
                                deleteProductFromCart(
                                  cartItem.id,
                                  cartId,
                                  functionsUrl
                                )
                              )
                            }
                          >
                            <DeleteIcon />
                          </button>
                          <h5>{cartItem.name}</h5>
                          {cartItem.options.length !== 0 &&
                            cartItem.options.map((option: any) => {
                              return (
                                <div className="sidebar-cart-product-option">
                                  <span>{option.name}: </span>
                                  {option.value}
                                </div>
                              );
                            })}
                        </div>
                        <div className="sidebar-cart-product-actions">
                          <div className="sidebar-cart-product-quantity">
                            <button
                              type="button"
                              onClick={() =>
                                dispatch(
                                  updateProductQuantity(
                                    cartItem.id,
                                    cartItem.product_id,
                                    cartItem.variant_id,
                                    cartItem.quantity - 1,
                                    cartId,
                                    functionsUrl
                                  )
                                )
                              }
                              disabled={cartItem.quantity <= 1}
                            >
                              <span>-</span>
                            </button>
                            <span>{cartItem.quantity}</span>
                            <button
                              type="button"
                              onClick={() =>
                                dispatch(
                                  updateProductQuantity(
                                    cartItem.id,
                                    cartItem.product_id,
                                    cartItem.variant_id,
                                    cartItem.quantity + 1,
                                    cartId,
                                    functionsUrl
                                  )
                                )
                              }
                            >
                              <span>+</span>
                            </button>
                          </div>
                          <div>${getprice(cartItem.extended_sale_price)}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div> {cartContent.emptyCartMessage}</div>
            )}
          </div>
        </div>
        <div className="sidebar-cart-footer">
          {cartItems.length !== 0 && (
            <>
              <div className="sidebar-cart-coupon-widget" />
              <div className="sidebar-cart-field sidebar-cart-shipping">
                <div className="sidebar-cart-field-label">
                  Shipping & Handling:
                </div>
                <div className="sidebar-cart-field-value">
                  Calculated at checkout
                </div>
              </div>
              <div className="sidebar-cart-field sidebar-cart-total">
                <div className="sidebar-cart-field-label">Cart Total:</div>
                <div className="sidebar-cart-field-value">
                  ${getprice(cartDetails.cart_amount)}
                </div>
              </div>
            </>
          )}
          <div className="sidebar-cart-actions">
            {cartItems.length ? (
              <Link
                to="/checkout"
                onClick={() => {
                  setTimeout(() => {
                    handelHideCart();
                  }, 10);
                }}
                className="sidebar-cart-checkout-button"
              >
                <span>{cartContent.cartCtaText}</span>
              </Link>
            ) : (
              <Link
                to="/shop"
                onClick={() => {
                  setTimeout(() => {
                    handelHideCart();
                  }, 10);
                }}
                className="sidebar-cart-checkout-button"
              >
                <span>{cartContent.emptyCartCtaText}</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Cart };

const AddToCartQuery = () => {
  return useStaticQuery(graphql`
    query cartQuery {
      site {
        siteMetadata {
          functionsUrl
        }
      }
      strapiShop {
        cartContent: cartDetails {
          title
          emptyCartMessage
          cartCtaText
          cartErrorMessage
          emptyCartCtaText
        }
      }
    }
  `);
};
