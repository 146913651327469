import { useStaticQuery, graphql } from 'gatsby';

const FooterQuery = () => {
  return useStaticQuery(graphql`
    query FooterQuery {
      Footer: strapiGlobal {
        footer {
          copyrightMessage
          footerLinksTitle
          contactForm {
            buttonText
            id
            title
          }
          footerLink {
            id
            link
            title
          }
          subscribeForm {
            buttonText
            emailPlaceholder
            id
            title
          }
        }
      }
      strapiContactUsPage {
        contactFormSection {
          email
          phone {
            number
            text
          }
        }
      }
      strapiWeightLossPage {
        slug
      }
      cartUrl: site {
        siteMetadata {
          functionsUrl
        }
      }
    }
  `);
};

export { FooterQuery };
