import React, { useEffect, useState } from 'react';
import {
  PaymentSection,
  MobileWeightLossPackage,
  WeightLossPlanSection,
} from 'components';
import MobileWhiteBgTop from 'assets/svg/whitebg-top.svg';
import MobileWhiteBgBottom from 'assets/svg/whitebg-bottom.svg';
import Rounded from 'assets/svg/rounded.svg';
import DesktopWhiteBgTop from 'assets/svg/desktop-whitebgtop.svg';
import DesktopWhiteBgBottom from 'assets/svg/desktop-whitebgbottom.svg';
import { SlideUpTitle } from 'components/animations';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getDateMonthYear } from 'utils';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const PaymentContent = ({
  weightLossPackage,
  weightLossPackageService,
  // products,
  weightLossPackageDescription,
  functionUrl,
  DesktopPlanRef,
}: any) => {
  const {
    title,
    tagline,
    guaranteeTitle,
    guaranteeLogo,
    inventoryLevel,
    nextIntaketitle,
    nextIntakedate,
  } = weightLossPackageDescription;
  const guaranteeImage =
    guaranteeLogo && guaranteeLogo.localFile
      ? getImage(guaranteeLogo.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const [humPayment, setHumPayment] = useState(true);
  const [activeTitle, setActiveTitle] = useState('');

  useEffect(() => {
    sessionStorage.removeItem('digitalCartId');
    weightLossPackage.edges.forEach((item: any) => {
      if (item.node.popular) setActiveTitle(item.node.title);
    });
  }, []);
  useEffect(() => {
    const element: any = document.querySelectorAll(
      '.weightloss-plan-offer-stock-title strong'
    );
    const AnimationPosition =
      window.innerWidth < 992 ? 'top center+=250' : 'top center-=20';
    gsap.from(element, {
      textContent: 50,
      duration: 1.7,
      ease: 'power1.in',
      snap: { textContent: 1 },
      scrollTrigger: {
        trigger: element,
        start: `${AnimationPosition}`,
        toggleActions: 'restart none restart none',
      },
    });
  }, []);
  return (
    <div className="weightloss-plan-section" ref={DesktopPlanRef}>
      <div className="weightloss-plan-wrapper">
        <DesktopWhiteBgTop className="desktop-white-bg-top" />
        <MobileWhiteBgTop className="mobile-white-bg-top" />
        <div className="weightloss-plan-container">
          <div className="weightloss-plan-details">
            <SlideUpTitle delaySec={0.5}>
              <p className="weightloss-plan-details-title">{title}</p>
              <p className="weightloss-plan-details-description">{tagline}</p>
            </SlideUpTitle>
          </div>
          <div className="weightloss-plan-divider" />
          <PaymentSection
            humPayment={humPayment}
            setHumPayment={setHumPayment}
            challengeTermText={weightLossPackageDescription.challengeTermText}
            logoImage={weightLossPackageDescription.logoImage}
          />
          <WeightLossPlanSection
            weightLossPackageDescription={weightLossPackageDescription}
            weightLossPackage={weightLossPackage}
            weightLossPackageService={weightLossPackageService}
            // products={products}
            functionUrl={functionUrl}
            humPayment={humPayment}
            activeTitle={activeTitle}
            setActiveTitle={setActiveTitle}
          />
          <MobileWeightLossPackage
            weightLossPackageDescription={weightLossPackageDescription}
            weightLossPackage={weightLossPackage}
            weightLossPackageService={weightLossPackageService}
            // products={products}
            functionUrl={functionUrl}
            humPayment={humPayment}
            activeTitle={activeTitle}
            setActiveTitle={setActiveTitle}
          />
        </div>

        <div className="weightloss-plan-offer">
          <DesktopWhiteBgBottom className="desktop-white-bg-bottom" />
          <MobileWhiteBgBottom className="mobile-white-bg-bottom" />
          <div className="weightloss-plan-offer-container">
            {guaranteeTitle && (
              <p className="weightloss-plan-offer-title">{guaranteeTitle}</p>
            )}
            {guaranteeImage && (
              <GatsbyImage image={guaranteeImage} alt="image" />
            )}
          </div>
          {inventoryLevel && (
            <div className="weightloss-plan-offer-stock">
              <div className="weightloss-plan-offer-stock-wrapper">
                <div className="weightloss-plan-offer-stock-title">
                  <ReactMarkdown>{inventoryLevel}</ReactMarkdown>
                </div>
                <div className="weightloss-plan-offer-stock-date">
                  <p>
                    <Rounded />
                    {nextIntaketitle}
                    <span> &#47;&#47; </span>
                    {getDateMonthYear(nextIntakedate)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { PaymentContent };
