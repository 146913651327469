/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import ReactModal from 'react-modal';
import Slider from 'react-slick';

const InfoPopup = ({ InfoModal, closeModal, portalClassName, wlKey }: any) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
  };
  useEffect(() => {
    window.addEventListener('click', e => {
      if (e.target === document.querySelector('.ReactModal__Content')) {
        closeModal();
      }
    });
  }, []);
  return (
    <ReactModal
      closeTimeoutMS={100}
      isOpen={InfoModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      portalClassName={`ReactModalPortal ${portalClassName}`}
    >
      <div className="infoPopup-wrapper">
        <button
          type="button"
          onClick={() => closeModal()}
          className="modal-close"
        >
          <span>&#x2715;</span>
        </button>
        <div className="infoPopup-wrapper-content">
          <div className="infoPopup-modalContent">
            <Slider {...settings}>
              {wlKey?.infoPopup?.map((info: any) => {
                const { id, title, description, image } = info;
                const planImg: any = image
                  ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
                  : null;
                return (
                  title && (
                    <div
                      className={
                        planImg ? 'infoPopup-modalContent-inner' : 'no-img'
                      }
                      key={id}
                    >
                      <h2 className="infoPopup-modalContent-inner-title">
                        {title}
                      </h2>
                      <p className="infoPopup-modalContent-inner-description">
                        {description}
                      </p>
                      <div className="infoPopup-modalContent-inner-img">
                        <GatsbyImage
                          image={planImg}
                          alt={planImg?.alternativeText}
                        />
                      </div>
                    </div>
                  )
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export { InfoPopup };
