exports.components = {
  "component---src-domain-about-tsx": () => import("./../../../src/domain/about.tsx" /* webpackChunkName: "component---src-domain-about-tsx" */),
  "component---src-domain-blog-tsx": () => import("./../../../src/domain/blog.tsx" /* webpackChunkName: "component---src-domain-blog-tsx" */),
  "component---src-domain-careers-tsx": () => import("./../../../src/domain/careers.tsx" /* webpackChunkName: "component---src-domain-careers-tsx" */),
  "component---src-domain-community-tsx": () => import("./../../../src/domain/community.tsx" /* webpackChunkName: "component---src-domain-community-tsx" */),
  "component---src-domain-contact-us-tsx": () => import("./../../../src/domain/contact-us.tsx" /* webpackChunkName: "component---src-domain-contact-us-tsx" */),
  "component---src-domain-dietitianbookings-tsx": () => import("./../../../src/domain/dietitianbookings.tsx" /* webpackChunkName: "component---src-domain-dietitianbookings-tsx" */),
  "component---src-domain-dietitians-tsx": () => import("./../../../src/domain/dietitians.tsx" /* webpackChunkName: "component---src-domain-dietitians-tsx" */),
  "component---src-domain-faq-tsx": () => import("./../../../src/domain/faq.tsx" /* webpackChunkName: "component---src-domain-faq-tsx" */),
  "component---src-domain-learning-hub-tsx": () => import("./../../../src/domain/learning-hub.tsx" /* webpackChunkName: "component---src-domain-learning-hub-tsx" */),
  "component---src-domain-media-hub-tsx": () => import("./../../../src/domain/media-hub.tsx" /* webpackChunkName: "component---src-domain-media-hub-tsx" */),
  "component---src-domain-recipes-tsx": () => import("./../../../src/domain/recipes.tsx" /* webpackChunkName: "component---src-domain-recipes-tsx" */),
  "component---src-domain-service-detail-page-tsx": () => import("./../../../src/domain/service-detail-page.tsx" /* webpackChunkName: "component---src-domain-service-detail-page-tsx" */),
  "component---src-domain-service-page-tsx": () => import("./../../../src/domain/service-page.tsx" /* webpackChunkName: "component---src-domain-service-page-tsx" */),
  "component---src-domain-services-tsx": () => import("./../../../src/domain/services.tsx" /* webpackChunkName: "component---src-domain-services-tsx" */),
  "component---src-domain-state-tsx": () => import("./../../../src/domain/state.tsx" /* webpackChunkName: "component---src-domain-state-tsx" */),
  "component---src-domain-story-tsx": () => import("./../../../src/domain/story.tsx" /* webpackChunkName: "component---src-domain-story-tsx" */),
  "component---src-domain-trainer-tsx": () => import("./../../../src/domain/trainer.tsx" /* webpackChunkName: "component---src-domain-trainer-tsx" */),
  "component---src-domain-weight-loss-page-tsx": () => import("./../../../src/domain/weight-loss-page.tsx" /* webpackChunkName: "component---src-domain-weight-loss-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-login-tsx": () => import("./../../../src/pages/members-login.tsx" /* webpackChunkName: "component---src-pages-members-login-tsx" */),
  "component---src-templates-article-detail-tsx": () => import("./../../../src/templates/article-detail.tsx" /* webpackChunkName: "component---src-templates-article-detail-tsx" */),
  "component---src-templates-dietetics-service-tsx": () => import("./../../../src/templates/dietetics-service.tsx" /* webpackChunkName: "component---src-templates-dietetics-service-tsx" */),
  "component---src-templates-dietitian-tsx": () => import("./../../../src/templates/dietitian.tsx" /* webpackChunkName: "component---src-templates-dietitian-tsx" */),
  "component---src-templates-location-detail-tsx": () => import("./../../../src/templates/LocationDetail.tsx" /* webpackChunkName: "component---src-templates-location-detail-tsx" */),
  "component---src-templates-media-release-detail-tsx": () => import("./../../../src/templates/media-release-detail.tsx" /* webpackChunkName: "component---src-templates-media-release-detail-tsx" */),
  "component---src-templates-ndis-details-tsx": () => import("./../../../src/templates/ndis-details.tsx" /* webpackChunkName: "component---src-templates-ndis-details-tsx" */),
  "component---src-templates-page-detail-tsx": () => import("./../../../src/templates/page-detail.tsx" /* webpackChunkName: "component---src-templates-page-detail-tsx" */),
  "component---src-templates-recipe-detail-tsx": () => import("./../../../src/templates/recipe-detail.tsx" /* webpackChunkName: "component---src-templates-recipe-detail-tsx" */),
  "component---src-templates-trainer-tsx": () => import("./../../../src/templates/trainer.tsx" /* webpackChunkName: "component---src-templates-trainer-tsx" */)
}

