import { useStaticQuery, graphql } from 'gatsby';

const MenuQuery = () => {
  return useStaticQuery(graphql`
    query MenuQuery {
      strapiGlobal(id: {}) {
        mainNav {
          navLink {
            slug
            title
          }
        }
        headerLinks {
          showBgColor
          title
          slug
        }
      }
      strapiContactUsPage(id: {}) {
        contactFormSection {
          phone {
            number
            text
          }
        }
      }
    }
  `);
};
export { MenuQuery };
