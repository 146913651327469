/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import RightArrow from 'assets/svg/arrow-right-icon.svg';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage } from 'gatsby-plugin-image';
import { EnquireFormModal, VideoModal } from 'components';
import PlayButton from 'assets/images/play-button.png';

const Banner = ({
  centerAlign,
  title,
  tagline,
  image,
  video,
  ctaButton,
  dealTitle,
  postcodePlaceholder,
  contactFormModalTitle,
  bannerButtonCtaHandler,
  showNdisFields,
  showBgImage,
}: any) => {
  const [isEnquireModalOpen, setisEnquireModalOpen] = useState(false);
  const [inputValue, setinputValue] = useState('');
  const [posCodeValue, setposCodeValue] = useState(inputValue);
  const [error, setError] = useState('');
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [ModalVideoIndex, setModalVideoIndex]: any = useState(0);
  const VideoGallery: any = [video?.localFile?.publicURL];

  const handleChange = (event: any) => {
    event.preventDefault();
    setinputValue(event.target.value);
  };
  const handleEnquireModalOpen = () => {
    if (inputValue.trim() === '') {
      setError('PostCode is Required');
    } else if (inputValue.length < 4) {
      setError('Postcode must be atleast 4 digits');
    } else {
      setError('');
      setisEnquireModalOpen(true);
      setposCodeValue(inputValue);
    }
  };
  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  return (
    <>
      <div
        className={`banner-section ${
          centerAlign ? 'center-align' : 'left-align'
        } ${showBgImage ? 'has-background' : ''}`}
        style={{
          ...(showBgImage && {
            backgroundImage: `url(${image?.localFile?.publicURL})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
          }),
        }}
      >
        <div className="container">
          <div className="banner-section-container">
            <div className="banner-section-wrapper">
              <div className="banner-section-content">
                {title && (
                  <ReactMarkdown className="md-html">{title}</ReactMarkdown>
                )}
              </div>
              <div className="banner-section-tagline">
                {tagline && (
                  <ReactMarkdown className="md-html">{tagline}</ReactMarkdown>
                )}
              </div>
              {ctaButton && postcodePlaceholder && (
                <>
                  <div className="banner-section-form">
                    <input
                      type="number"
                      placeholder={postcodePlaceholder}
                      name="posCode"
                      onChange={handleChange}
                      value={inputValue}
                      min="0"
                      onKeyPress={preventMinus}
                    />
                    <button
                      type="submit"
                      className="banner-section-button"
                      onClick={handleEnquireModalOpen}
                    >
                      <span>{ctaButton}</span>
                      <RightArrow />
                    </button>
                  </div>
                  {error && <div className="banner-section-error">{error}</div>}
                </>
              )}
              {ctaButton && !postcodePlaceholder && !centerAlign && (
                <div className="banner-section-btn">
                  <button
                    type="button"
                    onClick={
                      bannerButtonCtaHandler
                        ? () => bannerButtonCtaHandler()
                        : () => {}
                    }
                  >
                    {ctaButton}
                  </button>
                </div>
              )}
            </div>
            {!showBgImage && (
              <div
                className={`banner-section-image ${
                  video?.localFile ? 'has-video' : ''
                }`}
              >
                {video?.localFile && (
                  <img
                    src={PlayButton}
                    alt="play-button"
                    className="play-btn"
                    onClick={() => setVideoModalOpen(true)}
                  />
                )}
                <div className="banner-section-image-detail">
                  {image?.localFile?.childImageSharp?.gatsbyImageData && (
                    <GatsbyImage
                      image={image?.localFile?.childImageSharp?.gatsbyImageData}
                      alt={image?.alternativeText ?? title}
                    />
                  )}
                </div>
              </div>
            )}
            {ctaButton && !postcodePlaceholder && centerAlign && (
              <>
                <div className="banner-section-btn">
                  <button
                    type="button"
                    onClick={
                      bannerButtonCtaHandler
                        ? () => bannerButtonCtaHandler()
                        : () => {}
                    }
                  >
                    {ctaButton}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <EnquireFormModal
        isEnquireModalOpen={isEnquireModalOpen}
        setisEnquireModalOpen={setisEnquireModalOpen}
        contactFormModalTitle={contactFormModalTitle}
        optionalMessage
        dealTitle={dealTitle}
        posCodeValue={posCodeValue}
        showNdisFields={showNdisFields}
      />
      <VideoModal
        isModalOpen={isVideoModalOpen}
        setisModalOpen={setVideoModalOpen}
        gallery={VideoGallery}
        setModalVideoIndex={setModalVideoIndex}
        ModalVideoIndex={ModalVideoIndex}
      />
    </>
  );
};
export { Banner };
