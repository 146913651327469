/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import MailIconBlack from 'assets/svg/MailIconBlack.svg';
import { CareerSummaryComponentPropsType } from 'types';
import { CareerPageQuiz } from 'components';
import {
  showCareerPageQuizPrevQuestion,
  showCareerPageQuizNextQuestion,
  submitCareerPageQuiz,
  resetCareerPageQuiz,
  updateCarrerPageQuizForm,
} from 'Redux/actions';

const CareerSummary: FC<CareerSummaryComponentPropsType> = ({
  careerDetails,
  careerQuizOpen,
  IscareerQuizOpen,
}) => {
  const {
    CareerPageQuizReducer: {
      currentQuestion,
      submitedQuestions,
      showcareerform,
    },
  } = useSelector((state: any) => {
    return state;
  });
  const handleModal = () => {
    IscareerQuizOpen(!careerQuizOpen);
  };
  const { heading, description, email, jobPerks, ctaLabel } = careerDetails;
  return (
    <>
      <section className="career-summary">
        <div className="container">
          <div className="career-summary-item-wrapper">
            <div className="career-summary-item-title">
              <h2>{heading}</h2>
            </div>
            <div className="career-summary-item-content">
              <ReactMarkdown className="md-html">{description}</ReactMarkdown>
            </div>
          </div>
          <div className="career-summary-item-wrapper">
            <div className="career-summary-item-name">
              <div className="career-summary-item-email">
                <div className="service-page-cta-link-container">
                  <button
                    className="careers-cta"
                    type="button"
                    onClick={handleModal}
                  >
                    <span>{ctaLabel}</span>
                  </button>
                </div>
                <a href={`mailto:${email}`} className="career-summary-item-svg">
                  <MailIconBlack />
                  <h3>{email}</h3>
                </a>
              </div>
            </div>
            <div className="career-summary-item-links">
              <ul>
                {jobPerks.map((item: any) => {
                  return (
                    <li
                      className="career-summary-item-links-title"
                      key={item.id}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <CareerPageQuiz
        isQuizModalOpen={careerQuizOpen}
        portalClassName="career-page-quiz-modal"
        handleModalClose={handleModal}
        currentQuestion={currentQuestion}
        submitedQuestions={submitedQuestions}
        showcareerform={showcareerform}
        resetQuiz={resetCareerPageQuiz}
        showPrevQuestion={showCareerPageQuizPrevQuestion}
        showNextQuestion={showCareerPageQuizNextQuestion}
        previewAnswer={submitCareerPageQuiz}
        updateCarrerPageQuizForm={updateCarrerPageQuizForm}
      />
    </>
  );
};
export { CareerSummary };
