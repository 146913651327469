import React, { FC, useState, useEffect } from 'react';
import { DieteticsFilterTypes } from 'types';
import { Link } from 'gatsby';
import Search from 'assets/svg/search.svg';

type FormEvent = React.FormEvent<HTMLFormElement>;

const DieteticsFilter: FC<DieteticsFilterTypes> = ({
  Filters,
  filtervalue = '',
  setKeyword,
  setFilter,
  List,
}) => {
  const [filterValue, setFilterValue] = useState(filtervalue);
  const [isActive, setActive] = useState(false);
  const handelFilterChange = (value: string) => {
    if (filterValue !== value) {
      setFilter(value);
      setFilterValue(value);
    }
  };
  const onInputChange = (event: FormEvent) => {
    event.preventDefault();
    setKeyword(event.target.value);
  };
  const handleClick = () => {
    setActive(!isActive);
  };
  useEffect(() => {
    // eslint-disable-next-line func-names
    window.addEventListener('click', function (event) {
      const menu = document.querySelector('.dietetics_option_select');
      if (event.target !== menu) {
        setActive(false);
      }
    });
  }, [isActive]);
  return (
    <div>
      <div className="dietetics-common-filter">
        <ul className="dietetics-common-filter-list">
          <li
            className={`dietetics-common-filter-list-item ${
              filterValue === '' ? 'active' : ''
            }`}
          >
            <button type="button" onClick={() => handelFilterChange('')}>
              all
            </button>
          </li>
          {Filters.map((filter: string) => {
            return (
              <li
                className={`dietetics-common-filter-list-item ${
                  filterValue === filter ? 'active' : ''
                }`}
                key={filter}
              >
                <button
                  type="button"
                  onClick={() => handelFilterChange(filter)}
                >
                  {filter}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="dietetics_option">
        <div className="dietetics_option_list">
          <button
            type="button"
            className="dietetics_option_select"
            onClick={() => handleClick()}
            id="filter-submenu"
          >
            Services Look-up
          </button>
          <div
            className={`dietetics_option_submenu ${
              isActive ? 'active' : null
            } `}
          >
            {List.edges.map((category: any) => {
              return (
                <div
                  className="dietetics_option_submenu_list"
                  key={category.node.title}
                >
                  <h4>{category.node.title}</h4>
                  <ul>
                    {category.node.dietetics_services.map((list: any) => {
                      return (
                        <li
                          className="dietetics_option_submenu_item"
                          key={list.title}
                        >
                          <Link to={list.slug}>{list.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <form onChange={e => onInputChange(e)}>
          <input type="text" placeholder="Services Search" />
          <span>
            <Search />
          </span>
        </form>
      </div>
    </div>
  );
};

export { DieteticsFilter };
