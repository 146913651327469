import { STORE_INVENTORY_DATA } from 'Redux/actionTypes';

type InitialStateType = {
  ProductListWithInventory: Array<any>;
};

const InitialState: InitialStateType = {
  ProductListWithInventory: [],
};

const InventoryReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case STORE_INVENTORY_DATA:
      return {
        ...state,
        ProductListWithInventory: action.payload,
      };
    default:
      return state;
  }
};
export { InventoryReducer };
