/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { FC, useState, useEffect } from 'react';
import TickIcon from 'assets/svg/TickWithBlueBgIcon.svg';
import { QuizQuestion } from 'types';
import { useDispatch } from 'react-redux';
import Select from 'react-select/async';

const postCode = require('../../../postcode.json');

interface postCodeQuestionPropTypes {
  Question: QuizQuestion;
  showNextQuestion: any;
  submitedQuestions: any;
  currentquestion: number;
  QuestionsListLength: number;
  previewAnswer: any;
}
const PostCodeQuestions: FC<postCodeQuestionPropTypes> = ({
  Question,
  showNextQuestion,
  submitedQuestions,
  currentquestion,
  QuestionsListLength,
  previewAnswer,
}) => {
  const { question, multipleChoiceQuestionTypeOptions, description } =
    Question.node;
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionSummary, setSelectedOptionSummary] = useState('');
  const [showPostcode, setShowPostcode] = useState(false);
  /* eslint-disable-next-line */
  const [postCodeFieldValue, setPostCodeFieldValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({}) as any;
  const checkPostCode = (data: any) => {
    setPostCodeFieldValue(data.pincode);
    postCode.filter(element => {
      if (element.pincode === data.pincode && element.subUrb === data.subUrb) {
        setTimeout(() => {
          setSelectedLocation(element);
        }, 100);
      } else {
        setSelectedLocation({});
      }
    });
  };

  const handleClick = (e: any) => {
    setSelectedOption(e.value);
    setSelectedOptionSummary(e.optionSummaryContent);
    if (e.value.toLowerCase() !== 'online') {
      setShowPostcode(true);
    } else {
      setShowPostcode(false);
    }
  };

  const filterPostcodes = (inputValue: string) => {
    return postCode.filter(
      (i: any) =>
        i.pincode.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.subUrb.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterPostcodes(inputValue));
    }, 1000);
  };

  const handelNextQuestion = () => {
    const dataToSave = {
      question,
      answer: selectedOption,
      optionSummary: selectedOptionSummary,
      ...(selectedLocation && { selectedLocation }),
    };
    dispatch(showNextQuestion(dataToSave));
  };
  const SubmitAndPreviewAnswers = () => {
    const dataToSave = {
      question,
      answer: selectedOption,
      optionSummary: selectedOptionSummary,
      ...(selectedLocation && { selectedLocation }),
    };
    dispatch(previewAnswer(dataToSave));
  };
  useEffect(() => {
    if (submitedQuestions[currentquestion - 1]) {
      setSelectedOption(submitedQuestions[currentquestion - 1].answer);
      setSelectedOptionSummary(
        submitedQuestions[currentquestion - 1].optionSummary
      );
      if (submitedQuestions[currentquestion - 1].answer !== 'Online') {
        setShowPostcode(true);
        setSelectedLocation(
          submitedQuestions[currentquestion - 1].selectedLocation
        );
        setPostCodeFieldValue(
          submitedQuestions[currentquestion - 1].selectedLocation.subUrb
        );
      }
    }
  }, [currentquestion]);
  return (
    <div className="question-component postcode-type">
      <div className="quiz-item-question-option-wrapper">
        <h4 className="quiz-item-question">{question}</h4>
        <ul className="quiz-item-option-list">
          {multipleChoiceQuestionTypeOptions &&
            multipleChoiceQuestionTypeOptions.map((element: any) => {
              return (
                <li key={element.id}>
                  <button
                    type="button"
                    className="quiz-item-option-list-item"
                    onClick={() => {
                      handleClick(element);
                    }}
                  >
                    <div className="quiz-item-option-list-item-label">
                      <span className="quiz-item-option-list-item-icon-tick">
                        {element.value === selectedOption && <TickIcon />}
                      </span>
                      <span
                        className={`quiz-item-option-list-item-content ${
                          element.value === selectedOption ? 'selected' : ''
                        }`}
                      >
                        {element.value}
                      </span>
                    </div>
                    {showPostcode
                      ? element.value !== 'Online' && (
                          <div>
                            <div
                              className={`quiz-item-postcode-wrapper ${
                                Object.keys(selectedLocation).length !== 0
                                  ? 'show-icon'
                                  : ''
                              }`}
                            >
                              <Select
                                type="text"
                                className="quiz-item-postcode-input"
                                options={postCode}
                                onChange={checkPostCode}
                                isSearchable
                                value={
                                  selectedLocation.pincode
                                    ? selectedLocation
                                    : null
                                }
                                cacheOptions
                                defaultOptions
                                loadOptions={loadOptions}
                                getOptionLabel={option =>
                                  `${option.subUrb}, ${option.pincode}`
                                }
                                getOptionValue={option =>
                                  `${option.subUrb}, ${option.pincode}`
                                }
                                placeholder="Select your post code"
                                isOptionDisabled={option => option.disabled}
                              />
                            </div>
                            {selectedLocation.subUrb && (
                              <div className="quiz-item-postcode-title">
                                {selectedLocation.subUrb},{' '}
                                {selectedLocation.pincode}
                              </div>
                            )}
                            {Object.keys(selectedLocation).length === 0 && (
                              <div className="quiz-item-postcode-title">
                                Enter your postcode
                              </div>
                            )}
                          </div>
                        )
                      : null}
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="quiz-item-action-description-wrapper">
        <p className="quiz-item-description">{description}</p>
        {QuestionsListLength === currentquestion ? (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={
              !(
                selectedOption === 'Online' ||
                (selectedOption !== 'Online' &&
                  Object.keys(selectedLocation).length !== 0)
              )
            }
            onClick={() => SubmitAndPreviewAnswers()}
          >
            <span>View My Curated Plan</span>
          </button>
        ) : (
          <button
            type="button"
            className="quiz-item-next-btn"
            disabled={
              !(
                selectedOption === 'Online' ||
                (selectedOption !== 'Online' &&
                  Object.keys(selectedLocation).length !== 0)
              )
            }
            onClick={() => handelNextQuestion()}
          >
            <span>Next</span>
          </button>
        )}
      </div>
    </div>
  );
};
export { PostCodeQuestions };
