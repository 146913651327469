import React, { FC } from 'react';
import { Link } from 'gatsby';
import { PageHeading, FlipCard } from 'components';
import { SlideIn } from 'components/animations';
import FitnessIcon from 'assets/svg/weight.svg';
import NutritionIcon from 'assets/svg/apple.svg';
import MindsetIcon from 'assets/svg/stopwatch.svg';
import { HomePageServiceSectionTypes } from 'types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const HomeServices: FC<HomePageServiceSectionTypes> = ({
  title,
  tagline,
  homePageServices,
}) => {
  return (
    <section className="home-services">
      <PageHeading title={title} subtitle={tagline} />
      <div className="home-services__wrapper">
        {homePageServices.map((service: any, index: number) => {
          let direction = 'right';
          if (index % 2 === 0) {
            direction = 'left';
          }
          const Url = `/${service.slug}`;

          const serviceImage: any = service.image
            ? getImage(service.image.localFile.childImageSharp.gatsbyImageData)
            : null;

          let Icon;
          switch (service.icon) {
            case 'fitness':
              Icon = <FitnessIcon />;
              break;
            case 'nutrition':
              Icon = <NutritionIcon />;
              break;
            case 'mindset':
              Icon = <MindsetIcon />;
              break;
            default:
              Icon = <FitnessIcon />;
              break;
          }
          const Card1 = (
            <>
              {serviceImage && (
                <GatsbyImage
                  image={serviceImage}
                  alt={service.image?.alternativeText ?? (service.title || '')}
                />
              )}
              <div className="home-service__picture-content">
                <div className="home-service__picture-content_svg">{Icon}</div>
                <h5 className="home-service__picture-content_title">
                  <Link to={Url}>{service.title}</Link>
                </h5>
              </div>
            </>
          );
          const Card2 = (
            <Link to={Url}>
              <div className="home-service__content">
                <div className="home-service__wrapper">
                  <div className="home-service__icon">{Icon}</div>
                  <div className="home-service__text">
                    <h5 className="home-service__title">{service.title}</h5>
                    <p className="home-service__summary">{service.tagline}</p>
                    <p className="home-service__description">
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          );
          return (
            <div className="home-service" key={service.id}>
              <SlideIn direction={direction}>
                <FlipCard
                  card1={Card1}
                  card2={Card2}
                  card1Class="home-service__rounded"
                  card2Class="home-service__rounded"
                  index={index + 1}
                />
              </SlideIn>
            </div>
          );
        })}
      </div>
    </section>
  );
};
