import React, { FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { MenuQuery } from 'queries/main-nav';
import { toggleNav, showCart } from 'Redux/actions';
import Cart from 'assets/svg/cart.svg';

interface headerLinks {
  title: string;
  slug: string;
  showBgColor: boolean;
}
const HeaderNavigation: FC<{ pathname: string }> = ({ pathname }) => {
  const data = MenuQuery();
  const dispatch = useDispatch();
  const {
    CartReducer: { cartItems },
  } = useSelector((state: any) => {
    return state;
  });
  const headerLink = data?.strapiGlobal?.headerLinks;
  const { number: phoneNumber, text: phoneText } =
    data?.strapiContactUsPage?.contactFormSection?.phone;
  const [path, setPath] = useState('');
  const [closeNavBarOnClick, setCloseNavBarOnClick] = useState(false);
  const handelShowCart = () => {
    dispatch(showCart());
    document.body.classList.add('overflow-hidden');
    if (closeNavBarOnClick) {
      setTimeout(() => {
        dispatch(toggleNav());
      }, 10);
    }
  };
  useEffect(() => {
    setPath(pathname);
    setCloseNavBarOnClick(window.innerWidth <= 991);
  }, [pathname]);
  return (
    <ul className="header_menu">
      <li>
        <a href={`tel:${phoneNumber}`}>{phoneText}</a>
      </li>
      {headerLink.map((item: headerLinks) => (
        <li key={item.title} className={item.slug === path ? `active` : ``}>
          <Link
            to={item.slug}
            onClick={() => {
              setTimeout(() => {
                // eslint-disable-next-line no-unused-expressions
                closeNavBarOnClick ? dispatch(toggleNav()) : null;
              }, 10);
            }}
            className={item.showBgColor === true ? `showbg` : ``}
          >
            {item.title}
          </Link>
        </li>
      ))}
      {!pathname.includes('/checkout') && (
        <li>
          {(pathname.includes('/shop') || cartItems.length > 0) && (
            <button
              type="button"
              className="cart-icon-wrapper"
              onClick={() => handelShowCart()}
            >
              {cartItems.length > 0 && <span>{cartItems.length}</span>}
              <Cart />
            </button>
          )}
        </li>
      )}
    </ul>
  );
};

export { HeaderNavigation };
