/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideInLeftProps {
  children: any;
  className?: string;
  durationTime?: number;
  // delaySec?: number;
  direction?: string;
}

export const SlideIn: FC<SlideInLeftProps> = ({
  children,
  className,
  durationTime,
  direction = 'left',
}) => {
  const showAnimations =
    process.env.GATSBY_SHOW_ANIMATIONS === undefined ||
    (process.env.GATSBY_SHOW_ANIMATIONS &&
      process.env.GATSBY_SHOW_ANIMATIONS === 'true');
  const ref = useRef(null);

  useEffect(() => {
    const element: any = ref.current;

    const animationConfig = {
      opacity: 0,
      duration: durationTime ?? 0.5,
      x: -100,
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=250',
        toggleActions: 'play pause resume reset',
      },
    };
    if (direction === 'right') {
      animationConfig.x = 100;
    } else {
      animationConfig.x = -100;
    }
    if (showAnimations) {
      gsap.from(element, animationConfig);
    }
  }, [showAnimations]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
