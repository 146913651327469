import React, { FC, useState, useEffect } from 'react';
import { FilterProps } from 'types';

const Filter: FC<FilterProps> = ({
  Filters,
  setFilteredList,
  ListToFilter,
  FilterCategory,
  setCurrentPage,
  titleref,
  filtervalue = '',
}) => {
  const [filterValue, setFilterValue] = useState(filtervalue);

  const handelFilterChange = (value: string) => {
    if (filterValue !== value) {
      setFilterValue(value);
      if (value === '') {
        setFilteredList(ListToFilter);
      } else {
        const FilterdValue = ListToFilter.filter((filterItem: any) => {
          const tagValues = filterItem.node[FilterCategory].map(
            (tag: any) => tag.title
          );
          return tagValues.includes(value);
        });
        setFilteredList(FilterdValue);
      }
    } else {
      setFilterValue('');
      setFilteredList(ListToFilter);
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [filterValue]);
  return (
    <div className="common-filter" ref={titleref}>
      <ul className="common-filter-list">
        <li
          className={`common-filter-list-item ${
            filterValue === '' ? 'active' : ''
          }`}
        >
          <button type="button" onClick={() => handelFilterChange('')}>
            all
          </button>
        </li>
        {Filters.map((filter: string) => {
          return (
            <li
              className={`common-filter-list-item ${
                filterValue === filter ? 'active' : ''
              }`}
              key={filter}
            >
              <button type="button" onClick={() => handelFilterChange(filter)}>
                {filter}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { Filter };
