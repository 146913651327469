import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { RecipeItemPropsType } from 'types';

// Todo Check time fromate
const RecipeItem: FC<RecipeItemPropsType> = ({ Recipe }) => {
  const { title, duration, image, slug } = Recipe.node;
  const recipeImage = image
    ? getImage(image.localFile.childImageSharp.gatsbyImageData)
    : null;
  return (
    <div className="recipe-item">
      <h4 className="recipe-item-title">{title}</h4>
      {duration && (
        <div className="recipe-item-detail">
          <div className="recipe-item-timming">
            <span className="recipe-item-timming-value">{duration}</span>
            <span className="recipe-item-timming-label">MINS</span>
          </div>
        </div>
      )}
      <div className="recipe-item-image">
        <Link to={slug}>
          {recipeImage && (
            <GatsbyImage
              image={recipeImage}
              alt={image?.alternativeText ?? (title || '')}
            />
          )}
        </Link>
      </div>
    </div>
  );
};

export { RecipeItem };
