import React, { FC } from 'react';
import { RecipeItem } from 'components';
import { RecipesListComponentPropsType, RecipeListItemType } from 'types';

const RecipeList: FC<RecipesListComponentPropsType> = ({ Recipes }) => {
  return (
    <section className="recipe-list">
      {Recipes.map((recipe: RecipeListItemType) => {
        return <RecipeItem Recipe={recipe} key={recipe.node.id} />;
      })}
    </section>
  );
};
export { RecipeList };
