/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import Slider from 'react-slick';
import { PageHeading, Statistics } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CharityComponentPropsType } from 'types';
import { getDate, getMonth } from 'utils';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
// import RightArrowIcon from 'assets/svg/arrow-next.svg';

const CharitySection: FC<CharityComponentPropsType> = ({
  CharityInfo: { title, tagline, description, stats, events },
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 2.5,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="charity-section">
      <div className="charity-section-wrapper">
        <div className="container">
          <div className="charity-section-details1">
            <PageHeading
              title={title}
              subtitle={tagline}
              description={description}
            >
              <Statistics textColor="#0ec6ff" stats={stats} />
            </PageHeading>
          </div>
        </div>
      </div>
      <div className="charity-section-slider">
        <Slider {...settings}>
          {events.map((event: any) => {
            const {
              id,
              title: eventTitle,
              date,
              description: eventDescription,
              image,
              ctaLink,
              ctaLabel,
            } = event;
            const charityImage: any = image
              ? getImage(image.localFile.childImageSharp)
              : null;
            return (
              <div className="charity-section-slider-item" key={id}>
                <div className="charity-section-slider-item-wrapper">
                  <div className="charity-section-slider-item-image">
                    {charityImage && (
                      <GatsbyImage
                        image={charityImage}
                        alt={image?.alternativeText ?? (title || '')}
                      />
                    )}
                    <div className="charity-section-slider-item-timing">
                      <span className="event-date">{getDate(date)}</span>
                      <span className="event-month">{getMonth(date)}</span>
                    </div>
                  </div>
                  <div className="charity-section-slider-item-detail">
                    <h4 className="charity-section-slider-item-title">
                      <Link to={ctaLink}>{eventTitle}</Link>
                    </h4>
                    <p className="charity-section-slider-item-description">
                      <ReactMarkdown className="md-html">
                        {eventDescription}
                      </ReactMarkdown>
                    </p>
                    {ctaLabel && (
                      <p className="md-html">
                        <strong>{ctaLabel}</strong>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export { CharitySection };
